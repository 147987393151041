import React, { createContext, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { vasthuUrl } from "../component/Api";

const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const iniLang = localStorage.getItem("i18nextLng");
  const [adminError, setAdminError] = useState();
  const [crmError, setCrmError] = useState();
  const [adminLoading, setAdminLoading] = useState(false);
  const [crmLoading, setCrmLoading] = useState(false);
  const [profError, setProfError] = useState();
  const [profLoading, setProfLoading] = useState(false);
  const [clientError, setClientError] = useState();
  const [clientLoading, setClientLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClientRegister = async (data, callback) => {
    setAdminLoading(true);
    try {
      const res = await vasthuUrl.post(`vasthu/user/register`, {
        userName: data.user,
        phone_no: data.phone,
        email: data.email,
        password: data.pass,
        lng:iniLang
      });
      if (res.data.message === process.env.REACT_APP_SUCESS_REGISTER) {
        setClientLoading(false);
        setClientError("Client Successfully Registered");
        callback("success");
      } else {
        setClientError(res.data.message);
        setClientLoading(false);
        callback("fail");
      }
    } catch (err) {
      console.log("SERVER ERROR", err);
      setClientError("CHECK INTERNET CONNECTION");
      setClientLoading(false);
      callback("fail");
    }
  };

  const handleProfRegister = async (data, callback) => {
    setAdminLoading(true);
    console.log('data', data)
    try {
      const res = await vasthuUrl.post(`vasthu/user/registerProfessional`, {
        userName: data.user,
        phone_no: data.phone,
        email: data.email,
        password: data.pass,
        levelId: data.level,
        monthOfExp: data.exp,
        language: data.profLang,
        skill: data.profSkill,
        ratePerMin:data.amount,
        maxRate:data.maxRate
      });
      if (res.data.message === process.env.REACT_APP_SUCESS_REGISTER) {
        setProfLoading(false);
        setProfError("Professional Sucessfully Registered");
        callback("success");
      } else {
        setProfError(res.data.message);
        setProfLoading(false);
        callback("fail");
      }
    } catch (err) {
      console.log("SERVER ERROR", err);
      setProfError("CHECK INTERNET CONNECTION");
      setProfLoading(false);
      callback("fail");
    }
  };

  const handleAdminRegister = async (data, callback) => {
    setAdminLoading(true);
    try {
      const res = await vasthuUrl.post(`vasthu/user/registerAdmin`, {
        userName: data.user,
        phone_no: data.phone,
        email: data.email,
        password: data.pass,
      });
      if (res.data.message === process.env.REACT_APP_SUCESS_REGISTER) {
        setAdminLoading(false);
        setAdminError("Admin Sucessfully Registered");
        callback("success");
      } else {
        setAdminError(res.data.message);
        setAdminLoading(false);
        callback("fail");
      }
    } catch (err) {
      console.log("SERVER ERROR", err);
      setAdminError("CHECK INTERNET CONNECTION");
      setAdminLoading(false);
      callback("fail");
    }
  };

  const handleCrmRegister = async (data, callback) => {
    setCrmLoading(true);
    try {
      const res = await vasthuUrl.post(`vasthu/user/registerCrm`, {
        userName: data.user,
        phone_no: data.phone,
        email: data.email,
        password: data.pass,
      });
      if (res.data.message === process.env.REACT_APP_SUCESS_REGISTER) {
        setCrmLoading(false);
        setCrmError("CRM Sucessfully Registered");
        callback("success");
      } else {
        setCrmError(res.data.message);
        setCrmLoading(false);
        callback("fail");
      }
    } catch (err) {
      console.log("SERVER ERROR", err);
      setCrmError("CHECK INTERNET CONNECTION");
      setCrmLoading(false);
      callback("fail");
    }
  };

  const memoedValue = useMemo(
    () => ({
      adminError,
      handleClientRegister,
      handleProfRegister,
      handleAdminRegister,
      handleCrmRegister,
      crmError,
      crmLoading,
      adminLoading,
      clientError,
      clientLoading,
      profError,
      profLoading,
    }),
    [
      adminLoading,
      adminError,
      clientError,
      clientLoading,
      profError,
      profLoading,
      crmError,
      crmLoading
    ]
  );

  return (
    <AdminContext.Provider value={memoedValue}>
      {children}
    </AdminContext.Provider>
  );
};

export const UseAdminContext = () => {
  return useContext(AdminContext);
};
