import React, { Suspense, useEffect, useState } from "react";
import { Card, Button, Container, Spinner, Row, Col } from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import ServiceList from "../component/serviceList";
import { setDocumentLoading } from "react-doc-viewer/build/state/actions";
import { useTranslation } from "react-i18next";

const CustomerOneTimePricing = () => {
  const token = window.sessionStorage.getItem("token");
  const [oneTimeIndividual, setOneTimeIndividual] = useState([]);
  const {t} = useTranslation();

  const [oneTimeCombo, setOneTimeCombo] = useState([]);

  useEffect(() => {
    try {
      vasthuUrl
        .get("offer/getonetimeindividualoffer", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setOneTimeIndividual(res.data);
          console.log(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setOneTimeIndividual([]);
    }
    try {
      vasthuUrl
        .get("offer/getonetimecombooffer", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setOneTimeCombo(res.data);
          console.log(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setOneTimeCombo([]);
    }
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Container
        fluid
        style={{
          padding: 20,
          background: "linear-gradient(90deg, #ffbb33 0%, #ffbb33 100%)",
        }}
      >
        <div className=" my-1 g-1">
          <center>
            <h2>{t("USR.PR.OTTAB.TL1")}</h2>
          </center>
          {oneTimeIndividual.length > 0 ? (
            <Suspense fallback={<Spinner />}>
              <ServiceList data={oneTimeIndividual} />
            </Suspense>
          ) : (
            <center>
              <h3>{t("USR.PR.OTTAB.HTXT1")}</h3>
            </center>
          )}
        </div>
        <div className="mt-2 g-1">
          <center>
            <h2>{t("USR.PR.OTTAB.TL2")}</h2>
          </center>
          <Suspense fallback={<Spinner />}>
            {oneTimeCombo.length > 0 ? (
              <ServiceList data={oneTimeCombo} />
            ) : (
              <center>
                <h3>{t("USR.PR.OTTAB.HTXT2")}</h3>
              </center>
            )}
          </Suspense>
        </div>
        {/* <ServiceList data={periodicCombo} />
        <ServiceList data={periodicIndividual} /> */}
      </Container>
    </Suspense>
  );
};
export default CustomerOneTimePricing;
