import React, { useState, useEffect, UseAuth } from "react";
import { Navigate, NavLink, useNavigate, useLocation } from "react-router-dom";
import "../style.css";
// import { vasthuUrl } from "../../component/Api";
import { Button } from "react-bootstrap";
import { timeout } from "workbox-core/_private";
import Offcanvas from "react-bootstrap/Offcanvas";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MdOutlineScreenShare, MdOutlineStopScreenShare } from "react-icons/md";
import { videoServerUrl } from "../../component/Api";

const ChatReceiver = () => {
  const [booked_slot_id, setBooked_slot_id] = useState(""); //18
  const [meeting_code, setMeeting_code] = useState("66507");
  const token = window.sessionStorage.getItem("token");
  // const webSocket = new WebSocket("ws://192.168.1.34:3001");
  const webSocket = new WebSocket(videoServerUrl);
  const navigate = useNavigate();
  let dataChannel;
  let peerConn;
  let username;

  webSocket.onmessage = (event) => {
    try {
      handleSignallingData(JSON.parse(event.data));
    } catch (error) {
      alert("Please ensure the remote peer connection");
    }
  };

  function handleSignallingData(data) {
    switch (data.type) {
      case "offer":
        try {
          peerConn.setRemoteDescription(new RTCSessionDescription(data.offer));
          createAndSendAnswer();
          break;
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
      case "candidate":
        try {
          peerConn.addIceCandidate(data.candidate);
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
    }
  }

  function createAndSendAnswer() {
    peerConn
      .createAnswer()
      .then((answer) => {
        return peerConn.setLocalDescription(answer);
      })
      .then(() => {
        sendData({
          type: "send_answer",
          answer: peerConn.localDescription,
        });
      })
      .catch((error) => {
        console.error("Error creating or sending answer:", error);
      });
  }

  function sendData(data) {
    data.username = meeting_code;
    webSocket.send(JSON.stringify(data));
  }

  function joinCall() {
    username = meeting_code;
    document.getElementById("chat-div").style.display = "inline";

    const configuration = {
      iceServers: [
        {
          urls: "stun:stun.aawsoft.com:3478",
        },
        {
          urls: "turn:turn.aawsoft.com:3478",
          username: "guest",
          credential: "somepassword",
        },

        {
          urls: "stun:stun.aawsoft.com:5349",
        },
        {
          urls: "turn:turn.aawsoft.com:5349",
          username: "guest",
          credential: "somepassword",
        },
        {
          urls: [
            "stun:stun.l.google.com:19302",
            "stun:stun1.l.google.com:19302",
            "stun:stun2.l.google.com:19302",
          ],
        },

        {
          urls: "stun:openrelay.metered.ca:80",
        },
        {
          urls: "turn:openrelay.metered.ca:80",
          username: "openrelayproject",
          credential: "openrelayproject",
        },
        {
          urls: "turn:openrelay.metered.ca:443",
          username: "openrelayproject",
          credential: "openrelayproject",
        },
        {
          urls: "turn:openrelay.metered.ca:443?transport=tcp",
          username: "openrelayproject",
          credential: "openrelayproject",
        },
      ],
      optional: [
        {
          RtpDataChannels: true,
        },
      ],
    };

    peerConn = new RTCPeerConnection(configuration);

    try {
      console.log("datachannel state", dataChannel);
      if (dataChannel === undefined) {
        alert(
          "trying connection if not connected in 10 sec - please hangup and tryagain"
        );
      } else if (dataChannel.readyState === "open") {
        console.log("connection is open");
        alert("connection open");
      } else {
        alert(
          " catch = Consultant not yet connected. Please hangup and try again later"
        );
      }
    } catch (error) {
      setTimeout(() => {
        console.log("catch - connected");
      }, 3000);
    }

    peerConn.addEventListener("datachannel", (event) => {
      dataChannel = event.channel;
      console.log("dataChannel", dataChannel);
    });

    peerConn.ontrack = (event) => {
      const video = document.getElementById("remote-chat");
      video.srcObject = event.streams[0];
    };

    peerConn.onicecandidate = (e) => {
      if (e.candidate == null) return;

      sendData({
        type: "send_candidate",
        candidate: e.candidate,
      });
    };

    sendData({
      type: "join_call",
    });

    dataChannel = peerConn.createDataChannel("channel1", {
      reliable: true,
    });

    peerConn.addEventListener("datachannel", (event) => {
      dataChannel = event.channel;
    });

    dataChannel.onerror = function(error) {
      console.log("Ooops...error:", error);
    };

    //when we receive a message from the other peer, display it on the screen
    dataChannel.onmessage = function(event) {
      var chatArea = document.querySelector("#chatarea");
      var messageDiv = document.createElement("div");
      messageDiv.classList.add("message", "remote-message"); // Add a class for styling
      messageDiv.innerText = "Remote: " + event.data;
      chatArea.appendChild(messageDiv);
      chatArea.innerHTML += "<br />";
      console.log("event.data", event.data);
    };

    dataChannel.onclose = function() {
      console.log("data channel is closed");
      alert("data call channel is closed - call hangup");
      window.location.reload();
    };
  }

  function hangUp(e) {
    // e.preventDefault();
    if (dataChannel) {
      dataChannel.close();
    }
    navigate("/");
  }

  //when user clicks the "send message" button
  const sendMsg = (event) => {
    var msgInput = document.getElementById("msgInput");
    var chatArea = document.querySelector("#chatarea");

    var val = msgInput.value;
    var string_val = val.toString();

    try {
      dataChannel.send(val);
      var messageDiv = document.createElement("div");
      messageDiv.classList.add("message", "my-message"); // Add a class for styling
      messageDiv.innerText = val + " : Me";
      chatArea.appendChild(messageDiv);
      chatArea.innerHTML += "<br />";
      msgInput.value = "";
    } catch (error) {
      alert(
        "Please ensure the remote peer is connection. Please disconnect you are able to connected only after the consultant establishing the call "
      );
      msgInput.value = "";
    }
  };

  return (
    <div>
      <div>
        <Button id="join_call" variant="warning" onClick={joinCall}>
          Join Chat
        </Button>
      </div>
      <div id="chat-div">
        <video muted id="local-chat" autoPlay></video>
        <video id="remote-chat" autoPlay></video>
        <div className="chat-action-div">
          <div style={{ display: "flex", gap: "1rem" }}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Hang Up</Tooltip>}
            >
              <div>
                <Button variant="warning" onClick={() => hangUp()}>
                  HangUp
                </Button>
              </div>
            </OverlayTrigger>
          </div>

          <div className="chatBox">
            <div id="chatarea"></div>
            <div class="main__message_container">
              <input
                type="text"
                id="msgInput"
                placeholder="Type message here.."
              />
              <button class="sendMsgBtn" id="sendMsgBtn" onClick={sendMsg}>
                <i class="fa fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatReceiver;
