// import React, { useState, useEffect, UseAuth } from "react";
// import { Navigate, NavLink, useNavigate, useLocation } from "react-router-dom";
// import "../style.css";
// import { videoServerUrl } from "../../component/Api";
// import { vasthuUrl } from "../../component/Api";

// import { Button } from "react-bootstrap";

// const VideoCallReceiver = React.forwardRef((ref, props) => {
//   const [booked_slot_id, setBooked_slot_id] = useState("18");
//   const [meeting_code, setMeeting_code] = useState("");
//   const token = window.localStorage.getItem("token");
//   const {state} = useLocation();
//   console.log("booked_slot_id" + state.i.slotId);

//   useEffect(() => {
//     try {
//       setBooked_slot_id(state.i.slotId);
//       console.log("Token" + token);
//       vasthuUrl
//         .get(`getmeetingcode/${state.tempObj.slotId}`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         })
//         .then((res) => {
//           console.log(res.data);
//           setMeeting_code(res.data);
//         });
//     } catch (e) {
//       console.log("SERVER ERROR");
//     }
//   }, []);

//   // const webSocket = new WebSocket("ws://192.168.1.62:3001");
//   const webSocket = new WebSocket(videoServerUrl);
//   const navigate = useNavigate();
//   let displayMediaStream;

//   webSocket.onmessage = (event) => {
//     handleSignallingData(JSON.parse(event.data));
//   };

//   function handleSignallingData(data) {
//     switch (data.type) {
//       case "offer":
//         peerConn.setRemoteDescription(data.offer);
//         createAndSendAnswer();
//         break;
//       case "candidate":
//         peerConn.addIceCandidate(data.candidate);
//     }
//   }

//   function createAndSendAnswer() {
//     peerConn.createAnswer(
//       (answer) => {
//         peerConn.setLocalDescription(answer);
//         sendData({
//           type: "send_answer",
//           answer: answer,
//         });
//       },
//       (error) => {
//         console.log(error);
//       }
//     );
//   }

//   function sendData(data) {
//     data.username = username;
//     webSocket.send(JSON.stringify(data));
//   }

//   let localStream;
//   let peerConn;
//   let username;
//   let senders = [];

//   // new chat ========================================================================
//   let dataChannel;
//   let connectedUser;
//   var sendMsgBtn = document.querySelector("#sendMsgBtn");

//   var chatArea = document.querySelector("#chatarea");
//   var msgInput = document.querySelector("#msgInput");

//   let file;
//   let file1;
//   var downloadfile = document.querySelector("#downloadfile");
//   //=================================================================================

//   function joinCall() {
//     // username = document.getElementById("username-input").value;
//     username = meeting_code;

//     document.getElementById("video-call-div").style.display = "inline";

//     var getUserMedia =
//       navigator.getUserMedia ||
//       navigator.webkitGetUserMedia ||
//       navigator.mozGetUserMedia;
//     getUserMedia.call(
//       navigator,
//       {
//         video: {
//           frameRate: 24,
//           width: {
//             min: 480,
//             ideal: 720,
//             max: 1280,
//           },
//           aspectRatio: 1.33333,
//           // facingMode:'user'
//         },
//         audio: true,
//       },
//       (stream) => {
//         let configuration = {
//           iceServers: [
//             {
//               urls: "stun:stun.aawsoft.com:3478",
//             },
//             {
//               urls: "turn:turn.aawsoft.com:3478",
//               username: "guest",
//               credential: "somepassword",
//             },

//             {
//               urls: "stun:stun.aawsoft.com:5349",
//             },
//             {
//               urls: "turn:turn.aawsoft.com:5349",
//               username: "guest",
//               credential: "somepassword",
//             },
//           ],
//           optional: [
//             {
//               RtpDataChannels: true,
//             },
//           ],
//         };

//         peerConn = new RTCPeerConnection(configuration);
//         peerConn.addEventListener("datachannel", (event) => {
//           dataChannel = event.channel;
//           // dataChannel.send("test")
//         });

//         localStream = stream;

//         localStream
//           .getTracks()
//           .forEach((track) =>
//             senders.push(peerConn.addTrack(track, localStream))
//           );

//         document.getElementById("local-video").srcObject = localStream;

//         peerConn.ontrack = (event) => {
//           const video = document.getElementById("remote-video");
//           video.srcObject = event.streams[0];
//         };

//         peerConn.onicecandidate = (e) => {
//           if (e.candidate == null) return;

//           sendData({
//             type: "send_candidate",
//             candidate: e.candidate,
//           });
//         };

//         sendData({
//           type: "join_call",
//         });
//         // .then(
//         // ("professional not ready")? alert("pro not ready"):"";

//         // new chat ======================================================================

//         dataChannel = peerConn.createDataChannel("channel1", {
//           reliable: true,
//         });

//         peerConn.addEventListener("datachannel", (event) => {
//           dataChannel = event.channel;
//         });

//         dataChannel.onerror = function(error) {
//           console.log("Ooops...error:", error);
//         };

//         //when we receive a message from the other peer, display it on the screen
//         dataChannel.onmessage = function(event) {
//           var chatArea = document.querySelector("#chatarea");
//           //  chatArea.innerHTML += connectedUser + ": " + event.data + "<br />";
//           // chatArea.innerHTML += "Remote: " + event.data + "<br />";
//           chatArea.innerText += "Remote: " + event.data;
//           chatArea.innerHTML += "<br />";
//         };

//         dataChannel.onclose = function() {
//           console.log("data channel is closed");
//         };

//         // ================================================================================
//       },
//       (error) => {
//         console.log(error);
//       }
//     );
//   }

//   let isAudio = true;
//   function muteAudio() {
//     isAudio = !isAudio;
//     localStream.getAudioTracks()[0].enabled = isAudio;
//     var muteAudio = document.getElementById("mute-audio");
//     muteAudio.innerText === "Mute Audio"
//       ? (muteAudio.innerText = "Un Mute Audio")
//       : (muteAudio.innerText = "Mute Audio");
//   }

//   let isVideo = true;
//   function muteVideo() {
//     isVideo = !isVideo;
//     localStream.getVideoTracks()[0].enabled = isVideo;
//     var muteVideo = document.getElementById("mute-video");
//     muteVideo.innerText == "Mute Video"
//       ? (muteVideo.innerText = "Un Mute Video")
//       : (muteVideo.innerText = "Mute Video");
//   }

//   function hangUp(e) {
//     e.preventDefault();
//     peerConn.close();
//     localStream.getVideoTracks()[0].enabled = false;
//     localStream.getAudioTracks()[0].enabled = false;
//     localStream.getTracks().forEach(function(track) {
//       track.stop();
//     });
//     navigate("/user");
//   }

//   // new chat =============================================================================
//   //when user clicks the "send message" button
//   const sendMsg = (event) => {
//     var msgInput = document.getElementById("msgInput");
//     var chatArea = document.querySelector("#chatarea");

//     var val = msgInput.value;

//     // chatArea.innerHTML += "Me: " + val + "<br />";
//     chatArea.innerText += "Me: " + val;
//     chatArea.innerHTML += "<br />";

//     dataChannel.send(val);
//     msgInput.value = "";
//     //  });
//   };
//   // ==================================================================================================

//   async function shareScreen() {
//     try {
//       if (!displayMediaStream) {
//         displayMediaStream = await navigator.mediaDevices.getDisplayMedia({
//           video: true,
//         });
//       }
//       senders
//         .find((sender) => sender.track.kind === "video")
//         .replaceTrack(displayMediaStream.getTracks()[0]);

//       //show what you are showing in your "self-view" video.
//       document.getElementById("local-video").srcObject = displayMediaStream;

//       //hide the share button and display the "stop-sharing" one
//       document.getElementById("share-button").style.display = "none";
//       document.getElementById("stop-share-button").style.display = "inline";
//     } catch (e) {
//       console.log("Unable to acquire screen capture: " + e);
//     }
//   }

//   async function stopShareScreen() {
//     try {
//       senders
//         .find((sender) => sender.track.kind === "video")
//         .replaceTrack(
//           localStream.getTracks().find((track) => track.kind === "video")
//         );

//       document.getElementById("local-video").srcObject = localStream;
//       document.getElementById("share-button").style.display = "inline";
//       document.getElementById("stop-share-button").style.display = "none";
//     } catch (error) {
//       console.log("Unable to stop share screen: " + error);
//     }
//   }

//   return (
//     <div>
//       <div>
//         {/* <input
//           placeholder="Enter username..."
//           type="text"
//           id="username-input"
//         />
//         <br /> */}
//         {/* <button onClick={joinCall}>Join Call</button> */}

//         <Button id="join_call" variant="warning" ref={ref} onClick={joinCall}>
//           Join call
//         </Button>
//       </div>
//       <div id="video-call-div">
//         <video muted id="local-video" autoPlay></video>
//         <video id="remote-video" autoPlay></video>
//         <div className="call-action-div">
//           <div>
//             <Button id="mute-video" variant="warning" onClick={muteVideo}>
//               Mute Video
//             </Button>
//             <Button id="mute-audio" variant="warning" onClick={muteAudio}>
//               Mute Audio
//             </Button>
//             <Button id="share-button" variant="warning" onClick={shareScreen}>
//               Share Screen
//             </Button>
//             <Button
//               id="stop-share-button"
//               variant="warning"
//               onClick={stopShareScreen}
//             >
//               Stop Share
//             </Button>
//             {/* <NavLink to="/home"> */}
//             <Button variant="warning" onClick={hangUp}>
//               HangUp
//             </Button>
//             {/* </NavLink> */}
//           </div>
//           <div>
//             <div id="chatarea"></div>
//             <div>
//               <input id="msgInput" type="text" placeholder="message" />
//               <Button variant="warning" id="sendMsgBtn" onClick={sendMsg}>
//                 Send
//               </Button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// });

// export default VideoCallReceiver;

// import React, { useState, useEffect, UseAuth } from "react";
// import { Navigate, NavLink, useNavigate, useLocation } from "react-router-dom";
// import "../style.css";
// import { videoServerUrl } from "../../component/Api";
// import { vasthuUrl } from "../../component/Api";

// import { Button } from "react-bootstrap";
// import { timeout } from "workbox-core/_private";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";
// import { MdOutlineScreenShare, MdOutlineStopScreenShare } from "react-icons/md";

// import image1 from "./pizza.jpeg";

// const VideoCallReceiver = () => {
//   const [booked_slot_id, setBooked_slot_id] = useState(""); //18
//   const [meeting_code, setMeeting_code] = useState("");
//   const token = window.sessionStorage.getItem("token");
//   const { state } = useLocation();
//   console.log("booked_slot_id" + String(state.id.slotId));

//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   useEffect(() => {
//     try {
//       setBooked_slot_id(state.id.slotId);
//       console.log("Token" + token);
//       vasthuUrl
//         .get(`getmeetingcode/${state.id.slotId}`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         })
//         .then((res) => {
//           console.log(res.data);
//           setMeeting_code(res.data);
//         });
//     } catch (e) {
//       console.log("SERVER ERROR");
//     }
//   }, []);

//   // const webSocket = new WebSocket("ws://192.168.1.62:3001");
//   const webSocket = new WebSocket(videoServerUrl);
//   const navigate = useNavigate();
//   let displayMediaStream;

//   webSocket.onmessage = (event) => {
//     try {
//       handleSignallingData(JSON.parse(event.data));
//     } catch (error) {
//       alert("Please ensure the remote peer connection");
//     }
//     // handleSignallingData(JSON.parse(event.data));
//   };

//   function handleSignallingData(data) {
//     switch (data.type) {
//       case "offer":
//         try {
//           peerConn.setRemoteDescription(data.offer);
//           createAndSendAnswer();
//           break;
//         } catch (error) {
//           console.log(
//             "setRemoteDescription failed because of not finding currect remote connection"
//           );
//         }
//       // peerConn.setRemoteDescription(data.offer);
//       // createAndSendAnswer();
//       // break;
//       case "candidate":
//         try {
//           peerConn.addIceCandidate(data.candidate);
//         } catch (error) {
//           console.log(
//             "setRemoteDescription failed because of not finding currect remote connection"
//           );
//         }
//       // peerConn.addIceCandidate(data.candidate);
//     }
//   }

//   function createAndSendAnswer() {
//     peerConn.createAnswer(
//       (answer) => {
//         peerConn.setLocalDescription(answer);
//         sendData({
//           type: "send_answer",
//           answer: answer,
//         });
//       },
//       (error) => {
//         console.log(error);
//       }
//     );
//   }

//   function sendData(data) {
//     data.username = username;
//     webSocket.send(JSON.stringify(data));
//   }

//   let localStream;
//   let peerConn;
//   let username;
//   let senders = [];

//   // new chat ========================================================================
//   let dataChannel;
//   let connectedUser;
//   var sendMsgBtn = document.querySelector("#sendMsgBtn");
//   var sendMsgBtn1 = document.querySelector("#sendMsgBtn1");

//   var chatArea = document.querySelector("#chatarea");
//   var chatArea1 = document.querySelector("#chatarea1");
//   var msgInput = document.querySelector("#msgInput");
//   var msgInput1 = document.querySelector("#msgInput1");

//   let file;
//   let file1;
//   var downloadfile = document.querySelector("#downloadfile");
//   //=================================================================================

//   function joinCall() {
//     // username = document.getElementById("username-input").value;
//     username = meeting_code;

//     document.getElementById("video-call-div").style.display = "inline";

//     var getUserMedia =
//       navigator.getUserMedia ||
//       navigator.webkitGetUserMedia ||
//       navigator.mozGetUserMedia;
//     getUserMedia.call(
//       navigator,
//       {
//         // video: false,
//         video: {
//           frameRate: 24,
//           width: {
//             min: 480,
//             ideal: 720,
//             max: 1280,
//           },
//           aspectRatio: 1.33333,
//           // facingMode:'user'
//         },
//         audio: true,
//       },
//       (stream) => {
//         let configuration = {
//           iceServers: [
//             {
//               urls: "stun:stun.aawsoft.com:3478",
//             },
//             {
//               urls: "turn:turn.aawsoft.com:3478",
//               username: "guest",
//               credential: "somepassword",
//             },

//             {
//               urls: "stun:stun.aawsoft.com:5349",
//             },
//             {
//               urls: "turn:turn.aawsoft.com:5349",
//               username: "guest",
//               credential: "somepassword",
//             },
//             {
//               urls: [
//                 "stun:stun.l.google.com:19302",
//                 "stun:stun1.l.google.com:19302",
//                 "stun:stun2.l.google.com:19302",
//               ],
//             },

//             {
//               urls: "stun:openrelay.metered.ca:80",
//             },
//             {
//               urls: "turn:openrelay.metered.ca:80",
//               username: "openrelayproject",
//               credential: "openrelayproject",
//             },
//             {
//               urls: "turn:openrelay.metered.ca:443",
//               username: "openrelayproject",
//               credential: "openrelayproject",
//             },
//             {
//               urls: "turn:openrelay.metered.ca:443?transport=tcp",
//               username: "openrelayproject",
//               credential: "openrelayproject",
//             },
//           ],
//           optional: [
//             {
//               RtpDataChannels: true,
//             },
//           ],
//         };

//         peerConn = new RTCPeerConnection(configuration);

//         try {
//           console.log("datachannel state", dataChannel);
//           if (dataChannel === undefined) {
//             alert(
//               "trying connection if not connected in 10 sec - please hangup and tryagain"
//             );
//           } else if (dataChannel.readyState === "open") {
//             console.log("connection is open");
//             alert("connection open");
//           } else {
//             alert(
//               " catch = Consultant not yet connected. Please hangup and try again later"
//             );
//           }
//         } catch (error) {
//           setTimeout(() => {
//             console.log("catch - connected");
//           }, 3000);
//         }

//         peerConn.addEventListener("datachannel", (event) => {
//           dataChannel = event.channel;
//           // dataChannel.send("test")
//           console.log("dataChannel", dataChannel);
//         });

//         localStream = stream;

//         //         var canvas = document.getElementById("canvas");
//         //         window.onload = function(canvas) {

//         //           var ctx = canvas.getContext("2d");
//         //           var img = document.getElementById("img");
//         //           ctx.drawImage(img, 10, 10);
//         //         };

//         // // Get the stream
//         // localStream = canvas.captureStream(25); // 25 FPS
//         // // localStream.getTracks().forEach((track) => peerConn.addTrack(track, stream));

//         localStream
//           .getTracks()
//           .forEach((track) =>
//             senders.push(peerConn.addTrack(track, localStream))
//           );

//         document.getElementById("local-video").srcObject = localStream;

//         peerConn.ontrack = (event) => {
//           const video = document.getElementById("remote-video");
//           video.srcObject = event.streams[0];
//         };

//         peerConn.onicecandidate = (e) => {
//           if (e.candidate == null) return;

//           sendData({
//             type: "send_candidate",
//             candidate: e.candidate,
//           });
//         };

//         sendData({
//           type: "join_call",
//         });

//         // new chat ======================================================================

//         dataChannel = peerConn.createDataChannel("channel1", {
//           reliable: true,
//         });

//         peerConn.addEventListener("datachannel", (event) => {
//           dataChannel = event.channel;
//         });

//         dataChannel.onerror = function(error) {
//           console.log("Ooops...error:", error);
//         };

//         //when we receive a message from the other peer, display it on the screen
//         dataChannel.onmessage = function(event) {
//           var chatArea = document.querySelector("#chatarea");
//           //  chatArea.innerHTML += connectedUser + ": " + event.data + "<br />";
//           // chatArea.innerHTML += "Remote: " + event.data + "<br />";
//           chatArea.innerText += "Remote: " + event.data;
//           chatArea.innerHTML += "<br />";
//           var chatArea1 = document.querySelector(".chatarea1");
//           if(chatArea1){
//           chatArea1.innerText += "Remote: " + event.data;
//           chatArea1.innerHTML += "<br />";
//           }
//           console.log('event.data', event.data)
//         };

//         dataChannel.onclose = function() {
//           console.log("data channel is closed");
//           alert("data call channel is closed - call hangup");
//           localStream.getTracks().forEach(function(track) {
//             track.stop();
//           });
//         };

//         // ================================================================================
//       },
//       (error) => {
//         console.log(error);
//       }
//     );
//   }

//   // let isAudio = true;
//   // function muteAudio() {
//   //   isAudio = !isAudio;
//   //   localStream.getAudioTracks()[0].enabled = isAudio;
//   //   var muteAudio = document.getElementById("mute-audio");
//   //   muteAudio.innerText === "Mute Audio"
//   //     ? (muteAudio.innerText = "Un Mute Audio")
//   //     : (muteAudio.innerText = "Mute Audio");
//   // }
//   let isAudio = true;
//   function muteAudio() {
//     isAudio = !isAudio;
//     localStream.getAudioTracks()[0].enabled = isAudio;
//     var muteAudio = document.getElementById("mute-audio");
//     var audioIcon = document.getElementById("audio-icon");
//     if (isAudio) {
//       audioIcon.classList.remove("fa-microphone-slash");
//       audioIcon.classList.add("fa-microphone");
//     } else {
//       audioIcon.classList.remove("fa-microphone");
//       audioIcon.classList.add("fa-microphone-slash");
//     }
//   }

//   // let isVideo = true;
//   // function muteVideo() {
//   //   isVideo = !isVideo;
//   //   localStream.getVideoTracks()[0].enabled = isVideo;
//   //   var muteVideo = document.getElementById("mute-video");
//   //   muteVideo.innerText == "Mute Video"
//   //     ? (muteVideo.innerText = "Un Mute Video")
//   //     : (muteVideo.innerText = "Mute Video");
//   // }
//   let isVideo = true;
//   function muteVideo() {
//     isVideo = !isVideo;
//     localStream.getVideoTracks()[0].enabled = isVideo;
//     var muteVideo = document.getElementById("mute-video");
//     var videoIcon = document.getElementById("video-icon");
//     if (isVideo) {
//       videoIcon.classList.remove("fa-video-slash");
//       videoIcon.classList.add("fa-video");
//     } else {
//       videoIcon.classList.remove("fa-video");
//       videoIcon.classList.add("fa-video-slash");
//     }
//   }

//   function hangUp(e) {
//     e.preventDefault();
//     peerConn.close();
//     localStream.getVideoTracks()[0].enabled = false;
//     localStream.getAudioTracks()[0].enabled = false;
//     localStream.getTracks().forEach(function(track) {
//       track.stop();
//     });
//     navigate("/");
//   }

//   // new chat =============================================================================
//   //when user clicks the "send message" button
//   const sendMsg = (event) => {
//     var msgInput = document.getElementById("msgInput");
//     var chatArea = document.querySelector("#chatarea");

//     var val = msgInput.value;
//     var string_val = val.toString();

//     try {
//       // chatArea.innerText +="Me: " + val;
//       // chatArea.innerHTML += "<br />";
//       dataChannel.send(val);
//       chatArea.innerText += "Me: " + val;
//       chatArea.innerHTML += "<br />";
//       msgInput.value = "";
//     } catch (error) {
//       alert(
//         "Please ensure the remote peer is connection. Please disconnect you are able to connected only after the consultant establishing the call "
//       );
//       msgInput.value = "";
//     }

//     // // chatArea.innerHTML += "Me: " + val + "<br />";
//     // chatArea.innerText += "Me: " + val;
//     // chatArea.innerHTML += "<br />";
//     // dataChannel.send(val);
//     // msgInput.value = "";
//     //  });
//   };

//   const sendMsg1 = (event) => {
//     var msgInput1 = document.getElementById("msgInput1");
//     var chatArea1 = document.querySelector("#chatarea1");
//     console.log("dataChannel", dataChannel);

//     var val1 = msgInput1.value;
//     var string_val = val1.toString();

//     try {
//       console.log("dataChannel", dataChannel);
//       dataChannel.send(val1);
//       chatArea1.innerText += "Me: " + val1;
//       chatArea1.innerHTML += "<br />";
//       msgInput1.value = "";
//     } catch (error) {
//       console.log("dataChannel", dataChannel);
//       alert(
//         "Please ensure the remote peer is connection. Please disconnect you are able to connected only after the consultant establishing the call "
//       );
//       msgInput1.value = "";
//     }

//     // // chatArea.innerHTML += "Me: " + val + "<br />";
//     // chatArea.innerText += "Me: " + val;
//     // chatArea.innerHTML += "<br />";
//     // dataChannel.send(val);
//     // msgInput.value = "";
//     //  });
//   };
//   // ==================================================================================================

//   async function shareScreen() {
//     try {
//       if (!displayMediaStream) {
//         displayMediaStream = await navigator.mediaDevices.getDisplayMedia({
//           video: true,
//         });
//       }
//       senders
//         .find((sender) => sender.track.kind === "video")
//         .replaceTrack(displayMediaStream.getTracks()[0]);

//       //show what you are showing in your "self-view" video.
//       document.getElementById("local-video").srcObject = displayMediaStream;

//       //hide the share button and display the "stop-sharing" one
//       document.getElementById("share-button").style.display = "none";
//       document.getElementById("stop-share-button").style.display = "inline";
//     } catch (e) {
//       console.log("Unable to acquire screen capture: " + e);
//     }
//   }

//   async function stopShareScreen() {
//     try {
//       senders
//         .find((sender) => sender.track.kind === "video")
//         .replaceTrack(
//           localStream.getTracks().find((track) => track.kind === "video")
//         );
//       displayMediaStream = undefined;
//       document.getElementById("local-video").srcObject = localStream;
//       document.getElementById("share-button").style.display = "inline";
//       document.getElementById("stop-share-button").style.display = "none";
//     } catch (error) {
//       console.log("Unable to stop share screen: " + error);
//     }
//   }

//   return (
//     <div>
//       <div>
//         {/* <input
//           placeholder="Enter username..."
//           type="text"
//           id="username-input"
//         />
//         <br /> */}
//         {/* <button onClick={joinCall}>Join Call</button> */}

//         <Button id="join_call" variant="warning" onClick={joinCall}>
//           Join call
//         </Button>
//       </div>
//       <div id="video-call-div">
//         <video muted id="local-video" autoPlay></video>
//         {/* <canvas  id="canvas" width="100"  height="100"  />
//         <img id="img" src="pizza.jpeg"></img> */}
//         <video id="remote-video" autoPlay></video>
//         <div className="call-action-div">
//           <div style={{ display: "flex", gap: "1rem" }}>
//             {/* <Button id="mute-video" variant="warning" onClick={muteVideo}>
//               Mute Video
//             </Button> */}
//             <Button id="mute-video" variant="warning" onClick={muteVideo}>
//               <i
//                 id="video-icon"
//                 class="fas fa-video"
//                 style={{ color: "#000000" }}
//               ></i>
//             </Button>
//             {/* <Button id="mute-audio" variant="warning" onClick={muteAudio}>
//               Mute Audio
//             </Button> */}
//             <Button id="mute-audio" variant="warning" onClick={muteAudio}>
//               <i
//                 id="audio-icon"
//                 class="fas fa-microphone"
//                 style={{ color: "#000000" }}
//               ></i>
//             </Button>
//             {/* <Button id="share-button" variant="warning" onClick={shareScreen}>
//               Share Screen
//             </Button> */}
//             <OverlayTrigger
//               placement="top"
//               overlay={<Tooltip id="tooltip">Share Screen</Tooltip>}
//             >
//               <Button id="share-button" variant="warning" onClick={shareScreen}>
//                 <MdOutlineScreenShare size={24} />
//               </Button>
//             </OverlayTrigger>

//             {/* <Button
//               id="stop-share-button"
//               variant="warning"
//               onClick={stopShareScreen}
//               style={{ display: "none" }}
//             >
//               Stop Share
//             </Button> */}
//             <OverlayTrigger
//               placement="top"
//               overlay={<Tooltip id="tooltip">Stop Share</Tooltip>}
//             >
//               <Button
//                 id="stop-share-button"
//                 variant="warning"
//                 onClick={stopShareScreen}
//                 style={{ display: "none" }}
//               >
//                 <MdOutlineStopScreenShare size={24} />
//               </Button>
//             </OverlayTrigger>

//             {/* <NavLink to="/home"> */}
//             {/* <Button variant="warning" onClick={hangUp}>
//               HangUp
//             </Button> */}
//             {/* </NavLink> */}
//             <OverlayTrigger
//               placement="top"
//               overlay={<Tooltip id="tooltip">Hang Up</Tooltip>}
//             >
//               <Button variant="warning" onClick={hangUp}>
//                 <i class="fas fa-phone-slash" style={{ color: "#000000" }}></i>
//               </Button>
//             </OverlayTrigger>

//             {/* <Button variant="warning" onClick={handleShow}>
//               <i class="fas fa-comment" style={{ color: "#000000" }}></i>
//             </Button> */}
//           </div>
//           <div>
//             <div id="chatarea"></div>
//             <div>
//               <input id="msgInput" type="text" placeholder="message" />
//               <Button variant="warning" id="sendMsgBtn" onClick={sendMsg}>
//                 Send
//               </Button>
//             </div>
//           </div>
//           {/* <Offcanvas show={show} onHide={handleClose} placement="end">
//             <Offcanvas.Header closeButton>
//               <Offcanvas.Title>Chat</Offcanvas.Title>
//             </Offcanvas.Header>
//             <Offcanvas.Body>
//               <div>
//                 <div id="chatarea1" class="chatarea1"></div>
//                 <div>
//                   <input id="msgInput1" type="text" placeholder="message" />
//                   <Button variant="warning" id="sendMsgBtn1" onClick={sendMsg1}>
//                     Send
//                   </Button>
//                 </div>
//               </div>
//             </Offcanvas.Body>
//           </Offcanvas> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoCallReceiver;

//**  Updated By 08.10.2024  */
import React, { useState, useEffect, UseAuth } from "react";
import { Navigate, NavLink, useNavigate, useLocation } from "react-router-dom";
import "../style.css";
import { videoServerUrl } from "../../component/Api";
import { vasthuUrl } from "../../component/Api";

import { Button } from "react-bootstrap";
import { timeout } from "workbox-core/_private";
import Offcanvas from "react-bootstrap/Offcanvas";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MdOutlineScreenShare, MdOutlineStopScreenShare } from "react-icons/md";

import image1 from "./pizza.jpeg";

const VideoCallReceiver = () => {
  const [booked_slot_id, setBooked_slot_id] = useState(""); //18
  const [meeting_code, setMeeting_code] = useState("");
  const token = window.sessionStorage.getItem("token");
  const { state } = useLocation();
  console.log("booked_slot_id" + String(state.id.slotId));

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    // setShow(true)
    const chatContainer = document.querySelector(".main__right");
    chatContainer.classList.toggle("active");
  };

  useEffect(() => {
    try {
      setBooked_slot_id(state.id.slotId);
      console.log("Token" + token);
      vasthuUrl
        .get(`getmeetingcode/${state.id.slotId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setMeeting_code(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
    }
  }, []);

  // const webSocket = new WebSocket("ws://192.168.1.62:3001");
  const webSocket = new WebSocket(videoServerUrl);
  const navigate = useNavigate();
  let displayMediaStream;

  webSocket.onmessage = (event) => {
    try {
      handleSignallingData(JSON.parse(event.data));
    } catch (error) {
      alert("Please ensure the remote peer connection");
    }
  };

  function handleSignallingData(data) {
    switch (data.type) {
      case "offer":
        try {
          peerConn.setRemoteDescription(data.offer);
          createAndSendAnswer();
          break;
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
      case "candidate":
        try {
          peerConn.addIceCandidate(data.candidate);
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
    }
  }

  function createAndSendAnswer() {
    peerConn.createAnswer(
      (answer) => {
        peerConn.setLocalDescription(answer);
        sendData({
          type: "send_answer",
          answer: answer,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function sendData(data) {
    data.username = username;
    webSocket.send(JSON.stringify(data));
  }

  let localStream;
  let peerConn;
  let username;
  let senders = [];

  // new chat ========================================================================
  let dataChannel;
  //=================================================================================

  function joinCall() {
    username = meeting_code;

    document.getElementById("video-call-div").style.display = "inline";

    var getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia;
    getUserMedia.call(
      navigator,
      {
        video: {
          frameRate: 24,
          width: {
            min: 480,
            ideal: 720,
            max: 1280,
          },
          aspectRatio: 1.33333,
        },
        audio: true,
      },
      (stream) => {
        let configuration = {
          iceServers: [
            {
              urls: "stun:stun.aawsoft.com:3478",
            },
            {
              urls: "turn:turn.aawsoft.com:3478",
              username: "guest",
              credential: "somepassword",
            },

            {
              urls: "stun:stun.aawsoft.com:5349",
            },
            {
              urls: "turn:turn.aawsoft.com:5349",
              username: "guest",
              credential: "somepassword",
            },
            {
              urls: [
                "stun:stun.l.google.com:19302",
                "stun:stun1.l.google.com:19302",
                "stun:stun2.l.google.com:19302",
              ],
            },

            {
              urls: "stun:openrelay.metered.ca:80",
            },
            {
              urls: "turn:openrelay.metered.ca:80",
              username: "openrelayproject",
              credential: "openrelayproject",
            },
            {
              urls: "turn:openrelay.metered.ca:443",
              username: "openrelayproject",
              credential: "openrelayproject",
            },
            {
              urls: "turn:openrelay.metered.ca:443?transport=tcp",
              username: "openrelayproject",
              credential: "openrelayproject",
            },
          ],
          optional: [
            {
              RtpDataChannels: true,
            },
          ],
        };

        peerConn = new RTCPeerConnection(configuration);

        try {
          console.log("datachannel state", dataChannel);
          if (dataChannel === undefined) {
            alert(
              "trying connection if not connected in 10 sec - please hangup and tryagain"
            );
          } else if (dataChannel.readyState === "open") {
            console.log("connection is open");
            alert("connection open");
          } else {
            alert(
              " catch = Consultant not yet connected. Please hangup and try again later"
            );
          }
        } catch (error) {
          setTimeout(() => {
            console.log("catch - connected");
          }, 3000);
        }

        peerConn.addEventListener("datachannel", (event) => {
          dataChannel = event.channel;
          console.log("dataChannel", dataChannel);
        });

        localStream = stream;
        localStream
          .getTracks()
          .forEach((track) =>
            senders.push(peerConn.addTrack(track, localStream))
          );

        document.getElementById("local-video").srcObject = localStream;

        peerConn.ontrack = (event) => {
          const video = document.getElementById("remote-video");
          video.srcObject = event.streams[0];
        };

        peerConn.onicecandidate = (e) => {
          if (e.candidate == null) return;

          sendData({
            type: "send_candidate",
            candidate: e.candidate,
          });
        };

        sendData({
          type: "join_call",
        });

        // new chat ======================================================================

        dataChannel = peerConn.createDataChannel("channel1", {
          reliable: true,
        });

        peerConn.addEventListener("datachannel", (event) => {
          dataChannel = event.channel;
        });

        dataChannel.onerror = function(error) {
          console.log("Ooops...error:", error);
        };

        //when we receive a message from the other peer, display it on the screen
        dataChannel.onmessage = function(event) {
          var chatArea = document.querySelector("#chatarea");
          var messageDiv = document.createElement("div");
          messageDiv.classList.add("message", "remote-message"); // Add a class for styling
          messageDiv.innerText = "Remote: " + event.data;
          chatArea.appendChild(messageDiv);
          chatArea.innerHTML += "<br />";
          console.log("event.data", event.data);
        };

        dataChannel.onclose = function() {
          console.log("data channel is closed");
          alert("data call channel is closed - call hangup");
          localStream.getTracks().forEach(function(track) {
            track.stop();
          });
          window.location.reload();
        };

        // ================================================================================
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // let isAudio = true;
  // function muteAudio() {
  //   isAudio = !isAudio;
  //   localStream.getAudioTracks()[0].enabled = isAudio;
  //   var muteAudio = document.getElementById("mute-audio");
  //   muteAudio.innerText === "Mute Audio"
  //     ? (muteAudio.innerText = "Un Mute Audio")
  //     : (muteAudio.innerText = "Mute Audio");
  // }
  let isAudio = true;
  function muteAudio() {
    isAudio = !isAudio;
    localStream.getAudioTracks()[0].enabled = isAudio;
    var muteAudio = document.getElementById("mute-audio");
    var audioIcon = document.getElementById("audio-icon");
    if (isAudio) {
      audioIcon.classList.remove("fa-microphone-slash");
      audioIcon.classList.add("fa-microphone");
    } else {
      audioIcon.classList.remove("fa-microphone");
      audioIcon.classList.add("fa-microphone-slash");
    }
  }

  // let isVideo = true;
  // function muteVideo() {
  //   isVideo = !isVideo;
  //   localStream.getVideoTracks()[0].enabled = isVideo;
  //   var muteVideo = document.getElementById("mute-video");
  //   muteVideo.innerText == "Mute Video"
  //     ? (muteVideo.innerText = "Un Mute Video")
  //     : (muteVideo.innerText = "Mute Video");
  // }
  let isVideo = true;
  function muteVideo() {
    isVideo = !isVideo;
    localStream.getVideoTracks()[0].enabled = isVideo;
    var muteVideo = document.getElementById("mute-video");
    var videoIcon = document.getElementById("video-icon");
    if (isVideo) {
      videoIcon.classList.remove("fa-video-slash");
      videoIcon.classList.add("fa-video");
    } else {
      videoIcon.classList.remove("fa-video");
      videoIcon.classList.add("fa-video-slash");
    }
  }

  function hangUp(e) {
    e.preventDefault();
    peerConn.close();
    localStream.getVideoTracks()[0].enabled = false;
    localStream.getAudioTracks()[0].enabled = false;
    localStream.getTracks().forEach(function(track) {
      track.stop();
    });
    navigate("/");
  }

  //when user clicks the "send message" button
  const sendMsg = () => {
    var msgInput = document.getElementById("msgInput");
    var chatArea = document.querySelector("#chatarea");
    console.log("messageInput", msgInput);
    console.log("chatArea", chatArea);

    var val = msgInput.value;
    console.log("message" + val);
    try {
      dataChannel.send(val);
      var messageDiv = document.createElement("div");
      messageDiv.classList.add("message", "my-message"); // Add a class for styling
      messageDiv.innerText = val + " : Me ";
      chatArea.appendChild(messageDiv);
      chatArea.innerHTML += "<br />";
      msgInput.value = "";
    } catch (error) {
      alert("Please ensure the peer remote connection ");
      msgInput.value = "";
    }
  };

  async function shareScreen() {
    try {
      if (!displayMediaStream) {
        displayMediaStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
      }
      senders
        .find((sender) => sender.track.kind === "video")
        .replaceTrack(displayMediaStream.getTracks()[0]);

      //show what you are showing in your "self-view" video.
      document.getElementById("local-video").srcObject = displayMediaStream;

      //hide the share button and display the "stop-sharing" one
      document.getElementById("share-button").style.display = "none";
      document.getElementById("stop-share-button").style.display = "inline";
    } catch (e) {
      console.log("Unable to acquire screen capture: " + e);
    }
  }

  async function stopShareScreen() {
    try {
      senders
        .find((sender) => sender.track.kind === "video")
        .replaceTrack(
          localStream.getTracks().find((track) => track.kind === "video")
        );
      displayMediaStream = undefined;
      document.getElementById("local-video").srcObject = localStream;
      document.getElementById("share-button").style.display = "inline";
      document.getElementById("stop-share-button").style.display = "none";
    } catch (error) {
      console.log("Unable to stop share screen: " + error);
    }
  }

  return (
    <div>
      <div>
        <Button id="join_call" variant="warning" onClick={joinCall}>
          Join Video Call
        </Button>
      </div>
      <div id="video-call-div" style={{ display: "none" }}>
        <video muted id="local-video" autoPlay></video>
        <video id="remote-video" autoPlay></video>
        <div className="video-action-div">
          <div class="main">
            <div style={{ display: "flex", gap: "1rem" }}>
              <Button id="mute-video" variant="warning" onClick={muteVideo}>
                <i
                  id="video-icon"
                  class="fas fa-video"
                  style={{ color: "#000000" }}
                ></i>
              </Button>
              <Button id="mute-audio" variant="warning" onClick={muteAudio}>
                <i
                  id="audio-icon"
                  class="fas fa-microphone"
                  style={{ color: "#000000" }}
                ></i>
              </Button>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Share Screen</Tooltip>}
              >
                <Button
                  id="share-button"
                  variant="warning"
                  onClick={shareScreen}
                >
                  <MdOutlineScreenShare size={24} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Stop Share</Tooltip>}
              >
                <Button
                  id="stop-share-button"
                  variant="warning"
                  onClick={stopShareScreen}
                  style={{ display: "none" }}
                >
                  <MdOutlineStopScreenShare size={24} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Hang Up</Tooltip>}
              >
                <Button variant="warning" onClick={hangUp}>
                  <i
                    class="fas fa-phone-slash"
                    style={{ color: "#000000" }}
                  ></i>
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Messages</Tooltip>}
              >
                <Button variant="warning" id="chat_Btn" onClick={handleShow}>
                  <i class="fas fa-comment" style={{ color: "#000000" }}></i>
                </Button>
              </OverlayTrigger>
            </div>
            <div class="main__right">
              <div class="main__header">
                <h6>
                  <span class="live"></span>LIVE CHAT
                </h6>
              </div>
              <div className="videochatBox">
                <div id="chatarea"></div>
                <div class="main__message_container">
                  <input
                    type="text"
                    id="msgInput"
                    placeholder="Type message here.."
                  />
                  <button class="sendMsgBtn" id="sendMsgBtn" onClick={sendMsg}>
                    <i class="fa fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCallReceiver;
