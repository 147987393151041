import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Offcanvas,
  Nav,
  NavItem,
  Button,
  NavDropdown,
  Image,
  Form,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { GiLotus } from "react-icons/gi";
import { UseAuth } from "../context/Auth";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangChager from "../component/LangChanger";
import { vasthuUrl } from "../component/Api";

const Main = () => {
  const { onLogout, userId } = UseAuth();
  const location = useLocation();
  const lang1 = window.localStorage.getItem("i18nextLng");
  console.log("after login", lang1);
  const iniLang = localStorage.getItem("i18nextLng");
  const token = window.sessionStorage.getItem("token");
  const AuthName = window.sessionStorage.getItem("authname");
  const rerender = window.sessionStorage.getItem("rerender");
  const WalletAmount = JSON.parse(
    window.sessionStorage.getItem("WalletAmount")
  );
  const WalletBalance = WalletAmount ? WalletAmount.balance : null;
  const history = useNavigate();
  const [activeTabs, setActiveTabs] = useState();
  const [menu, setMenu] = useState(false);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(iniLang);
  const [toggle, setToggle] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [savemoney, setSaveMoney] = useState([]);

  const handleOffcanvasClose = () => {
    setShowOffcanvas(false);
  };

  useEffect(() => {
    window.close(); //on load close() event for bill desk -- (While child window flag is set to true)
    if (rerender) {
      return history(rerender);
    }
  }, [window.sessionStorage.removeItem("rerender")]);

  useEffect(() => {
    try {
      vasthuUrl
        .get(`/getuserbalance/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setSaveMoney(res.data);
          window.sessionStorage.setItem(
            "WalletAmount",
            JSON.stringify(res.data)
          );
        });
    } catch (e) {
      console.log("service", e);
    }
  }, [WalletBalance]);

  return (
    <React.Fragment>
      <Navbar
        bg="light"
        expand="lg"
        collapseOnSelect={true}
        style={{
          zIndex: 2,
          borderBottom: "1px solid #ffbb33",
          backgroundColor: "#ffbb34",
        }}
        className="nav_header"
      >
        <Container fluid style={{ backgroundColor: "#ffc33b" }}>
          {/* <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-'lg'`} /> */}
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-'lg'`}
            onClick={() => setShowOffcanvas(!showOffcanvas)}
          />
          <Navbar.Brand
            style={{
              fontWeight: "bold",
              cursor: "pointer",
              backgroundColor: "#ffc33b",
            }}
          >
            <NavLink to="/" className="nav_linkhead">
              {/* <GiLotus
                color="#ffbb33"
                size={30}
                width={30}
                height={30}
                className="d-inline-block align-top"
              />{" "} */}
              <Image
                src="Vlogo4.png"
                class="img-fluid"
                object="fill"
                width={40}
                height={40}
                style={{
                  backgroundColor: "#392600",
                  borderRadius: "7px",
                  marginRight: "10px",
                }}
                //#392600
              />
              {t("CM_PUB_LOGO_TITLE")}
            </NavLink>
          </Navbar.Brand>
          {savemoney && savemoney.balance > 0 ? (
            <b>
              {t("USR.WALT.BALCE")}: ₹ {savemoney.balance}
            </b>
          ) : null}

          {/* <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-'lg'`} /> */}
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-'lg'`}
            aria-labelledby={`offcanvasNavbarLabel-expand-'lg'`}
            placement="start"
            className="home-drawer"
            style={{ width: "70%" }}
            show={showOffcanvas}
            onHide={handleOffcanvasClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-'lg'`}>
                {/* {t("USR.HOM.HD.MEN")} */}
                <Image
                  height={60}
                  width={65}
                  alt="logo"
                  src="Vlogo4.png"
                  style={{
                    backgroundColor: "#392600",
                    borderRadius: "7px",
                    marginRight: "10px",
                  }}
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="home-drawer">
              <Nav
                className={
                  lang1 === "en"
                    ? "eng_font justify-content-end flex-grow-1 pe-3 header_navbar"
                    : lang1 === "ta"
                    ? "tam_font justify-content-end flex-grow-1 pe-3 header_navbar"
                    : "hi_font justify-content-end flex-grow-1 pe-3 header_navbar"
                }
              >
                {/* <NavLink className="nav_link" to="/"> */}
                <NavLink
                  to="/"
                  className="nav_linkhead"
                  onClick={handleOffcanvasClose}
                >
                  {t("USR.HOM.HD.HOM")}
                </NavLink>
                <NavDropdown className="nav_dpd_title" title={t("USR.NAV.VAS")}>
                  <NavDropdown.Item>
                    <NavLink
                      className="nav_link"
                      to="plotvasthu"
                      onClick={handleOffcanvasClose}
                    >
                      {t("USR.HOM.HD.VFP")}
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Divider className="all_border" />
                  <NavDropdown.Item>
                    <NavLink
                      className="nav_link"
                      to="homevasthu"
                      onClick={handleOffcanvasClose}
                    >
                      {t("USR.HOM.HD.VFH")}
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Divider className="all_border" />
                  <NavDropdown.Item>
                    <NavLink
                      className="nav_link"
                      to="buildingdetails"
                      onClick={handleOffcanvasClose}
                    >
                      {t("USR.HOM.HD.BD")}
                    </NavLink>
                  </NavDropdown.Item>
                </NavDropdown>
                {/* <NavLink className="nav_link" to="plotvasthu" onClick={handleOffcanvasClose}>
                  {t("USR.HOM.HD.VFP")}
                </NavLink> */}
                {/* <NavDropdown.Divider />
                <NavLink className="nav_link" to="homevasthu" onClick={handleOffcanvasClose}>
                  {t("USR.HOM.HD.VFH")}
                </NavLink>
                <NavDropdown.Divider /> */}
                {/* <NavLink className="nav_link" to="buildingdetails" onClick={handleOffcanvasClose}>
                  {t("USR.HOM.HD.BD")}
                </NavLink>
                <NavDropdown.Divider /> */}
                <NavLink
                  className="nav_linkhead"
                  to="customerpricing"
                  onClick={handleOffcanvasClose}
                >
                  {t("CM.FT.COL3.L1")}
                </NavLink>

                <NavDropdown
                  className="nav_dpd_title"
                  title={t("USR.HOM.NAV.BOOKS")}
                >
                  <NavDropdown.Item>
                    <NavLink
                      className="nav_link"
                      to="consultantbook"
                      onClick={handleOffcanvasClose}
                    >
                      {t("USR.HOM.HD.BOOK")}
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Divider className="all_border" />
                  <NavDropdown.Item>
                    <NavLink
                      className="nav_link"
                      to="consultantbooklist"
                      onClick={handleOffcanvasClose}
                    >
                      {t("USR.HOM.HD.SBOOK")}
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Divider className="all_border" />
                  <NavDropdown.Item>
                    <NavLink
                      className="nav_link"
                      to="appointmenthistory"
                      onClick={handleOffcanvasClose}
                    >
                      {t("USR.HOM.HD.BOOKH")}
                    </NavLink>
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  className="nav_dpd_title"
                  title={t("USR.HOM.HD.A")}
                >
                  <NavDropdown.Item>
                    <NavLink
                      className="nav_link"
                      to="customeraccount"
                      onClick={handleOffcanvasClose}
                    >
                      {t("USR.HOM.HD.MA")}
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Divider className="all_border" />
                  <NavDropdown.Item>
                    <NavLink
                      className="nav_link"
                      to="customerprofile"
                      onClick={handleOffcanvasClose}
                    >
                      {t("USR.HOM.HD.PROF")}
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Divider className="all_border" />
                  <NavDropdown.Item>
                    <NavLink
                      className="nav_link"
                      to="customerpayment"
                      onClick={handleOffcanvasClose}
                    >
                      {t("USR.HOM.HD.PAY")}
                    </NavLink>
                  </NavDropdown.Item>
                  {/* <NavDropdown.Divider className="border-warning" /> */}
                  {/* <NavDropdown.Item>
                    <NavLink className="nav_link" to="paymentresponse">
                      {t("USR.HOM.HD.PAYR")}
                    </NavLink>
                  </NavDropdown.Item> */}
                  <NavDropdown.Divider className="border-warning" />
                  <NavDropdown.Item>
                    <NavLink
                      className="nav_link"
                      to="audiocall"
                      onClick={handleOffcanvasClose}
                    >
                      {t("USR.HOM.HD.AUD")}
                    </NavLink>
                  </NavDropdown.Item>
                  {/* <NavDropdown.Divider className="all_border" />
                  <NavDropdown.Item>
                    <NavLink className="nav_link" to="customerreview">
                      {t("USR.HOM.HD.REV")}
                    </NavLink>
                  </NavDropdown.Item> */}
                  {/* <NavDropdown.Divider className="border-warning" /> */}
                  {/* <NavDropdown.Item>
                    <NavLink className="nav_link" to="myrefunds">
                  {t("USR.ACC.ACT.RFD.RFD")}
               
                    </NavLink>
                  </NavDropdown.Item> */}
                </NavDropdown>

                <NavDropdown className="nav_dpd_title" title={t("USR.NAV.PRO")}>
                  <NavDropdown.Item>
                    <NavLink
                      className="nav_link"
                      to="product"
                      onClick={handleOffcanvasClose}
                    >
                      {t("USR.NAV.PRO")}
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Divider className="all_border" />
                  <NavDropdown.Item>
                    <NavLink
                      to="likedproduct"
                      className="nav_link"
                      onClick={handleOffcanvasClose}
                    >
                      {t("HOM.NAV.PRO.LIK")}
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Divider className="all_border" />
                  <NavDropdown.Item>
                    <NavLink
                      to="bookedproduct"
                      className="nav_link"
                      onClick={handleOffcanvasClose}
                    >
                      {t("HOM.NAV.PRO.BOK")}
                    </NavLink>
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown.Divider />

                <Button className="all_btn logout_btn" onClick={onLogout}>
                  {t("USR.HOM.BTN.LOGOUT")}
                </Button>
                <NavDropdown.Divider />
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <Form.Group
            className="langchange"
            style={{ display: "flex", flexDirection: "row" }}
            controlId="lang"
          >
            {AuthName ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{t("TXT.HELO.LO")}</span>
                <h5 style={{ marginRight: "1rem" }}>
                  <b style={{ color: "#fff" }}>{AuthName}</b>
                </h5>
              </div>
            ) : null}
            <LangChager />
          </Form.Group>
        </Container>
      </Navbar>
      <Outlet />
    </React.Fragment>
  );
};

export default Main;
