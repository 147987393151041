import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stacks from "@mui/material/Stack";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Form,
  Image,
  Modal,
  Spinner,
  Table
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { vasthuUrl } from "../component/Api";

const UserAppointHistory = () => {
  const today = new Date();
  // const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const date = format(new Date(), "yyyy-MM-dd");
  console.log("date", date);
  // const { userId } = UseAuth();
  // const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const role = window.sessionStorage.getItem("role");
  const user = window.sessionStorage.getItem("userId");
  const crmSelectedUser = window.sessionStorage.getItem("crmSelectedUser");
  const userId = token && role == "ROLE_CRM" ? crmSelectedUser : user;
  const navigate = useNavigate();
  const [userMeetingHistoryList, setUserMeetingHistoryList] = useState([]);
  const [rating, setRating] = useState(3);
  const [reviewDesc, setReviewDesc] = useState("");
  const [msg, setMsg] = useState("");
  const [show, setShow] = useState(false);
  const [profDetails, setProfDetails] = useState({
    profName: "Professional",
    profUuid: "abc12",
    profSlotId: 123,
  });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  //model
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showReviewModal1, setShowReviewModal1] = useState(false);
  const [p, setP] = useState();
  const [cancelReason, setCancelReason] = useState("");
  const [count, setCount] = useState();
  const [messageType, setMessageType] = useState("error");
  const [slotList, setSlotList] = useState(false);
  const [reasonList, setReasonList] = useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`getoveralluserbookinghistory/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setUserMeetingHistoryList(res.data);
          setLoading(false);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }

    try {
      vasthuUrl
        .get(`cancel/getUserCancelReasons`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setReasonList(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }
  }, [slotList, showReviewModal1]);

  const showModal = () => {
    setShowReviewModal(true);
  };

  // const alert = (msg) => {
  //   setMsg(msg);
  //   setShow(true);
  //   setTimeout(() => {
  //     setShow(false);
  //     setMsg("");
  //   }, 3000);
  // };

  const ReviewForProfessional = async () => {
    try {
      if (rating == 0) {
        return alert(`Please Rate  ${profDetails.profName}`);
      }

      const res = await vasthuUrl.post(
        "saveconsultantreview",
        {
          user_uuid: userId,
          consultant_uuid: profDetails.profUuid,
          user_rating: rating,
          review_description: reviewDesc,
          slot_id: profDetails.profSlotId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data);
      alert(res.data.message);
      setReviewDesc("");
      setRating(0);
      setTimeout(() => {
        setShowReviewModal(false);
      }, 2000);
    } catch (error) {
      return alert("SERVER ERROR");
    }
  };

  const ReviewModal = (i) => {
    setShowReviewModal1(true);
    setP(i);
    setCount(i.cancelCount);
  };

  const handleViewDetailsclose = () => {
    setShowReviewModal1(false);
    setP();
    setCancelReason("");
  };
  console.log("p", p);
  console.log("setP", setP);

  const cancelbookedslot = () => {
    if (!validator.isEmpty(cancelReason)) {
      vasthuUrl
        .post(
          `cancelbookedslot`,
          {
            slot: p.slotId,
            subscription: p.subscriptionId,
            booking: p.bookingid,
            reason: cancelReason,
            role: role,
            cancelledByUuid: token && role == "ROLE_CRM" ? user : userId, // newly added
          },

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("slot cancel response", res.data);
          if (res.data) {
            alert(res.data.message);
            setMessageType("success");
          }
          setSlotList(true);
          handleViewDetailsclose();
        })
        .catch((e) => {
          console.log("Slot cancel_catch_block" + e);
        });
    } else {
      alert("Please Select Reason");
      setMessageType("error");
    }
  };
  console.log("userMeetingHistoryList", userMeetingHistoryList);

  const handleClosess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  return (
    <Container>
      <div>
        <center>
          <h2>{t("USR.HOM.HD.BOOKH")}</h2>
        </center>
      </div>
      {!loading ? (
        userMeetingHistoryList.length > 0 ? (
          <>
            <Table
              striped
              bordered
              hover
              responsive
              className="text-center all_border deskTable"
            >
              <thead>
                <tr>
                  <th>{t("TXT.CON.LOCA")}</th>
                  <th>{t("TXT.CAL.DAT.UP")}</th>
                  <th>{t("PR.USRMTNG.TBL.CLMN.CT.CMM")}</th>
                  {/* <th>{t("PR.APMNT.MTNGTAB.TBL.CLMN.BS")}</th> */}
                  <th>{t("TXT.CAL.STS.UP")}</th>
                  <th>{t("USR.CALL.CCL.RS")}</th>
                  <th>{t("USR.CAL.BK.BY")}</th>
                  <th>{t("USR.CAL.CCL.BY")}</th>
                  <th>{t("USR.CON.COL.FB")}</th>
                  {/* <th>{t("TXT.RE.SCH.UP")}</th> */}
                </tr>
              </thead>
              <tbody>
                {userMeetingHistoryList.map((i, j) => (
                  <tr key={j}>
                    <td>{i.name}</td>
                    <td>{i.callDate}</td>
                    <td>{i.callTime}</td>
                    <td>
                      {i.serviceStatus == "ACTIVE"
                        ? "UPCOMING"
                        : i.serviceStatus}
                    </td>
                    <td>{i.cancelReason !== null ? i.cancelReason : "NA"}</td>
                    <td>{i.bookedBy !== null ? i.bookedBy : "-"}</td>
                    <td>{i.cancelledBy !== null ? i.cancelledBy : "-"}</td>
                    <td>
                      {i.serviceStatus == "COMPLETED" ||
                      i.serviceStatus == "PARTIALLY_COMPLETED" ? (
                        <Button
                          variant={
                            i.serviceStatus == "UPCOMING"
                              ? "outline-secondary"
                              : "outline-warning"
                          }
                          onClick={() => {
                            console.log("II", i);
                            showModal();
                            setProfDetails({
                              profName: i.name,
                              profUuid: i.uuid,
                              profSlotId: i.slotId,
                            });
                          }}
                          className="mt-2"
                          disabled={
                            i.serviceStatus == "COMPLETED"
                              ? false
                              : i.serviceStatus == "PARTIALLY_COMPLETED"
                              ? false
                              : true
                          }
                        >
                          {t("USR.HOM.REV.TEXT4")}
                        </Button>
                      ) : (
                        "-"
                      )}
                    </td>
                    {/* <td>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          your remaining schudle  {3 - i.cancelCount}
                        </Tooltip>
                      }
                    >
                      <Button
                        className="all_btn"
                        onClick={() => ReviewModal(i)}
                        disabled={(i.cancelCount >= 3 || i.serviceStatus !== "MISSED" || i.callDate !== date ? true : false)}>{t("TXT.RE.SCH.LO")}
                      </Button>
                    </OverlayTrigger>
                  </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="justify-content-center mobileCard">
              {userMeetingHistoryList.map((i, j) => (
                <Card
                  key={j}
                  className="mobileCard all_border"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "antiquewhite",
                  }}
                >
                  <Card.Body>
                    <Card.Subtitle className="mb-2">
                      <b>{t("TXT.CAL.HIS.PF")}:</b> {i.name}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>{t("TXT.CAL.DAT.UP")}:</b> {i.callDate}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b> {t("PR.USRMTNG.TBL.CLMN.CT.CMM")}:</b> {i.callTime}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>{t("TXT.CAL.STS.UP")}:</b> {i.serviceStatus}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>{t("USR.CALL.CCL.RS")}:</b>{" "}
                      {i.cancelReason !== null ? i.cancelReason : "NA"}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>{t("USR.CAL.BK.BY")}:</b>
                      {i.bookedBy !== null ? i.bookedBy : "-"}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>{t("USR.CAL.CCL.BY")}:</b>{" "}
                      {i.cancelledBy !== null ? i.cancelledBy : "-"}
                    </Card.Subtitle>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      {i.serviceStatus == "COMPLETED" ||
                      i.serviceStatus == "PARTIALLY_COMPLETED" ? (
                        <Button
                          variant={
                            i.serviceStatus == "UPCOMING"
                              ? "outline-secondary"
                              : "outline-warning"
                          }
                          onClick={() => {
                            console.log("II", i);
                            showModal();
                            setProfDetails({
                              profName: i.name,
                              profUuid: i.uuid,
                              profSlotId: i.slotId,
                            });
                          }}
                          className="mt-2"
                          disabled={
                            i.serviceStatus == "COMPLETED"
                              ? false
                              : i.serviceStatus == "PARTIALLY_COMPLETED"
                              ? false
                              : true
                          }
                        >
                          {t("USR.HOM.REV.TEXT4")}
                        </Button>
                      ) : null}
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </>
        ) : (
          <div>
            <center>
              <h5>{t("USR.COM.ACK.TEXT1")}</h5>
            </center>
          </div>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}

      <Modal
        show={showReviewModal}
        onHide={() => {
          setShowReviewModal(false);
          setReviewDesc("");
          setRating(3);
        }}
        size="lg"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="all_background" closeButton>
          {show ? (
            <Stacks spacing={2} sx={{ width: "100%" }}>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={openSnack}
                autoHideDuration={3000}
                onClose={handleClosess}
              >
                <MuiAlert
                  variant="filled"
                  onClose={handleClosess}
                  severity={messageType}
                  sx={{
                    width: "100%",
                  }}
                >
                  {message}
                </MuiAlert>
              </Snackbar>
            </Stacks>
          ) : (
            <Modal.Title id="contained-modal-title-vcenter">
              <Image
                fluid
                thumbnail
                roundedCircle
                style={{ width: 70, height: 70, marginRight: "20px" }}
                src="https://source.unsplash.com/user/c_v_r/100x100"
                alt="profile"
              />
              {profDetails.profName}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h6>{t("USR.BOK.HIS.RAT")}</h6>

            <div
              className="star-rating"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    style={{
                      backgroundColor: "transparent",
                      outline: "none",
                      cursor: "pointer",
                      border: "none",
                      position: "relative",
                    }}
                    key={index}
                    className={index <= rating ? "on" : "off"}
                    onClick={() => {
                      console.log("STAR RATING", index);
                      setRating(index);
                    }}
                  >
                    <span
                      className="star"
                      style={{ fontSize: "30px", textAlign: "center" }}
                    >
                      &#9733;
                    </span>
                  </button>
                );
              })}
            </div>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <h6>
                {profDetails.profName} &nbsp;
                {t("USR.BOK.HIS.RAT.CON")}
              </h6>
              <Form.Control
                as="textarea"
                className="all_border"
                rows={5}
                size="sm"
                maxLength={250}
                onChange={(e) => {
                  setReviewDesc(e.target.value);
                }}
                placeholder={t("USR.BOK.HIS.RAT.REV")}
                value={reviewDesc}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-evenly all_border">
          <Button
            className="all_btn"
            onClick={() => {
              setShowReviewModal(false);
              setReviewDesc("");
              setRating(3);
            }}
          >
            {t("TXT.CLO.LO")}
          </Button>
          <Button className="all_btn" onClick={ReviewForProfessional}>
            {t("USR.HOM.BTN.SUB")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showReviewModal1}
        onHide={handleViewDetailsclose}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="v_modal"
      >
        <Modal.Header closeButton className="all_background">
          <Modal.Title>Cancel or Reschedule Booking</Modal.Title>
        </Modal.Header>
        {loading ? (
          <center>
            <Spinner animation="border" variant="warning" />
          </center>
        ) : (
          <Modal.Body>
            <div style={{ color: "red", fontWeight: "bold" }} className="mb-3">
              {/* You are allowed {3 - count} more time only for cancellation!!! */}
              {3 - count} time you are allowed to cancel!!!
            </div>

            <Form>
              <Form.Select
                // style={{ width: "70%" }}
                className="form_feild"
                onChange={(e) => {
                  setCancelReason(e.target.value);
                }}
              >
                <option value="">Select Cancel or Reschedule Reason</option>
                {reasonList.map((i, j) => (
                  <option key={j} value={i.user_Reasons}>
                    {i.user_Reasons}
                  </option>
                ))}
              </Form.Select>
            </Form>
          </Modal.Body>
        )}
        <Modal.Footer className="consultantbtn all_border d-flex justify-content-evenly">
          <Button className="all_btn" onClick={handleViewDetailsclose}>
            Close
          </Button>
          <Button className="all_btn" onClick={(i) => cancelbookedslot()}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserAppointHistory;
