import React, { useState, useEffect, Suspense } from "react";
import { vasthuUrl } from "../component/Api";

import { Navigate, useNavigate, useLocation } from "react-router-dom";

import { Button, Modal, Spinner } from "react-bootstrap";
import NewBootCard from "../component/NewBootCard";

import { UseAuth } from "../context/Auth";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import Toast from "react-bootstrap/Modal";
import { format, isToday } from "date-fns";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const AudioCallProfessional = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [audiocallHistoryList, setAudiocallHistoryList] = useState([
    {
      callFrom: "",
      callTo: "",
      duration: "",
      offerId: "",
      offerName: "",
      status: "",
      proposedCallDuration: "",
    },
  ]);
  const token = window.sessionStorage.getItem("token");
  const uuid = window.sessionStorage.getItem("userId");
  const location = useLocation();
  const [callStatus, setCallStatus] = useState("Audiocall List");
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [callStatusPro, setCallStatusPro] = useState();
  const [waitingTime, setWaitingTime] = useState();
  console.log("waitingTime", waitingTime);
  const [consulName, setConsulName] = useState();
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const formatDate = (date) => {
    try {
      return format(date, "d MMM yyyy, HH:mm"); // Example format string
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return ""; // or return a default value
    }
  };

  const closeTosastBtn = () => {
    setToast(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  useEffect(() => {
    console.log("screen: Audiocall.js");
    setLoading(true);
    vasthuUrl
      .get(`vasthu/user/getAudioCallProHistory/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((rs) => {
        console.log("achistory", rs.data);
        setAudiocallHistoryList(rs.data);
        setLoading(false);
      })
      .catch((er) => console.log("call history err", er));
    setLoading(false);
  }, []);

  const GetConsultantCallStatus = (id) => {
    setConsulName(id.callFrom);
    // setLoading(true);
    try {
      vasthuUrl
        .post(`vasthu/user/getprostatusavalaibility`, {
          // pro_uuid: id.call_to_id,
          // user_uuid: id.call_from_id,
          pro_uuid: id.call_to_id,
          user_uuid: id.call_from_id,
        })
        .then((res) => {
          setCallStatusPro(res.data);
          console.log("res.data", res.data);
          if (res.data.proCurrentCallStatus == "in-progress") {
            setToast(true);
            // alert(`Consultant Already on a call please wait ${(Math.round(res.data.waitingTime / 60))} mins!!!`);
            setWaitingTime(Math.round(res.data.waitingTime / 60));
            console.log(res.data);
          } else {
            openModelCall(id);
          }
        });
    } catch (err) {
      console.log("SERVER ERROR", err);
    }
  };
  const openModelCall = (id) => {
    const res = vasthuUrl
      .post(
        `vasthu/user/clicktocall`,
        {
          from: id.call_from_id, //"09790580304",
          to: id.call_to_id, //"07092758264",
          duration: id.proposedCallDuration, //30min
          subscriptionId: id.subscriptionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("postresponse", res);
        if (res.status == "200") {
          setCallStatus(t("USR.CAL.PROCED.BTN"));
          navigate("/audiocallstatus", {
            state: { callStatus: "USR.CAL.PROCED.BTN" },
          });

          // setTimeout(() => {
          //   navigate("/audiocall", { replace: true });
          // }, 30000);
        } else {
          setCallStatus(t("USR.CAL.FAILD.BTN"));
          console.log(t("USR.CAL.FAILD.BTN"));
        }
      })
      .catch((err) => {
        setCallStatus(t("USR.CAL.FAILD.BTN"));

        navigate("/audiocallstatus", {
          state: { callStatus: "USR.CAL.FAILD.BTN" },
        });
      });
  };

  const listtemp = audiocallHistoryList.map((obj, index) => {
    return {
      ...obj,
      id: index + 1,
      start_time: formatDate(new Date(obj.start_time)), // Format start_time
      duration: Math.round(obj.duration / 60), // Convert duration to minutes
      proposedCallDuration: Math.round(obj.proposedCallDuration / 60), // Convert proposedCallDuration to minutes
    };
  });

  const columns = [
    { field: "id", headerName: t("CMN.S.NO"), width: 80 },
    { field: "callFrom", headerName: t("USR.HOM.HD.UN"), width: 150 },
    { field: "callTo", headerName: t("CON.AUD.CAL.HIS.CLT"), width: 150 },
    { field: "start_time", headerName: t("TXT.CAL.DAT.LO"), width: 150 },
    {
      field: "duration",
      headerName: t("TXT.DUR.SPOK.LO"),
      width: 150,
    },
    {
      field: "proposedCallDuration",
      headerName: t("TXT.TOT.CAL.DUR.LO"),
      width: 150,
    },
    { field: "offerName", headerName: t("USR.PY.HS.TB.COL1"), width: 150 },
    { field: "status", headerName: t("TXT.CAL.STS.LO"), width: 150 },
    {
      field: "actions",
      headerName: t("CON.AUD.CAL.HIS.CONT"),
      type: "actions",
      width: 150,
      renderCell: (i) => (
        <>
          {i.row.status === "completed" ? (
            "-"
          ) : (
            <Button
              className="all_btn"
              disabled={
                !isToday(new Date(i.row.start_time)) ||
                !(
                  parseInt(i.row.proposedCallDuration) >
                  parseInt(i.row.duration)
                ) ||
                i.row.status === "cancelled" ||
                i.row.status === "in-progress" ||
                i.row.status === "onhold"
              }
              onClick={async () => {
                GetConsultantCallStatus(i.row);
              }}
            >
              {t("CON.AUD.CAL.HIS.JOIN.BTN")}
            </Button>
          )}
        </>
      ),
    },
  ];
  columns[4].headerName += " " + t("TXT.MIN().LO");
  columns[5].headerName += " " + t("TXT.MIN().LO");

  return (
    // <div>
    // {/* <div>{callStatus}</div> */}
    // {/* <div>{audiocallHistoryList}</div> */}
    <React.Fragment>
      <div>
        <center>
          <h4 className="m-1">
            <u>{t("USR.AUDCH.TEXT1")}</u>
          </h4>
        </center>
      </div>
      {!loading ? (
        audiocallHistoryList.length > 0 ? (
          <>
            {/* <Table
              striped
              bordered
              hover
              responsive
              className="deskTable  all_border text-center"
            >
              <thead>
                <tr>
                  <th>{t("TXT.NM.LO")}</th>
                  <th>{"Client"}</th>
                  <th>{t("TXT.CAL.DAT.LO")}</th>
                  <th>{t("TXT.DUR.SPOK.LO")}{t("TXT.MIN().LO")}</th>
                  <th>{t("TXT.TOT.CAL.DUR.LO")}{t("TXT.MIN().LO")}</th>
                  <th>{t("TXT.OFF.NM.LO")}</th>
                  <th>{t("TXT.CAL.STS.LO")}</th>
                  <th>{"Connect"}</th>
                </tr>
              </thead>
              <tbody>
                {audiocallHistoryList.map((i, j) => (
                  <tr key={j}>
                    <td>{i.callFrom}</td>
                    <td>{i.callTo}</td>
                    <td>{formatDate(new Date(i.start_time))}</td>
                    <td>{Math.round(i.duration / 60)}</td>
                    <td>{Math.round(i.proposedCallDuration / 60)}</td>
                    <td>{i.offerName}</td>
                    <td>{i.status}</td>
                    <td>
                      <Button
                        className="all_btn"
                        disabled={
                          !isToday(new Date(i.start_time)) ||
                          !(
                            parseInt(i.proposedCallDuration) >
                            parseInt(i.duration)
                          ) ||
                          i.status == "completed" ||
                          i.status == "cancelled" ||
                          i.status == "onhold"
                        }
                        // disabled={today?false:true}
                        // onClick={handleShow}
                        // onClick={() => clicktocall(i.call_to_id,i.call_from_id,i.subscriptionId, i.proposedCallDuration)}
                        onClick={async () => {
                          GetConsultantCallStatus(i);
                        }}
                      >
                        {t("TXT.JO.AGN.LO")}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table> */}
            <center className="datagrid">
              <Grid container className="deskTable datagrid">
                <DataGrid
                  className="all_border - text-center me-3 data_grid"
                  // rows={props.rows}
                  rows={listtemp}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  getRowId={(row) => row.id}
                />
              </Grid>
            </center>

            <div className=" mobileCard justify-content-center mx-1">
              {audiocallHistoryList.map((i, j) => (
                <NewBootCard key={j}>
                  <h4>
                    {t("TXT.NM.LO")}: {i.callFrom}
                  </h4>
                  <h6>
                    {t("CON.AUD.CAL.HIS.CLT")}: {i.callTo}
                  </h6>
                  <h6>
                    {t("TXT.CAL.DAT.LO")}:{formatDate(new Date(i.start_time))}
                  </h6>
                  <h6>
                    {t("TXT.DUR.SPOK.LO")}
                    {t("TXT.MIN().LO")}:{Math.round(i.duration / 60)}
                  </h6>
                  <h6>
                    {t("TXT.TOT.CAL.DUR.LO")}
                    {t("TXT.MIN().LO")}:
                    {Math.round(i.proposedCallDuration / 60)}
                  </h6>
                  <h6>
                    {t("TXT.OFF.NM.LO")}: {i.offerName}
                  </h6>
                  <h6>
                    {t("TXT.CAL.STS.LO")}: {i.status}
                  </h6>
                  <div class="d-grid gap-2 d-md-block">
                    {i.status === "completed" ? (
                      " "
                    ) : (
                      <Button
                        className="all_btn"
                        disabled={
                          !isToday(new Date(i.start_time)) ||
                          !(
                            parseInt(i.proposedCallDuration) >
                            parseInt(i.duration)
                          ) ||
                          i.status === "cancelled" ||
                          i.status === "in-progress" ||
                          i.status === "onhold"
                        }
                        onClick={async () => {
                          GetConsultantCallStatus(i);
                        }}
                      >
                        {t("CON.AUD.CAL.HIS.JOIN.BTN")}
                      </Button>
                    )}
                  </div>
                  <Modal show={show1} onHide={handleClose1}>
                    <Modal.Header className="all_background" closeButton>
                      <Modal.Title>{t("TXT.CAL.PRO.ALRT.LO")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t("TXT.REA.WAT.CONTU.LO")}</Modal.Body>
                    <Modal.Footer className="d-flex justify-content-evenly">
                      <Button className="all_btn" onClick={handleClose1}>
                        {t("TXT.CLO.LO")}
                      </Button>
                      <Button
                        className="all_btn"
                        // onClick={() => {
                        //   handleClose1();
                        //   clicktocall(
                        //     i.call_from_id,
                        //     i.call_to_id,
                        //     i.subscriptionId,
                        //     i.proposedCallDuration
                        //   );
                        // }}
                      >
                        Proceed
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </NewBootCard>
              ))}
            </div>
          </>
        ) : (
          <center>
            <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
          </center>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}

      <Toast onHide={() => setToast(false)} show={toast} className="all_border">
        <Toast.Header closeButton className="all_background">
          {t("TXT.CON.BSY.LO")}!!!
        </Toast.Header>
        <Toast.Body>
          Hello! <b>{consulName}</b> {t("TXT.CON.BSY.ANT.PLS.LO")} {waitingTime}{" "}
          minutes{" "}
        </Toast.Body>
        <Toast.Footer>
          <Button className="all_btn" onClick={closeTosastBtn}>
            close
          </Button>
        </Toast.Footer>
      </Toast>
    </React.Fragment>
    // </div>
  );
};

export default AudioCallProfessional;
