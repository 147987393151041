import React from "react";
import { Routes, Route } from "react-router-dom";
import NoMatch from "../pages/Nopage";
import ProfProtectedRoute from "../component/ProfProtectedRoute";
import Professional from "../pages/Professional";
import CreateSlot from "../pages/Createslot";
import VideoCallSender from "../videocall/sender/VideoCallSender";
import AudioCallProfessional from "../pages/AudioCallProfessional";
import AudioCallStatus from "../component/AudioCallStatus";
import ConsultantReview from "../pages/Consultant/ConsultantReview";


const ProfRoute = () => {
  return (
    <Routes>
      <Route index element={<Professional />} />
      <Route exact path="startcall" element={<VideoCallSender />} />
      <Route exact index path="createslot" element={<CreateSlot />} />
      <Route exact index path="audiocallprofessional" element={<AudioCallProfessional />} />
      <Route path="audiocallstatus" element={<AudioCallStatus />} />
      <Route path="consultantreview" element={<ConsultantReview />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};


export default ProfRoute;
