import React, { useState, useEffect, Suspense } from "react";
import Modal from "react-modal";
import Modal2 from "react-bootstrap/Modal";
import Modal3 from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import {
  Card,
  Col,
  Container,
  Row,
  Badge,
  Form,
  Button,
  Alert,
  Spinner,
  OverlayTrigger,
  Tooltip,
  ListGroup,
  ProgressBar,
  Image,
  FloatingLabel,
} from "react-bootstrap";
import { AiOutlineStar, AiFillSchedule } from "react-icons/ai";
import { MdOutlineLanguage } from "react-icons/md";
import { GrUserExpert } from "react-icons/gr";
import { FiPhoneCall } from "react-icons/fi";
import { BiBadgeCheck, BiBody } from "react-icons/bi";
import { GoPrimitiveDot, GoProject } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stacks from "@mui/material/Stack";
import { vasthuUrl } from "../component/Api";
import BillDeskPay from "../component/BillDeskPay";

const SocialConsultant = ({ details }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const lang = window.localStorage.getItem("i18nextLng");
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const consultantId = window.sessionStorage.getItem("consultantId");
  const duration = window.sessionStorage.getItem("AudioCallDuration");
  const uuid = window.sessionStorage.getItem("userId");
  const [allLang, setAllLang] = useState([]);
  const [allSkill, setAllSkill] = useState([]);
  const [initialProf, setInitialProf] = useState([]);
  const [profList, setProfList] = useState([]);
  const [searchLang, setSearchLang] = useState("All");
  const [searchSkill, setSearchSkill] = useState("ALL");
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);
  const [callStatusPro, setCallStatusPro] = useState();
  const [waitingTime, setWaitingTime] = useState();
  const [consulName, setConsulName] = useState();
  const [selectedConsultant, setSelectedConsultant] = useState({});
  const [selectedConsultantService, setSelectedConsultantService] = useState();
  const [defaultAudioOffer, setDefaultAudioOffer] = useState({});
  const [defaultAudioOffer15, setDefaultAudioOffer15] = useState({});
  const [defaultAudioOffer30, setDefaultAudioOffer30] = useState({});

  console.log("consultantId", consultantId);
  //Modal - active list
  let subtitle;
  const [selectedAudioOffer, setSelectedAudioOffer] = useState(7);
  const [activeServiceList, setActiveServiceList] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  const [consultantServiceList, setConsultantServiceList] = useState([]);

  const [formData, setFormData] = useState({
    duration: 0,
    price: 0,
  });

  console.log("filteredPersons", filteredPersons);

  useEffect(() => {
    setSearchSkill(details.selectedService);
  }, [details]);

  // booking - end
  useEffect(() => {
    vasthuUrl
      .get(`offer/defaultIndividualOffer/audio`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("audiores", res);
        setDefaultAudioOffer({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
      })
      .catch((err) => setDefaultAudioOffer({}));

    vasthuUrl
      .get(`offer/defaultIndividualOffer/audio15`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("audiores15", res);
        setDefaultAudioOffer15({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
      })
      .catch((err) => setDefaultAudioOffer15({}));

    vasthuUrl
      .get(`offer/defaultIndividualOffer/audio30`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("audiores30", res);
        setDefaultAudioOffer30({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
      })
      .catch((err) => setDefaultAudioOffer30({}));
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/vasthu/getvasthuservices`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("services_list" + res.data);
          let consultations = res.data.filter(
            (obj) => obj.serviceType == "CONSULTATION"
          );
          console.log(
            "services_list_consultaions" + consultations[0].serviceType
          );
          setServiceData(consultations);
        });

      vasthuUrl
        .get(`/info/professional/home`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProfList(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  }, []);

  const executePendingAction = () => {
    const pendingAction = window.sessionStorage.getItem("pendingAction");
    if (pendingAction === "GetConsultantCallStatus") {
      const consultantId = window.sessionStorage.getItem("consultantId");
      const consultantName = window.sessionStorage.getItem("consultantName");
      GetConsultantCallStatus(consultantId);
      setConsulName(consultantName);
      // Clear the stored action and data after execution
      window.sessionStorage.removeItem("pendingAction");
    }
  };

  // Call executePendingAction after login
  useEffect(() => {
    executePendingAction();
  }, []);

  const checkLoginAndGetConsultantCallStatus = (id, consultantName) => {
    if (window.sessionStorage.getItem("token") == null) {
      window.sessionStorage.setItem("pendingAction", "GetConsultantCallStatus");
      window.sessionStorage.setItem("consultantId", id);
      window.sessionStorage.setItem("consultantName", consultantName);
      window.sessionStorage.setItem("rerender", location.pathname);
      navigate("/login");
    } else {
      GetConsultantCallStatus(id);
      setConsulName(consultantName);
    }
  };

  const GetConsultantCallStatus = (id) => {
    setLoading(true);
    try {
      vasthuUrl
        .post(`vasthu/user/getprostatusavalaibility`, {
          pro_uuid: id,
          user_uuid: userId,
        })
        .then((res) => {
          setCallStatusPro(res.data);
          setLoading(false);
          openModelCall(res.data);
        });
    } catch (e) {
      openModelCall();
    }
  };

  const openModelCall = (data) => {
    if (data.proCurrentCallStatus === "in-progress") {
      setToast(true);
      setWaitingTime(Math.round(data.waitingTime / 60));
      console.log(data);
    } else {
      openModal();
      console.log(data);
    }
    console.log(data);
  };

  //Consultant busy MODA
  const [toast, setToast] = useState(false);

  const closeTosastBtn = () => {
    setToast(false);
  };

  //Call Duration MODAL
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //Service MODAL
  const [serviceModal, setServiceModal] = React.useState(false);

  function afterServiceModal() {
    console.log("afterOpenModal");
  }

  function closeServiceModal() {
    setServiceModal(false);
  }

  // ALERT MODAL
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [openSnack, setOpenSnack] = React.useState(false);

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
      setMessageType("");
    }, 4000);
  };

  const handleClosess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  //consultant review confirm MODAL
  const [model1, setModel1] = useState(false);

  const modelClose1 = () => {
    setModel1(false);
    setConUuid();
    setConName();
    setConImgUrl();
  };
  const modelOpen1 = () => {
    setModel1(true);
  };

  //consultant review MODAL
  const [model, setModel] = useState(false);

  const modelClose = () => {
    setModel(false);
    setModel1(false);
    setConUuid();
    setConName();
    setConImgUrl();
  };
  const modelOpen = () => {
    setModel(true);
    ConsultaionReview();
  };

  const getConsutantServices = (uuid) => {
    try {
      vasthuUrl
        // .get(`/vasthu/getvasthuservices`, {
        .get(`vasthu/getvasthuservicesbyconsultant/${uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setConsultantServiceList(res.data);
          setSelectedConsultant(uuid);
          if (selectedConsultantService == null) {
            setServiceModal(true);
          } else {
            CheckConsultant(uuid);
          }
        });
    } catch (e) {
      console.log("service", e);
    }
  };

  const CheckConsultant = (id) => {
    if (typeof selectedConsultantService !== "undefined") {
      let value = profList.find(
        (x) => x.consultantUuid === selectedConsultant.consultantUuid || id
      );
      let selectedService = selectedConsultantService;
      window.sessionStorage.setItem(
        "checkcon",
        JSON.stringify({ value, selectedService })
      );
      // navigate("/checkconsultant", {
      //   state: { value, selectedService },
      // });
      navigate("/checkconsultant");
    } else {
      alert("Please Select a service");
      setMessageType("error");
    }
  };

  const payHandler = (txn) => {
    console.log("pay response out", txn);
    vasthuUrl
      .post(
        "vasthu/user/bdpaymentresponseui",
        { txn: txn.txnResponse.transaction_response },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("txnres", res.data);

        // alert(res.data)
        // if (res.data.transaction_error_type == "success") {

        //   console.log("call audio")

        //   navigate("/audiocallstart", { state: res.data });
        // }

        if (res.data.transaction_error_type === "success") {
          console.log("Saving payment_response ....", txn);

          vasthuUrl
            .post("vasthu/user/bdpaymentresponsehandler", txn, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => console.log("after txnsave", res.data))
            .catch((err) => console.log("after txnsave try", err));

          console.log("buying .. subsc");

          setTimeout(() => {
            vasthuUrl
              .post(
                "subscription/buyVasthuOffer",
                {
                  orderId: res.data.orderid,
                  userUuid: userId,
                  offerId: selectedAudioOffer,
                  paymentStatus: true,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("Payment status changed to true");
                console.log("call audio");
                console.log("afterbuysubscr", res.data);
                // navigate("/audiocallstart", { state: res.data });
                try {
                  vasthuUrl.post(
                    `vasthu/user/clicktocall`,
                    {
                      from: consultantId, //"09790580304",
                      to: uuid, //"07092758264",
                      duration: parseInt(duration), //30min
                      subscriptionId: res.data.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  console.log("postresponsefrom audio call", res.data);
                  console.log("postresponsefrom audio call", res);
                  if (res.status == 200) {
                    navigate("/audiocallstatus", {
                      state: { callStatus: "USR.CAL.PROCED.BTN" },
                    });
                    // console.log("offerId - on 200 [already register offer] status",i.id)
                    console.log(
                      "offerId - on 200 [already register offer] status",
                      res.data.id
                    );
                    vasthuUrl
                      .get(
                        // `/subscription/useVasthuOffer/${i.id}`,
                        `/subscription/useVasthuOffer/${res.data.id}`,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      )
                      .then((rs) =>
                        console.log(
                          "offer subscription redeem count used",
                          rs.data.message
                        )
                      )
                      .catch((er) =>
                        console.log(
                          "offer subscription redeem count not reduced",
                          er
                        )
                      );
                  } else {
                    navigate("/audiocallstatus", {
                      state: { callStatus: "USR.CAL.FAILD.BTN" },
                    });
                  }
                } catch (err) {
                  console.log("SERVER ERROR", err);
                }
              });
          }, 1000);
        } else {
          vasthuUrl
            .post(
              "subscription/buyVasthuOffer",
              {
                orderId: res.data.orderid,
                userUuid: userId,
                offerId: selectedAudioOffer,
                paymentStatus: false,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              console.log("Payment status changed to false");
            });
        }
      });
  };

  const handleBuy1 = async (e) => {
    try {
      // e.preventDefault();
      console.log("handle_buy1");
      await vasthuUrl
        .post(
          `vasthu/openapi/createorderapi`,
          {
            userUuid: userId,
            offerId: selectedAudioOffer,
            price: formData.price,
            currency: "356",
            returnUrl: "paymentstatusbilldesk",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("billDesk", res.data);
          BillDeskPay(res.data, payHandler)
            .then((config) => {
              console.log("payout", config);
              window.loadBillDeskSdk(config);
            })
            .catch((err) => console.log("payment initialize error", err));
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePsumbit1 = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      vasthuUrl
        .get(`/subscription/userActiveOffers/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (list) => {
          console.log("Audiocall list available " + JSON.stringify(list.data));
          let filteredAudioList = list.data.filter(
            // (obj) => obj.offerId == selectedAudioOffer
            (obj) => obj.offerId > 2
          );
          console.log("filtered audio list", filteredAudioList);
          console.log("filtered audio list len  ", filteredAudioList.length);

          console.log("filtered audio list", list.data);
          console.log("filtered audio list len  ", list.data.length);

          // setActiveServiceList(list.data);
          setActiveServiceList(filteredAudioList);

          // if ((list.data).length > 0) {
          // if (filteredAudioList.length > 0) {
          //   openModal1();
          if (false) {
          } else {
            console.log("no subscription - please proceed with pay");
            setLoading(false);
            handleBuy1();
          }
        });
    } catch (e) {
      alert("CHECK INTERNET CONNECTION");
      console.log("error from trying subscription list " + e);
      setLoading(false);
      // console.log("SERVER ERROR", e);
    }
  };

  const handleDurationRadio = (e) => {
    if (e.target.value === "15") {
      window.sessionStorage.setItem("AudioCallDuration", String(900));
      setSelectedAudioOffer(defaultAudioOffer15.offerId);
      setFormData({
        duration: e.target.value,
        price: defaultAudioOffer15.offerPrice,
      });
    } else if (e.target.value === "30") {
      window.sessionStorage.setItem("AudioCallDuration", String(1800));
      setSelectedAudioOffer(defaultAudioOffer30.offerId);
      setFormData({
        duration: e.target.value,
        price: defaultAudioOffer30.offerPrice,
      });
    } else {
      window.sessionStorage.setItem("AudioCallDuration", String(3600));
      setSelectedAudioOffer(defaultAudioOffer.offerId);
      setFormData({
        duration: e.target.value,
        price: defaultAudioOffer.offerPrice,
      });
    }
  };

  const [conUuid, setConUuid] = useState();
  const [conName, setConName] = useState();
  const [conImgUrl, setConImgUrl] = useState();
  const [consultantStats, setConsultantStats] = useState({});
  const [userReview, setUserReview] = useState([]);
  const [fiveStars, setFiveStars] = useState(0);
  const [fourStars, setFourStars] = useState(0);
  const [threeStars, setThreeStars] = useState(0);
  const [twoStars, setTwoStars] = useState(0);
  const [oneStar, setOneStar] = useState(0);

  const ConsultaionReview = () => {
    try {
      vasthuUrl
        .get(`usersreviewforconsultant/${conUuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("user reviews", res.data);
          setUserReview(res.data);
        });
    } catch (error) {
      console.log("ERROE" + error);
    }
    try {
      vasthuUrl
        .get(`usersreviewcountforconsultant/${conUuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setConsultantStats(res.data);
          console.log("user count", res.data);
          if (res.data) {
            setFiveStars(
              (res.data.rating5count / res.data.total_review_count) * 100
            );
            setFourStars(
              (res.data.rating4count / res.data.total_review_count) * 100
            );
            setThreeStars(
              (res.data.rating3count / res.data.total_review_count) * 100
            );
            setTwoStars(
              (res.data.rating2count / res.data.total_review_count) * 100
            );
            setOneStar(
              (res.data.rating1count / res.data.total_review_count) * 100
            );
          }
        });
    } catch (error) {
      console.log("ERROE" + error);
    }
  };

  useEffect(() => {
    vasthuUrl
      .get(`vasthu/openapi/getskills`)
      .then((res) => {
        setAllSkill(res.data);
        console.log("all skills", res.data);
      })
      .catch((err) => {
        setAllSkill([]);
        alert("can't get Skills");
      });
    vasthuUrl
      .get("openapi/lang/allCode")
      .then((res) => {
        console.log("available lang", res.data);
        setAllLang(res.data);
      })
      .catch((err) => {
        setAllLang([]);
        alert("can't get lang");
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .post(`info/professional/byskillorlang`, {
          skill: searchSkill,
          langCode: searchLang,
        })
        .then((res) => {
          setInitialProf(res.data);
          setLoading(false);
          if (searchSkill == "Vasthu Consultation") {
            setSelectedConsultantService(3);
          } else if (searchSkill == "Astrology Consultation") {
            setSelectedConsultantService(4);
          } else if (searchSkill == "Building Consultation") {
            setSelectedConsultantService(5);
          } else {
            setSelectedConsultantService();
          }
          console.log("con filter out", res.data);
        });
    } catch (error) {
      setInitialProf((prev) => prev);
      setLoading(false);
      console.log("consultlist", error);
    }
  }, [searchSkill, searchLang]);

  const filteredPersons = initialProf.filter((person) => {
    return person.consultantName
      .toLowerCase()
      .includes(searchField.toLowerCase());
  });

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  const handleSkill = (e) => {
    setSearchSkill(e.target.value);
  };
  const handleLang = (e) => {
    setSearchLang(e.target.value);
  };

  return (
    <div className="p-1">
      <div class="container">
        <div class="row d-flex justify-content-end align-items-center my-1">
          <div class="col-md-8 mt-2">
            <div class="form">
              <input
                type="text"
                class="form-control form-input form_feild"
                placeholder={t("CM.CNSL.SER.PL")}
                onChange={handleChange}
              />
            </div>
          </div>
          <div class="col-md-2 mt-2">
            <Form.Select
              value={searchSkill}
              className="form_feild"
              onChange={handleSkill}
            >
              <option value="ALL">{t("CM.CNSL.ON.SER1")}</option>

              {allSkill.map((i) => (
                <option value={i.skillName} key={i.skillName}>
                  {i.skillName}
                </option>
              ))}
            </Form.Select>
          </div>
          <div class="col-md-2 mt-2">
            <Form.Select
              value={searchLang}
              className="form_feild"
              onChange={handleLang}
            >
              <option value="ALL">{t("CM.CNSL.ON.SER2")}</option>
              {allLang.map((i) => (
                <option value={i.langCode} key={i.id}>
                  {i.language}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </div>
      {!loading ? (
        //   <SearchList filteredPersons={filteredPersons} />
        <div>
          <Modal2
            show={modalIsOpen}
            onHide={closeModal}
            className="all_border"
            centered
            // isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            // // style={customStyles}
            // contentLabel="Audio Call Duration"
          >
            <Modal2.Header closeButton className="all_background">
              <h3>Audio Call Duration</h3>
            </Modal2.Header>
            <h3
              className="text-center mt-3"
              ref={(_subtitle) => (subtitle = _subtitle)}
            >
              {t("TXT.SEL.AUD.CAL.DURA.LO")}
            </h3>
            <form onSubmit={handlePsumbit1}>
              <div
                className="text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <label>15 {t("TXT.MIN.LO")}</label>
                  {/*  15 min */}
                  <input
                    type="radio"
                    name="duration"
                    value={15}
                    onChange={handleDurationRadio}
                    style={{ marginLeft: "revert" }}
                  />
                </div>
                <div>
                  <label>30 {t("TXT.MIN.LO")}</label>
                  {/*  30 min */}
                  <input
                    type="radio"
                    name="duration"
                    value={30}
                    onChange={handleDurationRadio}
                    style={{ marginLeft: "revert" }}
                  />
                </div>
                {/* <input type="submit" /> */}
              </div>
              <Modal2.Footer
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  padding: "5px",
                }}
                className="all_border"
              >
                <Button size="sm" type="submit" className="all_btn">
                  {t("USR.VFP.DRP.BTN.SMT")}
                </Button>
                <Button size="sm" onClick={closeModal} className="all_btn">
                  {t("TXT.BTN.CAN.LO")}
                </Button>
              </Modal2.Footer>
            </form>
          </Modal2>

          <Modal
            isOpen={serviceModal}
            onAfterOpen={afterServiceModal}
            onRequestClose={closeServiceModal}
            // style={customStyles}
            contentLabel="Select Consultaion Service"
          >
            <h2
              className="text-center all_background"
              ref={(_subtitle) => (subtitle = _subtitle)}
            >
              {t("TXT.SEL.SER.LO")}
            </h2>

            <div
              className="text-center"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <FloatingLabel controlId="min">
                <Form.Select
                  aria-label="min"
                  size="sm"
                  className="form_feild"
                  onChange={(e) => {
                    console.log("SelectedConsultationService", e.target.value);
                    setSelectedConsultantService(e.target.value);
                  }}
                >
                  <option>{t("TXT.SEL.SER.LO")}</option>
                  {consultantServiceList.map((service, index) => (
                    <option key={index} value={service.id}>
                      {lang == "en"
                        ? service.serviceName
                        : lang == "ta"
                        ? service.serviceNameTamil
                        : service.serviceNameHindi}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>

              <Button
                size="sm"
                type="submit"
                className="all_btn"
                style={{ width: "100%", marginTop: "8px" }}
                onClick={CheckConsultant}
              >
                {t("USR.VFP.DRP.BTN.SMT")}
              </Button>
            </div>
            <center>
              <Button
                size="sm"
                style={{ width: "100%", marginTop: "8px" }}
                onClick={closeServiceModal}
                className="all_btn"
              >
                {t("TXT.BTN.CAN.LO")}
              </Button>
            </center>
          </Modal>

          <Modal3 show={model1} onHide={modelClose1} className="all_border">
            <Modal3.Header closeButton className="all_background">
              <h3>Consultant Review Details</h3>
            </Modal3.Header>
            <Modal3.Body>
              <div>Are you sure want Consultant Review Details?</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "5%",
                }}
              >
                <Button className="all_btn" onClick={() => modelClose1()}>
                  No
                </Button>
                <Button className="all_btn" onClick={() => modelOpen()}>
                  Yes
                </Button>
              </div>
            </Modal3.Body>
          </Modal3>

          <Modal2
            show={model}
            onHide={modelClose}
            fullscreen={true}
            className="all_border"
          >
            <Modal2.Header closeButton className="all_background">
              <h3>Consultant Review Details</h3>
            </Modal2.Header>
            <Modal2.Body>
              <>
                <Container>
                  <Card className="text-center all_border mt-1">
                    {consultantStats && (
                      <>
                        <Card.Header className="d-flex all_background justify-content-center align-items-center ">
                          {conImgUrl ? (
                            <Image
                              fluid
                              thumbnail
                              roundedCircle
                              style={{ width: 60, height: 60, margin: 5 }}
                              src={conImgUrl}
                              alt="profile"
                            />
                          ) : (
                            <Image
                              fluid
                              thumbnail
                              roundedCircle
                              style={{ width: 60, height: 60, margin: 5 }}
                              src={require("../images/noimage.jpg")}
                              alt="profile"
                            />
                          )}
                          <h5 className="pt-2">
                            {conName} <br />
                            <Badge bg="danger" pill>
                              {consultantStats.average_rating}/5.0
                            </Badge>{" "}
                            ({consultantStats.total_review_count})
                          </h5>
                        </Card.Header>
                        <Card.Body>
                          <ListGroup style={{ border: "1px solid #ffb33e" }}>
                            <ListGroup.Item
                              as="li"
                              className="d-flex justify-content-between align-items-center "
                            >
                              <h6 className="mt-2" style={{ color: "grey" }}>
                                5 - {t("PR.AC.RVWTAB.STRS.CMM")}
                              </h6>
                              <ProgressBar
                                className="d-flex"
                                style={{ width: "70%" }}
                                variant="success"
                                now={fiveStars}
                                // label={`${fiveStars}%`}
                              />
                              <h6 className="mt-2" style={{ color: "grey" }}>
                                {consultantStats.rating5count}
                              </h6>
                            </ListGroup.Item>
                            <ListGroup.Item
                              as="li"
                              className="d-flex justify-content-between align-items-center "
                            >
                              <h6 className="mt-2" style={{ color: "grey" }}>
                                4 - {t("PR.AC.RVWTAB.STRS.CMM")}
                              </h6>
                              <ProgressBar
                                style={{ width: "70%" }}
                                variant="info"
                                now={fourStars}
                                // label={`${fourStars}%`}
                              />
                              <h6 className="mt-2" style={{ color: "grey" }}>
                                {consultantStats.rating4count}
                              </h6>
                            </ListGroup.Item>
                            <ListGroup.Item
                              as="li"
                              className="d-flex justify-content-between align-items-center "
                            >
                              {" "}
                              <h6 className="mt-2" style={{ color: "grey" }}>
                                3 - {t("PR.AC.RVWTAB.STRS.CMM")}
                              </h6>
                              <ProgressBar
                                style={{ width: "70%" }}
                                variant="warning"
                                now={threeStars}
                                // label={`${threeStars}%`}
                              />
                              <h6 className="mt-2" style={{ color: "grey" }}>
                                {consultantStats.rating3count}
                              </h6>
                            </ListGroup.Item>
                            <ListGroup.Item
                              as="li"
                              className="d-flex justify-content-between align-items-center "
                            >
                              <h6 className="mt-2" style={{ color: "grey" }}>
                                2 - {t("PR.AC.RVWTAB.STRS.CMM")}
                              </h6>
                              <ProgressBar
                                style={{ width: "70%" }}
                                variant="danger"
                                now={twoStars}
                                // label={`${twoStars}%`}
                              />
                              <h6 className="mt-2" style={{ color: "grey" }}>
                                {consultantStats.rating2count}{" "}
                              </h6>
                            </ListGroup.Item>
                            <ListGroup.Item
                              as="li"
                              className="d-flex justify-content-between align-items-center "
                            >
                              <h6 className="mt-2" style={{ color: "grey" }}>
                                1 - {t("PR.AC.RVWTAB.STR.CMM")}
                              </h6>
                              <ProgressBar
                                style={{ width: "70%" }}
                                variant="primary"
                                // label={`${oneStar}%`}
                                now={oneStar}
                              />
                              <h6 className="mt-2" style={{ color: "grey" }}>
                                {consultantStats.rating1count}
                              </h6>
                            </ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                      </>
                    )}
                  </Card>
                  {userReview.length > 0 ? (
                    <Card className="mt-2">
                      <Card.Header className="text-center" as="h3">
                        {t("PR.AC.RVWTAB")}
                      </Card.Header>
                      {userReview.map((i, j) => (
                        <Card.Body key={j}>
                          <div className="review-list">
                            <ul>
                              <li>
                                <div className="d-flex">
                                  <div className="left">
                                    <span>
                                      <img
                                        src="https://bootdey.com/img/Content/avatar/avatar1.png"
                                        className="profile-pict-img img-fluid"
                                        alt="userProfile"
                                      />
                                    </span>
                                  </div>
                                  <div className="right">
                                    <h4>
                                      {i.user.userName}
                                      <span className="gig-rating text-body-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 1792 1792"
                                          width="15"
                                          height="15"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                                          ></path>
                                        </svg>
                                        {i.user_rating}
                                      </span>
                                    </h4>
                                    <div className="country d-flex align-items-center">
                                      <div className="country-name font-accent">
                                        {i.user.email}
                                      </div>
                                    </div>
                                    <div className="review-description">
                                      <p>{i.review_description}</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </Card.Body>
                      ))}
                    </Card>
                  ) : (
                    <center>
                      <h5 className="mt-3">{t("PR.AC.RVWTAB.MSG.NORVW")}</h5>
                    </center>
                  )}
                </Container>
                <Button
                  className="all_btn mt-2"
                  style={{
                    float: "right",
                  }}
                  onClick={() => modelClose()}
                >
                  {t("TXT.CLO.LO")}
                </Button>
              </>
            </Modal2.Body>
          </Modal2>

          <Toast
            onHide={() => setToast(false)}
            show={toast}
            className="all_border"
          >
            <Toast.Header closeButton className="all_background">
              {t("TXT.CON.BSY.LO")}!!!
            </Toast.Header>
            <Toast.Body>
              Hello! <b>{consulName}</b> is currenty busy on another call,
              please bear with us for {waitingTime} minutes{" "}
            </Toast.Body>
            <Toast.Footer>
              <Button className="all_btn" onClick={closeTosastBtn}>
                {t("TXT.CLO.LO")}
              </Button>
            </Toast.Footer>
          </Toast>

          <Container className="mt-2">
            <Row className="d-flex  g-2">
              {filteredPersons.length > 0 ? (
                <>
                  {filteredPersons.map((i, j) => (
                    <>
                      {i.professionalAccountStatus === "ACTIVATE" ? (
                        <Col
                          md={6}
                          lg={4}
                          className="d-flex align-items-stretch"
                        >
                          <Card
                            className="d-flex professionalCard all_border"
                            key={i.consultantUuid}
                          >
                            <Card.Header className="social_con_header all_border">
                              {i.imgUrl ? (
                                <Col lg={2}>
                                  <img
                                    src={i.imgUrl}
                                    style={{
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <Badge
                                    className="mt-2"
                                    pill
                                    bg="warning"
                                    text="dark"
                                    size="sm"
                                    onClick={() => {
                                      modelOpen1();
                                      setConUuid(i.consultantUuid);
                                      setConName(i.consultantName);
                                      setConImgUrl(i.imgUrl);
                                    }}
                                  >
                                    <AiOutlineStar size={15} />
                                    {i.consultantRating}
                                  </Badge>
                                </Col>
                              ) : (
                                <Col lg={2}>
                                  <img
                                    src={require("../images/noimage.jpg")}
                                    style={{
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <Badge
                                    className="mt-2"
                                    pill
                                    bg="warning"
                                    text="dark"
                                    size="sm"
                                    onClick={() => {
                                      modelOpen1();
                                      setConUuid(i.consultantUuid);
                                      setConName(i.consultantName);
                                      setConImgUrl(i.imgUrl);
                                    }}
                                  >
                                    <AiOutlineStar size={15} />
                                    {i.consultantRating}
                                  </Badge>
                                </Col>
                              )}

                              <Col lg={7} style={{ marginLeft: "12px" }}>
                                <Row>
                                  <strong>{i.consultantName}</strong>
                                  <small>
                                    <MdOutlineLanguage />
                                    {i.knownLangugae}
                                  </small>
                                  <small className="skill_btn">
                                    <BiBadgeCheck />
                                    {i.skills}
                                  </small>
                                  <small>
                                    <GrUserExpert />
                                    {i.expYear}
                                    {t("USR.HOM.CL.TEXT1")}
                                  </small>
                                  <Row></Row>
                                </Row>
                              </Col>
                              <Col lg={3}>
                                <Row className="d-flex g-1 call_btn">
                                  {details.selectedHead == "TXT.CAL.LO" ? (
                                    <OverlayTrigger
                                      key="top"
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-top`}>
                                          {t("TXT.STA.CALL.LO")}
                                        </Tooltip>
                                      }
                                    >
                                      {/* <Button variant="warning" onClick={handlePsumbit1}> */}
                                      {/* <Button
                                        className="mt-2 all_btn"
                                        size="sm"
                                        disabled={
                                          i.profstatus === 1 ? false : true
                                        }
                                        onClick={async () => {
                                          if (
                                            window.sessionStorage.getItem(
                                              "token"
                                            ) == null
                                          ) {
                                            window.sessionStorage.setItem(
                                              "rerender",
                                              location.pathname
                                            );
                                            navigate("/login");
                                          } else {
                                            GetConsultantCallStatus(
                                              i.consultantUuid
                                            );
                                            setConsulName(i.consultantName);
                                            // openModal();
                                            window.sessionStorage.setItem(
                                              "consultantId",
                                              i.consultantUuid
                                            );
                                          }
                                        }}
                                      >
                                        {/* <FiPhoneCall size={25} /> */}
                                      {/* {t("USR.HOM.CL.ACTION1")}
                                      </Button> */}
                                      <Button
                                        className="mt-2 all_btn"
                                        size="sm"
                                        disabled={
                                          i.profstatus === 1 ? false : true
                                        }
                                        onClick={() => {
                                          checkLoginAndGetConsultantCallStatus(
                                            i.consultantUuid,
                                            i.consultantName
                                          );
                                        }}
                                      >
                                        {t("USR.HOM.CL.ACTION1")}
                                      </Button>
                                    </OverlayTrigger>
                                  ) : details.selectedHead == "USR.BOOK.BTN" ? (
                                    <OverlayTrigger
                                      key="top"
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-top`}>
                                          {t("TXT.DO.SCH.LO")}
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        // variant="outline-warning"
                                        className="mt-2 all_btn"
                                        size="sm"
                                        onClick={() => {
                                          // CheckConsultant(i.consultantUuid);
                                          // if (
                                          //   window.sessionStorage.getItem(
                                          //     "token"
                                          //   ) == null
                                          // ) {
                                          //   window.sessionStorage.setItem(
                                          //     "rerender",
                                          //     location.pathname
                                          //   );
                                          //   navigate("/login");
                                          // } else {
                                          getConsutantServices(
                                            i.consultantUuid
                                          );
                                          // }
                                        }}
                                      >
                                        {/* <AiFillSchedule size={25} /> */}
                                        {t("USR.HOM.CL.ACTION2")}
                                      </Button>
                                    </OverlayTrigger>
                                  ) : null}
                                </Row>
                              </Col>
                            </Card.Header>
                            {i.profstatus === 1 ? (
                              <Card.Footer
                                className="text-center all_border"
                                style={{ color: "green", height: 45 }}
                              >
                                <strong>{t("USR.HOM.PR.ONL")}</strong>
                                &nbsp;
                                <Spinner
                                  animation="grow"
                                  variant="success"
                                  size="sm"
                                />
                                {/* <GoProject color="RED" size={20} /> */}
                              </Card.Footer>
                            ) : null}
                            {i.profstatus === 2 ? (
                              <Card.Footer
                                className="text-center all_border"
                                style={{ color: "red", height: 45 }}
                              >
                                <strong>{t("USR.HOM.PR.OFF")}</strong>
                                &nbsp;
                                <GoProject color="RED" size={20} />
                              </Card.Footer>
                            ) : null}
                            {i.profstatus === 3 ? (
                              <Card.Footer
                                className="text-center all_border"
                                style={{ color: "green", height: 45 }}
                              >
                                <strong>{t("USR.HOM.PR.ONCA")}</strong>
                                &nbsp;
                                <Spinner
                                  animation="grow"
                                  variant="success"
                                  size="sm"
                                />
                              </Card.Footer>
                            ) : null}
                          </Card>
                        </Col>
                      ) : null}
                    </>
                  ))}
                </>
              ) : (
                <center>
                  <h5>No Consultant Found</h5>
                </center>
              )}
            </Row>
          </Container>

          <Stacks spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleClosess}
            >
              <MuiAlert
                variant="filled"
                onClose={handleClosess}
                severity={messageType}
                sx={{
                  width: "100%",
                }}
              >
                {message}
              </MuiAlert>
            </Snackbar>
          </Stacks>
        </div>
      ) : (
        <center>
          <Spinner animation="border" />
        </center>
      )}
    </div>
  );
};

export default SocialConsultant;
