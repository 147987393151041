import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal, Toast } from "react-bootstrap";
import { vasthuUrl } from "../../component/Api";
import Stacks from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";
import validator from "validator";

function UserWalletOffer() {
  const { t } = useTranslation();
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const Navigate = useNavigate();
  const [model, setModal] = useState(false);
  const [offerName, setOfferName] = useState();
  const [offerPrice, setOfferPrice] = useState();
  const [messageType, setMessageType] = useState("error");
  const [savewalletoffer, setSaveWalletOffer] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState("");
  const [alloffer, setAllOffer] = useState([]);
  const [saveData, setSaveData] = useState([]);
  const [walletOffername, setWalletOfferName] = useState([]);
  const [actualPrice, setActualPrice] = useState([]);
  const [extraAmount, setExtraAmount] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState(false);

  const CreateModalOpen = (i) => {
    console.log("i", i);
    setModal(true);
    setSaveData(i);
  };

  const CreateModalClose = () => {
    setModal(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  useEffect(() => {
    try {
      vasthuUrl
        .get(`/getallwalletoffers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setAllOffer(res.data);
          setStatusUpdate(false);
          console.log("setAllOffer", res.data);
        });
    } catch (e) {
      console.log("setAllOffer", e);
      setAllOffer([]);
    }
  }, [statusUpdate]);

  const handleAddWallet = async () => {
    try {
      await vasthuUrl
        .post(
          `savewalletoffer`,
          {
            walletOffername: walletOffername,
            actualPrice: actualPrice,
            extraAmount: extraAmount,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setSaveWalletOffer(res.data);
          alert("Wallet Offer created Successfully..!");
          setMessageType("success");
          CreateModalClose();
        });
    } catch (err) {
      console.log("offer buy in home screen", err);
      alert(err);
    }
  };

  const handleUpdateProduct = () => {
    const walletOfferId = saveData.id; // Extract the ID from saveData

    vasthuUrl
      .put(
        `/updatewalletofferwithid/${walletOfferId}`,
        {
          walletOffername: saveData.walletOffername,
          actualPrice: saveData.actualPrice,
          extraAmount: saveData.extraAmount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((response) => {
        console.log("Update Successful:", response.data);
        alert("Update Offer Successfully..!");
        setMessageType("success");
        setTimeout(() => {
          CreateModalClose();
        }, 1000);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error updating wallet offer:", error);
      });
  };

  const handleUpdateStatus = async (id, status) => {
    console.log("id", id);
    console.log("status", status);
    try {
      const res = await vasthuUrl.post(
        `/updateWalletOfferStatus`,
        {
          id: id,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("res", res.data);
      alert("Update Successfully!!!");
      setMessageType("success");
      setStatusUpdate(true);
    } catch (error) {
      alert("Failed to Update");
    }
  };

  const columns = [
    { field: "id", headerName: "S.NO", width: 70 },
    { field: "walletOffername", headerName: "OFFER NAME", width: 150 },
    { field: "actualPrice", headerName: "PRICE", width: 100 },
    { field: "extraAmount", headerName: "EXTRA", width: 100 },
    { field: "status", headerName: "STATUS", width: 100 },
    {
      field: "actions",
      headerName: "ACTION",
      type: "actions",

      renderCell: (i) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Button
              className="all_btn"
              onClick={() => {
                CreateModalOpen(i.row);
              }}
              disabled={i.row.status === "INACTIVE"}
            >
              Update <MdEdit size={20} />
            </Button>

            {i.row.status === "INACTIVE" ? (
              <Button
                className="mx-1 all_btn"
                onClick={() => handleUpdateStatus(i.row.id, "ACTIVE")}
              >
                Activate
              </Button>
            ) : (
              <Button
                className="mx-1 all_btn"
                onClick={() => handleUpdateStatus(i.row.id, "INACTIVE")}
              >
                Deactivate
              </Button>
            )}
          </div>
        </>
      ),
      width: 250,
    },
  ];

  return (
    <div>
      <div className="product_btn">
        <Button
          className="my-2 all_btn"
          style={{ float: "right" }}
          onClick={CreateModalOpen}
        >
          ADD WALLET
        </Button>
      </div>

      <Modal
        show={model}
        onHide={CreateModalClose}
        size="lg"
        centered
        fullscreen={true}
      >
        <Card className="p-2">
          <Card.Header className="all_background">
            <center>
              <b>ADD WALLET</b>
            </center>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="walletOffername">
                <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                  Offer Name
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form_feild"
                  value={saveData ? saveData.walletOffername : walletOffername}
                  placeholder="Enter Offer Name"
                  // onChange={(e) => setWalletOfferName(e.target.value)}
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, walletOffername: e.target.value },
                      setWalletOfferName(e.target.value)
                    )
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="actualPrice">
                <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                  Offer Price
                </Form.Label>
                <Form.Control
                  type="number"
                  className="form_feild"
                  value={saveData ? saveData.actualPrice : actualPrice}
                  placeholder="Enter Offer Price"
                  // onChange={(e) => setOfferPrice(e.target.value)}
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, actualPrice: e.target.value },
                      setActualPrice(e.target.value)
                    )
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="extraAmount">
                <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                  Extra Amount
                </Form.Label>
                <Form.Control
                  type="number"
                  className="form_feild"
                  value={saveData ? saveData.extraAmount : extraAmount}
                  placeholder="Enter Offer Price"
                  // onChange={(e) => setOfferPrice(e.target.value)}
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, extraAmount: e.target.value },
                      setExtraAmount(e.target.value)
                    )
                  }
                />
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button className="all_btn" onClick={CreateModalClose}>
            Close
          </Button>
          {saveData && saveData.id ? (
            <Button className="all_btn" onClick={() => handleUpdateProduct()}>
              Update
            </Button>
          ) : (
            <Button className="all_btn" onClick={handleAddWallet}>
              Add Wallet
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {alloffer.length > 0 ? (
        <>
          <center className="datagrid">
            <Grid container className="deskTable">
              <DataGrid
                className="all_border - text-center me-3 data_grid"
                rows={alloffer}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                getRowId={(row) => row.id}
              />
            </Grid>
          </center>
          <div className="mobileCard">
            {alloffer.map((i, j) => (
              <Card
                className="mobileCard all_border"
                key={j}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                }}
              >
                <Card.Body>
                  <Card.Subtitle className="mb-2">
                    <b>S.NO:</b> {i.id}
                  </Card.Subtitle>

                  <Card.Subtitle className="mb-2">
                    <b>OFFER NAME:</b> {i.walletOffername}
                  </Card.Subtitle>

                  <Card.Subtitle className="mb-2">
                    <b>PRICE:</b> {i.actualPrice}
                  </Card.Subtitle>

                  <Card.Subtitle className="mb-2">
                    <b>STATUS:</b> {i.status}
                  </Card.Subtitle>

                  <Card.Subtitle className="mb-2">
                    <b>EXTRA AMOUNT:</b> {i.extraAmount}
                  </Card.Subtitle>
                </Card.Body>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    className="all_btn"
                    onClick={() => {
                      CreateModalOpen(i);
                    }}
                    disabled={i.status === "INACTIVE"}
                  >
                    Update <MdEdit size={20} />
                  </Button>
                  {i.status === "INACTIVE" ? (
                    <Button
                      className="mx-1 all_btn"
                      onClick={() => handleUpdateStatus(i.id, "ACTIVE")}
                    >
                      Activate
                    </Button>
                  ) : (
                    <Button
                      className="mx-1 all_btn"
                      onClick={() => handleUpdateStatus(i.id, "INACTIVE")}
                    >
                      Deactivate
                    </Button>
                  )}
                </div>
              </Card>
            ))}
          </div>
        </>
      ) : (
        <center>
          <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
        </center>
      )}

      <Stacks spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <MuiAlert
            variant="filled"
            onClose={handleCloseSnack}
            severity={messageType}
            sx={{
              width: "100%",
            }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stacks>
    </div>
  );
}

export default UserWalletOffer;
