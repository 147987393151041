import React, { useState, useEffect, UseAuth } from "react";
import { Navigate, NavLink, useNavigate, useLocation } from "react-router-dom";
import "../style.css";
// import { videoServerUrl } from "../../component/Api";
// import { vasthuUrl } from "../../component/Api";
import { BiSolidPhoneCall } from "react-icons/bi";

import { Button } from "react-bootstrap";
import { timeout } from "workbox-core/_private";
import Offcanvas from "react-bootstrap/Offcanvas";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MdOutlineScreenShare, MdOutlineStopScreenShare } from "react-icons/md";
import { videoServerUrl } from "../../component/Api";

const AudioCallReceiver = () => {
  const [booked_slot_id, setBooked_slot_id] = useState(""); //18
  const [meeting_code, setMeeting_code] = useState("66508");
  const token = window.sessionStorage.getItem("token");
  // const { state } = useLocation();
  // console.log("booked_slot_id" + String(state.id.slotId));

  // useEffect(() => {
  //   try {
  //     setBooked_slot_id(state.id.slotId);
  //     console.log("Token" + token);
  //     vasthuUrl
  //       .get(`getmeetingcode/${state.id.slotId}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then((res) => {
  //         console.log(res.data);
  //         setMeeting_code(res.data);
  //       });
  //   } catch (e) {
  //     console.log("SERVER ERROR");
  //   }
  // }, []);

  // const webSocket = new WebSocket("ws://192.168.1.34:3001");
  const webSocket = new WebSocket(videoServerUrl);
  const navigate = useNavigate();
  let displayMediaStream;

  webSocket.onmessage = (event) => {
    try {
      handleSignallingData(JSON.parse(event.data));
    } catch (error) {
      alert("Please ensure the remote peer connection");
    }
    // handleSignallingData(JSON.parse(event.data));
  };

  function handleSignallingData(data) {
    switch (data.type) {
      case "offer":
        try {
          peerConn.setRemoteDescription(data.offer);
          createAndSendAnswer();
          break;
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
      // peerConn.setRemoteDescription(data.offer);
      // createAndSendAnswer();
      // break;
      case "candidate":
        try {
          peerConn.addIceCandidate(data.candidate);
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
      // peerConn.addIceCandidate(data.candidate);
    }
  }

  function createAndSendAnswer() {
    peerConn.createAnswer(
      (answer) => {
        peerConn.setLocalDescription(answer);
        sendData({
          type: "send_answer",
          answer: answer,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function sendData(data) {
    data.username = meeting_code;
    webSocket.send(JSON.stringify(data));
  }

  let localStream;
  let peerConn;
  let username;
  let senders = [];

  let dataChannel;

  function joinCall() {
    username = meeting_code;

    document.getElementById("audio-call-div").style.display = "inline";

    var getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia;
    getUserMedia.call(
      navigator,
      {
        video: false,
        // video: {
        //   frameRate: 24,
        //   width: {
        //     min: 480,
        //     ideal: 720,
        //     max: 1280,
        //   },
        //   aspectRatio: 1.33333,
        //   // facingMode:'user'
        // },
        audio: true,
      },
      (stream) => {
        let configuration = {
          iceServers: [
            {
              urls: "stun:stun.aawsoft.com:3478",
            },
            {
              urls: "turn:turn.aawsoft.com:3478",
              username: "guest",
              credential: "somepassword",
            },

            {
              urls: "stun:stun.aawsoft.com:5349",
            },
            {
              urls: "turn:turn.aawsoft.com:5349",
              username: "guest",
              credential: "somepassword",
            },
            {
              urls: [
                "stun:stun.l.google.com:19302",
                "stun:stun1.l.google.com:19302",
                "stun:stun2.l.google.com:19302",
              ],
            },

            {
              urls: "stun:openrelay.metered.ca:80",
            },
            {
              urls: "turn:openrelay.metered.ca:80",
              username: "openrelayproject",
              credential: "openrelayproject",
            },
            {
              urls: "turn:openrelay.metered.ca:443",
              username: "openrelayproject",
              credential: "openrelayproject",
            },
            {
              urls: "turn:openrelay.metered.ca:443?transport=tcp",
              username: "openrelayproject",
              credential: "openrelayproject",
            },
          ],
          optional: [
            {
              RtpDataChannels: true,
            },
          ],
        };

        peerConn = new RTCPeerConnection(configuration);

        try {
          console.log("datachannel state", dataChannel);
          if (dataChannel === undefined) {
            alert(
              "trying connection if not connected in 10 sec - please hangup and tryagain"
            );
          } else if (dataChannel.readyState === "open") {
            console.log("connection is open");
            alert("connection open");
          } else {
            alert(
              " catch = Consultant not yet connected. Please hangup and try again later"
            );
          }
        } catch (error) {
          setTimeout(() => {
            console.log("catch - connected");
          }, 3000);
        }

        peerConn.addEventListener("datachannel", (event) => {
          dataChannel = event.channel;
          // dataChannel.send("test")
          console.log("dataChannel", dataChannel);
        });

        localStream = stream;

        //         var canvas = document.getElementById("canvas");
        //         window.onload = function(canvas) {

        //           var ctx = canvas.getContext("2d");
        //           var img = document.getElementById("img");
        //           ctx.drawImage(img, 10, 10);
        //         };

        // // Get the stream
        // localStream = canvas.captureStream(25); // 25 FPS
        // // localStream.getTracks().forEach((track) => peerConn.addTrack(track, stream));

        localStream
          .getTracks()
          .forEach((track) =>
            senders.push(peerConn.addTrack(track, localStream))
          );

        document.getElementById("local-audio").srcObject = localStream;

        peerConn.ontrack = (event) => {
          const video = document.getElementById("remote-audio");
          video.srcObject = event.streams[0];
        };

        peerConn.onicecandidate = (e) => {
          if (e.candidate == null) return;

          sendData({
            type: "send_candidate",
            candidate: e.candidate,
          });
        };

        sendData({
          type: "join_call",
        });

        // new chat ======================================================================

        dataChannel = peerConn.createDataChannel("channel1", {
          reliable: true,
        });

        peerConn.addEventListener("datachannel", (event) => {
          dataChannel = event.channel;
        });

        dataChannel.onerror = function(error) {
          console.log("Ooops...error:", error);
        };

        dataChannel.onclose = function() {
          console.log("data channel is closed");
          alert("data call channel is closed - call hangup");
          localStream.getTracks().forEach(function(track) {
            track.stop();
          });
          navigate("/");
        };

        // ================================================================================
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function hangUp(e) {
    peerConn.close();
    localStream.getAudioTracks()[0].enabled = false;
    localStream.getTracks().forEach(function(track) {
      track.stop();
    });
    navigate("/");
  }

  return (
    <div>
      <div>
        <Button id="join_call" variant="warning" onClick={joinCall}>
          Join Audio Call
        </Button>
      </div>

      <div id="audio-call-div">
        <video muted id="local-audio" autoPlay></video>
        <video id="remote-audio" autoPlay></video>
        <div className="call-action-div">
          <div className="call_proceed">
            <BiSolidPhoneCall size={50} />
          </div>
          <div style={{ marginTop: "50px" }}>
            <Button variant="warning" onClick={() => hangUp()}>
              HangUp
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioCallReceiver;
