import React, { useState, useEffect, UseAuth } from "react";
import { Navigate, NavLink, useNavigate, useLocation } from "react-router-dom";
import "../style.css";
// import { videoServerUrl } from "../../component/Api";
// import { vasthuUrl } from "../../component/Api";
import { Alert, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MdOutlineScreenShare, MdOutlineStopScreenShare } from "react-icons/md";
// import { useTranslation } from "react-i18next";
import { BiSolidPhoneCall } from "react-icons/bi";
import { videoServerUrl } from "../../component/Api";

const AudioCallSender = () => {
  // const { t } = useTranslation();
  const [booked_slot_id, setBooked_slot_id] = useState("");
  const [meeting_code, setMeeting_code] = useState("66508");
  const token = window.sessionStorage.getItem("token");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // useEffect(() => {
  //   try {
  //     setBooked_slot_id(state.i.slotId);
  //     console.log("Token" + token);
  //     vasthuUrl
  //       .get(`getmeetingcode/${state.i.slotId}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then((res) => {
  //         console.log(res.data);
  //         setMeeting_code(res.data);
  //       });
  //   } catch (e) {
  //     console.log("SERVER ERROR");
  //   }
  // }, []);

  const navigate = useNavigate();

  // const webSocket = new WebSocket("ws://192.168.1.34:3001")
  const webSocket = new WebSocket(videoServerUrl);
  if (window.sessionStorage) {
    console.log("session storage supported");
  } else {
    console.log("session storage not supported");
  }
  let displayMediaStream;

  webSocket.onmessage = (event) => {
    try {
      handleSignallingData(JSON.parse(event.data));
    } catch (error) {
      alert("Please ensure the remote peer connection");
    }
    // handleSignallingData(JSON.parse(event.data));
  };

  function handleSignallingData(data) {
    switch (data.type) {
      case "answer":
        try {
          peerConn.setRemoteDescription(data.answer);
          break;
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
      // peerConn.setRemoteDescription(data.answer);
      // break;
      case "candidate":
        try {
          peerConn.addIceCandidate(data.candidate);
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }

      // peerConn.addIceCandidate(data.candidate);
    }
  }

  let username;
  function sendUsername() {
    // username = document.getElementById("username-input").value;
    username = meeting_code;
    console.log("usernameinput" + username);
    sendData({
      type: "store_user",
    });
  }

  function sendData(data) {
    data.username = username;
    if (webSocket.readyState === 1) {
      webSocket.send(JSON.stringify(data));
    } else {
      console.log("socket not ready");
    }
  }

  let localStream;
  let peerConn;
  let senders = [];
  // new chat ========================================================================
  let dataChannel;
  let connectedUser;

  //=================================================================================

  function startCall() {
    try {
      sendUsername();
    } catch (error) {
      console.log("not able to send username");
    }
    document.getElementById("audio-call-div").style.display = "inline";

    var getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia;
    getUserMedia.call(
      navigator,
      {
        video: false,
        // video: {
        //   frameRate: 24,
        //   width: {
        //     min: 480,
        //     ideal: 720,
        //     max: 1280,
        //   },
        //   aspectRatio: 1.33333,
        //   // facingMode:'user'
        // },
        audio: true,
      },
      (stream) => {
        let configuration = {
          iceServers: [
            {
              urls: "stun:stun.aawsoft.com:3478",
            },
            {
              urls: "turn:turn.aawsoft.com:3478",
              username: "guest",
              credential: "somepassword",
            },

            {
              urls: "stun:stun.aawsoft.com:5349",
            },
            {
              urls: "turn:turn.aawsoft.com:5349",
              username: "guest",
              credential: "somepassword",
            },

            {
              urls: [
                "stun:stun.l.google.com:19302",
                "stun:stun1.l.google.com:19302",
                "stun:stun2.l.google.com:19302",
              ],
            },

            {
              urls: "stun:openrelay.metered.ca:80",
            },
            {
              urls: "turn:openrelay.metered.ca:80",
              username: "openrelayproject",
              credential: "openrelayproject",
            },
            {
              urls: "turn:openrelay.metered.ca:443",
              username: "openrelayproject",
              credential: "openrelayproject",
            },
            {
              urls: "turn:openrelay.metered.ca:443?transport=tcp",
              username: "openrelayproject",
              credential: "openrelayproject",
            },
          ],
          optional: [
            {
              RtpDataChannels: true,
            },
          ],
        };

        peerConn = new RTCPeerConnection(configuration);

        // new chat ======================================================================

        dataChannel = peerConn.createDataChannel("channel1", {
          reliable: true,
        });

        try {
          if (dataChannel.readyState !== "open") {
            alert("Wait for client");
          }
        } catch (error) {
          console.log("connection is open");
        }

        peerConn.addEventListener("datachannel", (event) => {
          dataChannel = event.channel;
          // dataChannel.send("test")
          console.log("dataChannel", dataChannel);
          // if(dataChannel.readyState !== "open"){
          //   alert("Wait for client");
          // }
        });

        dataChannel.onopen = function(success) {
          console.log("Both are connected", success);
          console.log(
            "target.readystate+channel",
            success.target.readyState,
            "+",
            success.target.label
          );
          if (
            success.target.readyState == "open" &&
            success.target.label == "channel1"
          ) {
            console.log("calling  api");

            //    vasthuUrl
            //                         .get(
            //                           // `/subscription/useVasthuOffer/${i.id}`,
            //                           `/subscription/useVasthuOffer/${i.subscriptionId}`,
            //                           {
            //                             headers: {
            //                               Authorization: `Bearer ${token}`,
            //                             },
            //                           }
            //                         )
            //                         .then((rs) =>
            //                           console.log(
            //                             "offer subscription redeem count used",
            //                             rs.data.message
            //                           )
            //                         )
            //                         .catch((er) =>
            //                           console.log(
            //                             "offer subscription redeem count not reduced",
            //                             er
            //                           )
            //                         );
          }
        };

        dataChannel.onerror = function(error) {
          console.log("Ooops...error:", error);
        };

        dataChannel.onclose = function() {
          console.log("data channel is closed");
          alert("data call channel is closed - call hangup");
          localStream.getTracks().forEach(function(track) {
            track.stop();
          });
          window.location.reload();
        };

        // ================================================================================

        //
        localStream = stream;

        localStream
          .getTracks()
          .forEach((track) =>
            senders.push(peerConn.addTrack(track, localStream))
          );

        document.getElementById("local-audio").srcObject = localStream;

        peerConn.ontrack = (event) => {
          const video = document.getElementById("remote-audio");
          video.srcObject = event.streams[0];
        };

        peerConn.onicecandidate = (e) => {
          if (e.candidate == null) return;
          sendData({
            type: "store_candidate",
            candidate: e.candidate,
          });
        };

        createAndSendOffer();
        // Set timeout to automatically hang up after 3 minutes (180,000 ms)
        setTimeout(() => {
          hangUp();
        }, 180000); // 3 minutes
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function createAndSendOffer() {
    peerConn.createOffer(
      (offer) => {
        sendData({
          type: "store_offer",
          offer: offer,
        });

        peerConn.setLocalDescription(offer);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function hangUp(e) {
    // e.preventDefault();
    peerConn.close();
    localStream.getVideoTracks()[0].enabled = false;
    localStream.getAudioTracks()[0].enabled = false;
    localStream.getTracks().forEach(function(track) {
      track.stop();
    });
    // test - after completeted video call
    // console.log("Subscription id on hangup", state.i.subscriptionId);
    // vasthuUrl
    //   .post(
    //     `subscription/consulationCallStatus`,
    //     {
    //       offerId: state.i.subscriptionId,
    //       callFinished: true,
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   )
    //   .then((rs) =>
    //     console.log("offer subscription -call hangup", rs.data.message)
    //   )
    //   .catch((er) =>
    //     console.log("offer subscription  count not reduced - on hangup", er)
    //   );

    // test - after completeted video call - end
    navigate("/");
  }

  return (
    <div>
      <div>
        <Button id="start_call" variant="warning" onClick={startCall}>
          Start Audio Call
        </Button>
      </div>
      <div id="audio-call-div" style={{ display: "none" }}>
        <video muted id="local-audio" autoPlay></video>
        <video id="remote-audio" autoPlay></video>
        <div className="call-action-div">
          <div className="call_proceed">
            <BiSolidPhoneCall size={50} />
          </div>
          <div style={{ marginTop: "50px" }}>
            <Button variant="warning" onClick={() => hangUp()}>
              HangUp
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioCallSender;
