import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stacks from "@mui/material/Stack";
import { format } from "date-fns";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Spinner,
  Table,
} from "react-bootstrap";
import Toast from "react-bootstrap/Modal";
import { registerPlugin } from "react-filepond";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import validator from "validator";
import { vasthuUrl } from "../component/Api";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const AppointTableDesk = () => {
  const location = useLocation();
  console.log("location", location);
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const consultantId = window.sessionStorage.getItem("consultantId");
  const token = window.sessionStorage.getItem("token");
  const role = window.sessionStorage.getItem("role");
  const user = window.sessionStorage.getItem("userId");
  const crmSelectedUser = window.sessionStorage.getItem("crmSelectedUser");
  const userId = token && role == "ROLE_CRM" ? crmSelectedUser : user;
  const navigate = useNavigate();
  const [userMeetingList, setUserMeetingList] = useState([]);
  const [files, setFiles] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const [fileName, setFileName] = useState("");
  const [fileView, setFileView] = useState();
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [modal, setModal] = useState(false);
  const [tempSlotId, setTempSlotId] = useState({
    bookStatus: "",
    callDate: "",
    callDuration: null,
    callTime: "",
    name: "",
    serviceStatus: "",
    slotId: null,
  });
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [callStatus, setCallStatus] = useState("Failed");

  const [showModal, setShowModal] = useState(false);
  const [imageData, setImageData] = useState(null);

  const [callStatusPro, setCallStatusPro] = useState();
  const [waitingTime, setWaitingTime] = useState();
  const [totalDurationSpoken, setTotalDurationSpoken] = useState(0);
  const [consulName, setConsulName] = useState();
  const [detail, setDetail] = useState();
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [p, setP] = useState();
  const [count, setCount] = useState();
  const [slotList, setSlotList] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [reasonList, setReasonList] = useState([]);
  const [cancelReason, setCancelReason] = useState("");
  console.log("cancelReason", cancelReason);
  //toast aleart
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageType, setMessageType] = useState("error");

  const closeTosastBtn = () => {
    setToast(false);
  };

  const ReviewModal = (i) => {
    setShowReviewModal(true);
    setP(i);
    console.log("p", p);
    setCount(i.cancelledCount);
  };

  const handleClosess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const closeModalBtn = () => {
    setModal(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  //Warning Alert For Last Cancel
  const [warningModal, setWarningModal] = useState(false);

  const warningModalOpen = () => {
    if (!validator.isEmpty(cancelReason)) {
      setWarningModal(true);
    } else {
      alert("Please Select Reason");
      setMessageType("error");
    }
  };
  const warningModalClose = () => {
    setWarningModal(false);
    setCancelReason("");
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleSubmit = async (e, obj) => {
    // console.log(id, e.target.files[0]);
    console.log(obj.slotId, e.target.files[0]);
    const userObj = userMeetingList.find((i) => i.slotId === obj.slotId);
    e.preventDefault();
    if (e.target.files[0]) {
      const res = await vasthuUrl.post(
        // `uploadFile/${id}`,
        `uploadFile/${obj.slotId}`,

        { file: e.target.files[0] },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            // contentType: "application/json",
          },
        }
      );
      // setSelectedFile(e.target.files[0]);
      // setFilePath(URL.createObjectURL(e.target.files[0]));
      const filePath = URL.createObjectURL(e.target.files[0]);
      console.log("after Api", res.data, filePath);
      // setFileName(res.data.fileName);
      // setDownloadUrl(res.data.fileDownloadUri);
      userObj.selectedFile = e.target.files[0];
      userObj.filePath = filePath;
      userObj.fileName = res.data.fileName;
      setUserMeetingList((prev) => [...prev]);
      setFileUpload(true);
    }
  };

  const handleClose = () => {
    setViewModal(false);
    setTempSlotId({
      bookStatus: "",
      callDate: "",
      callDuration: null,
      callTime: "",
      name: "",
      serviceStatus: "",
      slotId: null,
    });
  };

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`getuserbookinglistwithmode/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setUserMeetingList(res.data);
          setLoading(false);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }

    try {
      vasthuUrl
        .get(`cancel/getUserCancelReasons`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setReasonList(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }
  }, [slotList, fileUpload]);

  const fetchCall = () => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`getuserbookinglistwithmode/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setUserMeetingList(res.data);
          setLoading(false);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }
  };

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const updateTime = () => {
      setTime(new Date());
      fetchCall();
    };

    const calculateNextRefreshTime = () => {
      const now = new Date();
      let nextRefreshTime = null;

      userMeetingList.forEach((call) => {
        const callDateTime = new Date(`${call.callDate}T${call.callTime}`);
        if (
          callDateTime > now &&
          (!nextRefreshTime || callDateTime < nextRefreshTime)
        ) {
          nextRefreshTime = callDateTime;
        }
        console.log("callDateTime", callDateTime);
      });

      if (nextRefreshTime) {
        const msUntilNextRefresh = nextRefreshTime - now;
        return msUntilNextRefresh;
      }
      return null;
    };

    const setNextInterval = () => {
      const msUntilNextRefresh = calculateNextRefreshTime();
      if (msUntilNextRefresh !== null) {
        const timeout = setTimeout(() => {
          updateTime();
          setNextInterval(); // Set the next interval after updating
        }, msUntilNextRefresh);

        return timeout;
      }
      return null;
    };

    const initialTimeout = setNextInterval();

    return () => clearTimeout(initialTimeout); // Cleanup on unmount
  }, [userMeetingList]);

  const checkSlotJoinStatus = (slotDate, slotTime) => {
    let startDate = slotDate;
    let startTime = slotTime;

    let currentTimeStamp = new Date().getTime();
    let currentHours = new Date().getHours();

    // console.log("SLOT  TIME" + timeStamp2 +"CURRENT TIME" +currentTimeStamp  + "SLOT HOURS" + ">>" + currentHours);

    let timeStamp2 = new Date(startDate).getTime();
    // console.log("SLOT DATE "+timeStamp2);

    var target = new Date("1970-01-01 " + startTime);
    let timeSlot = target.getTime();

    let slotStartTimeStamp = timeStamp2 + timeSlot;

    let slotEndTimeStamp = slotStartTimeStamp + 30 * 60 * 1000;

    let getSlotHours =
      new Date(slotEndTimeStamp).getHours() +
      " MIN   :" +
      new Date(slotEndTimeStamp).getMinutes() +
      new Date(slotEndTimeStamp).getDate();

    console.log("SLOT DATE AND TIME" + getSlotHours);

    if (
      slotStartTimeStamp >= currentTimeStamp &&
      currentTimeStamp <= slotEndTimeStamp
    ) {
      return true;
    } else {
      return false;
    }
  };

  const reader = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
  };

  const handleViewDetailsclose = () => {
    setShowReviewModal(false);
  };

  const cancelbookedslot = () => {
    if (!validator.isEmpty(cancelReason)) {
      vasthuUrl
        .post(
          `cancelbookedslot`,
          {
            slot: p.slotId,
            subscription: p.subscriptionId,
            booking: p.bookingid,
            reason: cancelReason,
            role: role,
            cancelledByUuid: token && role == "ROLE_CRM" ? user : userId,
            lng: lang, // newly added
          },

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("slot cancel response", res.data);
          if (res.data) {
            alert(res.data.message);
            setMessageType("success");
          }
          setSlotList(true);
          handleViewDetailsclose();
          warningModalClose();
          navigate("/consultantbook");
        })
        .catch((e) => {
          console.log("Slot cancel_catch_block" + e);
        });
    } else {
      alert("Please Select Reason");
      setMessageType("error");
    }
  };

  console.log("p", p);
  console.log("setP", setP);

  const downloadFile = async (uri) => {
    try {
      vasthuUrl
        .get(`${uri}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          // Create a data URL from the blob
          const dataUrl = URL.createObjectURL(response.data);
          setImageData(dataUrl);
          setShowModal(true);
        });
    } catch (error) {
      console.log("URI NOT FOUND");
    }
  };

  const handleCloses = () => {
    setShowModal(false);
    // Clean up the data URL when the modal is closed
    URL.revokeObjectURL(imageData);
  };

  const GetConsultantCallStatus = async (id) => {
    setDetail(id);
    setConsulName(id.name);
    try {
      vasthuUrl
        .post(`vasthu/user/getprostatusavalaibility`, {
          pro_uuid: id.uuid,
          user_uuid: userId,
        })
        .then((res) => {
          setCallStatusPro(res.data);
          console.log("res.data . call consultan tstatus", res.data);
          if (res.data.proCurrentCallStatus == "in-progress") {
            setToast(true);
            // alert("Consultant Already on a call !!!");
            setWaitingTime(Math.round(res.data.waitingTime / 60));
            setTotalDurationSpoken(res.data.totalDurationSpoken);
            console.log(res.data);
          } else {
            console.log(
              "res.data.totalDurationSpoken" + res.data.totalDurationSpoken
            );
            setTotalDurationSpoken(res.data.totalDurationSpoken);
            if (id.serviceStatus == "ACTIVE") {
              console.log("initial  join" + id);
              openModelCall(id, id.callDuration * 60);
            } else {
              try {
                vasthuUrl
                  .get(`vasthu/getUserSpokenDuration/${id.subscriptionId}`, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then((res) => {
                    console.log("second  join" + res.data);
                    openModelCall(
                      id,
                      res.data[0].proposedCallDuration - res.data[0].duration
                    );
                  })
                  .catch((err) =>
                    console.log("error form getting status for booking call")
                  );
              } catch (error) {}
            }
          }
        });
    } catch (err) {
      console.log("SERVER ERROR", err);
      openModelCall();
    }
  };
  const openModelCall = (id, duration) => {
    if (id.consultationMode == "Video")
      navigate("/receivecall", { state: { id } });
    else if (id.consultationMode == "Audio") {
      try {
        vasthuUrl
          .post(`vasthu/user/clicktocall`, {
            from: id.uuid, //"+919790580304",
            to: userId, //"07092758264",
            // duration: (id.callDuration - totalDurationSpoken) * 60, //30min
            duration: duration,
            subscriptionId: id.subscriptionId,
          })
          .then((res) => {
            console.log("postresponse", res);
            console.log("postresponsedata", res.data);

            if (res.data == 200) {
              setCallStatus(t("USR.CAL.PROCED.BTN"));
              // console.log("offerId - on 200 [already register offer] status",i.id)
              console.log(
                "offerId - on 200 [already register offer] status",
                id.subscriptionId
              );

              vasthuUrl
                .get(
                  // `/subscription/useVasthuOffer/${i.id}`,
                  `/subscription/useVasthuOffer/${id.subscriptionId}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((rs) =>
                  console.log(
                    "offer subscription redeem count used",
                    rs.data.message
                  )
                )
                .catch((er) =>
                  console.log("offer subscription redeem count not reduced", er)
                );

              navigate("/audiocallstatus", {
                state: { callStatus: "USR.CAL.PROCED.BTN" },
              });
            } else {
              setCallStatus(t("USR.CAL.FAILD.BTN"));

              navigate("/audiocallstatus", {
                state: { callStatus: "USR.CAL.FAILD.BTN" },
              });
            }
          })
          .catch(console.log("Click to call Failed"));
      } catch (err) {
        console.log("SERVER ERROR", err);
      }
    }
  };

  const AstroUserDetails = (id) => {
    let value = id.slotId;
    navigate("/astroUserDetails", {
      state: { value },
    });
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">
        {t("USR.BOK.SCH.BIR.DET.OVE.LAY")}
      </Popover.Header>
    </Popover>
  );

  const options = [{ status: "Unable to network " }, { status: "Not connect" }];

  const ViewUserDetails = (slotId) => {
    try {
      vasthuUrl
        .get(`/getAstroUserDetails/${slotId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setUserDetails(res.data);
          setModal(true);
        });
    } catch (e) {
      console.log("SERVER ERROR");
    }
  };

  const formatDate = (date) => {
    try {
      return format(date, "d MMM yyyy"); // Example format string
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return ""; // or return a default value
    }
  };

  const formatTime = (timeString) => {
    try {
      const time = new Date(`2000-01-01T${timeString}`);
      return format(time, "h:mm a");
    } catch (error) {
      console.error("Error formatting time:", error.message);
      return ""; // or return a default value
    }
  };

  return (
    <Container>
      {!loading ? (
        userMeetingList.length > 0 ? (
          <Table
            striped
            bordered
            hover
            responsive
            className="deskTable border border-warning"
          >
            <thead>
              <tr className="text-center">
                <th>{t("TXT.CON.LOCA")}</th>
                <th>{t("USR.SCH.BO.SER")}</th>
                <th>{t("TXT.CAL.DAT.UP")}</th>
                <th>{t("PR.USRMTNG.TBL.CLMN.CT.CMM")}</th>
                <th>{t("PR.USRMTNG.TBL.CLMN.DR")}</th>
                <th>{t("PR.USRMTNG.TBL.CLMN.UP")}</th>
                <th>{t("PR.USRMTNG.TBL.CLMN.CNT")}</th>
                <th>{t("USR.BK.SCH.ACT.BTN")}</th>
              </tr>
            </thead>
            <tbody>
              {userMeetingList.map((i, j) => (
                <tr key={j}>
                  <td style={{ verticalAlign: "middle" }}>{i.name}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    {i.service == 3
                      ? "Vasthu Consultation"
                      : i.service == 4
                      ? "Astrology Consultation"
                      : i.service == 5
                      ? "Building Consultation"
                      : i.consultationMode}
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    {format(new Date(i.callDate), "d MMM yyyy")}
                  </td>
                  <td style={{ verticalAlign: "middle" }}>{i.callTime}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    {i.callDuration + " " + "mins"}
                  </td>
                  <td
                    className="text-center d-flex"
                    style={{ flexDirection: "column", verticalAlign: "middle" }}
                  >
                    {t("USR.BOK.SCH.UPL.TXT")}
                    {i.downloadUri ? (
                      <>
                        <div className="p-1 all_border">
                          <input
                            className="form-control all_border form_feild"
                            type="file"
                            id="formFile"
                            onChange={(e) => handleSubmit(e, i)}
                          />
                        </div>

                        <Button
                          size="sm"
                          style={{
                            borderRadius: 50,
                          }}
                          onClick={() => downloadFile(i.downloadUri)}
                          className="mt-2 all_btn"
                        >
                          {t("USR.CON.SCH.VIE.FIL")}
                        </Button>
                      </>
                    ) : (
                      <>
                        <div className="p-1">
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                            onChange={(e) => handleSubmit(e, i)}
                          />
                        </div>
                      </>
                    )}
                    {i.service == 4 ? (
                      <>
                        <center>{t("USR.CON.SCH.OR")}</center>
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="top"
                          overlay={popover}
                        >
                          <Button
                            className="mt-2 all_btn1"
                            onClick={() => {
                              AstroUserDetails(i);
                            }}
                            size="sm"
                            style={{ width: "100%", borderRadius: 50 }}
                          >
                            <Spinner
                              animation="grow"
                              style={{
                                animationDuration: "1.5s",
                                color: "transparent",
                                width: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              className="animated_text"
                            >
                              <span
                                style={{ color: "black", fontSize: "16px" }}
                              >
                                {t("USR.CON.SCH.BIR.DAT.TIM.BTN")}
                              </span>
                            </Spinner>
                          </Button>
                        </OverlayTrigger>
                        {/* {userDetails.length > 0 ? ( */}
                        <Button
                          className="mt-2 all_btn"
                          style={{ width: "100%", borderRadius: 50 }}
                          onClick={() => ViewUserDetails(i.slotId)}
                        >
                          {t("BTN.VIEW.DET.UP")}
                        </Button>
                        {/* ) : null} */}
                      </>
                    ) : null}
                  </td>

                  <td
                    className="text-center"
                    style={{ verticalAlign: "middle" }}
                  >
                    {Date.now() >= new Date(i.callDate + " " + i.callTime) ? (
                      <Button
                        variant="outline-warning"
                        style={{ borderRadius: 50 }}
                        onClick={async () => GetConsultantCallStatus(i)}
                        className="mt-1"
                      >
                        {t("USR.SCH.BO.JOIN")}
                      </Button>
                    ) : (
                      <h6>{t("USR.SCH.BO.NOT.STAT")}</h6>
                    )}
                  </td>
                  <td
                    className="text-center"
                    style={{ verticalAlign: "middle" }}
                  >
                    {Date.now() >=
                    new Date(i.callDate + " " + i.callTime) - 3600000 ? (
                      <h6>NA</h6>
                    ) : (
                      <Button
                        style={{ borderRadius: 50 }}
                        className="all_btn"
                        onClick={() => ReviewModal(i)}
                        disabled={
                          Date.now() >=
                          new Date(i.callDate + " " + i.callTime) - 3600000 // 1 hour in milliseconds
                            ? true
                            : false
                        }
                      >
                        {t("USR.SCH.BO.CAN")}
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <>
            <center className="mt-2">
              <h3>{t("USR.NM.FOUND")}</h3>
            </center>
            <center>
              <Button
                className="all_btn"
                onClick={() => navigate("/consultantbook")}
              >
                {t("USR.BC.BTN")}
              </Button>
            </center>
          </>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}

      <div className="mobileCard justify-content-center form_feild">
        {userMeetingList.map((i, j) => (
          <Card key={j} style={{ width: "100%" }} className="mt-2 form_feild">
            <Card.Body>
              <Card.Title>
                {t("TXT.CON.LOCA")}: {i.name}
              </Card.Title>
              <Card.Title>
                {t("USR.SCH.BO.SER")}:{" "}
                {i.service == 3
                  ? "Vasthu Consultation"
                  : i.service == 4
                  ? "Astrology Consultation"
                  : i.service == 5
                  ? "Building Consultation"
                  : i.consultationMode}
              </Card.Title>
              <Card.Title>
                {t("TXT.CAL.DAT.UP")}:{" "}
                {format(new Date(i.callDate), "d MMM yyyy")}
              </Card.Title>
              <Card.Title>
                {t("PR.USRMTNG.TBL.CLMN.CT.CMM")}: {i.callTime}
              </Card.Title>
              <Card.Title>
                {t("PR.USRMTNG.TBL.CLMN.DR")}: {i.callDuration + " " + "mins"}
              </Card.Title>
              {t("USR.BOK.SCH.UPL.TXT")}
              {i.downloadUri ? (
                <>
                  <div className="p-1">
                    <input
                      className="form-control"
                      type="file"
                      id="formFile"
                      onChange={(e) => handleSubmit(e, i)}
                    />
                  </div>

                  <Button
                    size="sm"
                    style={{
                      borderRadius: 50,
                      width: "100%",
                    }}
                    onClick={() => downloadFile(i.downloadUri)}
                    className="mt-2 all_btn"
                  >
                    {t("USR.CON.SCH.VIE.FIL")}
                  </Button>
                </>
              ) : (
                <div className="p-1">
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={(e) => handleSubmit(e, i)}
                  />
                </div>
              )}
              {i.service == 4 ? (
                <>
                  <center>{t("USR.CON.SCH.OR")}</center>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={popover}
                  >
                    <Button
                      className="mt-2 all_btn1"
                      onClick={() => {
                        AstroUserDetails(i);
                      }}
                      size="sm"
                      style={{ width: "100%", borderRadius: 50 }}
                    >
                      <Spinner
                        animation="grow"
                        style={{
                          animationDuration: "1.5s",
                          color: "transparent",
                          width: "auto",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="animated_text"
                      >
                        <span style={{ color: "black", fontSize: "16px" }}>
                          {t("USR.CON.SCH.BIR.DAT.TIM.BTN")}
                        </span>
                      </Spinner>
                    </Button>
                  </OverlayTrigger>
                  {/* {userDetails.length > 0 ? ( */}
                  <Button
                    className="mt-2 all_btn"
                    style={{ width: "100%", borderRadius: 50 }}
                    onClick={() => ViewUserDetails(i.slotId)}
                  >
                    {t("BTN.VIEW.DET.UP")}
                  </Button>
                  {/* ) : null} */}
                </>
              ) : null}
            </Card.Body>
            <Card.Footer className="d-flex text-center justify-content-end all_border">
              {Date.now() >= new Date(i.callDate + " " + i.callTime) ? (
                <>
                  <Button
                    style={{ borderRadius: 50 }}
                    onClick={async () => GetConsultantCallStatus(i)}
                    className="mt-1 all_btn"
                  >
                    {t("USR.SCH.BO.JOIN")}
                  </Button>
                </>
              ) : (
                <h6>{t("USR.SCH.BO.NOT.STAT")}</h6>
              )}
              {Date.now() >=
              new Date(i.callDate + " " + i.callTime) - 3600000 ? null : (
                <Button
                  style={{ borderRadius: 50 }}
                  className="all_btn"
                  onClick={() => ReviewModal(i)}
                  disabled={
                    Date.now() >=
                    new Date(i.callDate + " " + i.callTime) - 3600000 // 1 hour in milliseconds
                      ? true
                      : false
                  }
                >
                  {t("USR.SCH.BO.CAN")}
                </Button>
              )}
            </Card.Footer>
          </Card>
        ))}
      </div>

      <Toast onHide={() => setToast(false)} show={toast} className="all_border">
        <Toast.Header closeButton className="all_background">
          {t("TXT.CON.BSY.LO")}!!!
        </Toast.Header>
        <Toast.Body>
          {t("TXT.HELO.LO")}! <b>{consulName}</b> {t("TXT.CON.BSY.ANT.PLS.LO")}{" "}
          {waitingTime} {t("USR.BOK.SCH.CON.BSY.MIN")}
        </Toast.Body>
        <Toast.Footer>
          <Button className="all_btn" onClick={closeTosastBtn}>
            {t("TXT.CLO.LO")}
          </Button>
        </Toast.Footer>
      </Toast>

      <Modal show={showModal} fullscreen={true} centered onHide={handleCloses}>
        <Modal.Header className="all_background" closeButton>
          <Modal.Title>{t("USR.BOK.SCH.DOC.VIW.TL")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display the image in the modal */}
          {imageData && (
            <img
              src={imageData}
              alt="File"
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="all_border">
          <Button
            className="all_btn"
            variant="secondary"
            onClick={handleCloses}
          >
            {t("TXT.CLO.LO")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal onHide={() => setModal(false)} show={modal} className="all_border">
        <Modal.Header closeButton className="all_background">
          {t("USR.CON.SCH.USR.DET.TL")}
        </Modal.Header>
        <Modal.Body>
          {userDetails.length > 0 ? (
            userDetails.map((i, j) => (
              <Card
                key={j}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                }}
                className="all_border"
              >
                <Card.Body>
                  <Card.Title>
                    {t("TXT.NM.LO")}: {i.userName}
                  </Card.Title>
                  <Card.Title>
                    {t("USR.CON.SCH.USR.BIR.DAT")}:{" "}
                    {formatDate(new Date(i.birthDate))}
                  </Card.Title>
                  <Card.Title>
                    {t("USR.CON.SCH.USR.BIR.TIM")}: {formatTime(i.birthTime)}
                  </Card.Title>
                  <Card.Title>
                    {t("USR.CON.SCH.USR.BIR.PLA")}: {i.userBirthstate}
                  </Card.Title>
                </Card.Body>
              </Card>
            ))
          ) : (
            <center>
              <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
            </center>
          )}
        </Modal.Body>
        <Modal.Footer className="all_border">
          <Button className="all_btn" onClick={closeModalBtn}>
            {t("TXT.CLO.LO")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={viewModal}
        onHide={handleClose}
        size="lg"
        centered
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{tempSlotId.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="justify-content-center align-center text-center ">
          <h4>
            <u>
              {t("USR.BOK.SCH.FIL")} <br /> {fileName}
            </u>
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="all_btn"
            style={{ borderRadius: 50 }}
            onClick={handleClose}
          >
            {t("TXT.CLO.UP")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showReviewModal}
        onHide={handleViewDetailsclose}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="v_modal"
      >
        <Modal.Header closeButton className="all_background">
          <Modal.Title>{t("USR.BOK.SCH.CAN.RES.BOK.TL")}</Modal.Title>
        </Modal.Header>
        {loading ? (
          <center>
            <Spinner animation="border" variant="warning" />
          </center>
        ) : (
          <Modal.Body>
            <div style={{ color: "red", fontWeight: "bold" }} className="mb-3">
              {/* You are allowed {3 - count} more time only for cancellation!!! */}
              {/* You are allowed to cancel {3 - count} more time!!! */}
              {3 - count} {t("USR.BOK.SCH.CAN.RES.TXT")} !!!
            </div>

            <Form>
              <Form.Select
                // style={{ width: "70%" }}
                className="form_feild"
                onChange={(e) => {
                  setCancelReason(e.target.value);
                }}
              >
                <option value="">{t("USR.BOK.SCH.CAN.RES.SEL")}</option>
                {reasonList.map((i, j) => (
                  <option key={j} value={i.user_Reasons}>
                    {lang === "ta"
                      ? i.user_Reasons_ta
                      : lang === "hi"
                      ? i.user_Reasons_hi
                      : i.user_Reasons}
                  </option>
                ))}
              </Form.Select>
            </Form>
          </Modal.Body>
        )}
        <Modal.Footer className="consultantbtn all_border d-flex justify-content-evenly">
          <Button className="all_btn" onClick={handleViewDetailsclose}>
            {t("TXT.CLO.LO")}
          </Button>
          <Button
            className="all_btn"
            onClick={(i) => {
              if (count == 2) {
                warningModalOpen();
                handleViewDetailsclose();
              } else {
                cancelbookedslot();
              }
            }}
          >
            {t("USR.BOK.SCH.CAN.RESCH")}
          </Button>
          {/* <Button className="all_btn" onClick={handleViewDetailsclose}>
            Cancel
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={warningModal}
        onHide={warningModalClose}
        centered
        className="v_modal"
      >
        <Modal.Header closeButton className="all_background">
          <Modal.Title style={{ color: "red" }}>
            {t("USR.BOK.SCH.LAT.CAN.TL")}!!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="justify-content-center align-center text-center ">
          <div style={{ color: "red", fontWeight: "bold" }} className="mb-3">
            {t("USR.BOK.SCH.LST.CAN.TXT")}!!!
          </div>
        </Modal.Body>
        <Modal.Footer className="consultantbtn all_border d-flex justify-content-evenly">
          <Button className="all_btn" onClick={() => warningModalClose()}>
            {t("TXT.CLO.UP")}
          </Button>
          <Button className="all_btn" onClick={() => cancelbookedslot()}>
            {t("USR.HOM.MD.KBTN")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Stacks spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClosess}
        >
          <MuiAlert
            variant="filled"
            onClose={handleClosess}
            severity={messageType}
            sx={{
              width: "100%",
            }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stacks>
    </Container>
  );
};

export default AppointTableDesk;
