import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
// import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from 'react-bootstrap/Table';
import { vasthuUrl } from '../component/Api';
import { UseAuth } from '../context/Auth';


const ConsultantCallInfo = () => {
  const { token } = UseAuth();
  const uuid = window.sessionStorage.getItem("userId");
  const [professionalCallDetails, setProfessionalCallDetails] = useState([]);
  useEffect(() => {

    try {

      vasthuUrl
        .get(`vasthu/user/getAudioCallProHistoryForAdminCallInfo`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },

        }).then(res => {
          res.data.map((i) => i.open = false);
          console.log(res.data); setProfessionalCallDetails(res.data);
        });
    } catch (error) {
      console.log("SERVER ERROR");
    }

  }, [])


  return (
    <Container>

      {/* <center><h3>Consultant Meeting Details</h3></center> */}

      {/* <TableContainer component={Paper} >

        <Table sx={{ minWidth: 650 }} >
          <TableHead>
            <TableRow>
              <TableCell align="right">User</TableCell>
              <TableCell align="right">Consultant</TableCell>
              <TableCell align="right">OfferName</TableCell>
              <TableCell align="right">Duration</TableCell>
              <TableCell align="right">Status</TableCell>
              

            </TableRow>
          </TableHead>
          {professionalCallDetails.map((row, k) => (
            <TableBody key={k}>
              <TableRow

                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="right">{row.callFrom}</TableCell>
                <TableCell align="right">{row.callTo}</TableCell>
                <TableCell align="right">{row.offerName}</TableCell>
                <TableCell align="right">{row.proposedCallDuration}</TableCell>
                <TableCell align="right">{row.status}</TableCell>
                

              </TableRow>

            </TableBody>
          ))}
        </Table>
      </TableContainer> */}


      <Table striped bordered hover responsive className="deskTable all_border text-center mt-2 mb-2">        
      <thead>
        <tr>
          <th>User</th>
          <th>Consultant</th>
          <th>OfferName</th>
          <th>Duration</th>
          <th>Status</th>
        </tr>
      </thead>
        <tbody>
          {professionalCallDetails.map((x, k) => (
            <tr key={k}>
              <td> {x.callFrom}</td>
              <td> {x.callTo}</td>
              <td> {x.offerName}</td>
              <td> {x.proposedCallDuration}</td>
              <td> {x.status}</td>
            </tr>
          ))}

        </tbody>
      </Table>
    </Container>
  )
}

export default ConsultantCallInfo