import React, { Suspense, useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import ServiceList from "../component/serviceList";
import { useTranslation } from "react-i18next";

function CustomerSpecialPricing() {
  const { t } = useTranslation();
  const token = window.sessionStorage.getItem("token");
  const [freeOffers, setFreeOffers] = useState([]);

  useEffect(() => {
    try {
      vasthuUrl
        .get("/offer/getAllSpecialOffer", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFreeOffers(res.data);
          console.log("res.data", res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setFreeOffers([]);
    }
  }, []);
  return (
    <Suspense fallback={<Spinner />}>
      <Container
        fluid
        style={{
          padding: 20,
          background: "linear-gradient(90deg, #ffbb33 0%, #ffbb33 100%)",
        }}
      >
        <div className=" my-1 g-1">
          <center>
            <h2>{t("USR.PR.OTTAB.TL1")}</h2>
          </center>
          {freeOffers.length > 0 ? (
            <Suspense fallback={<Spinner />}>
              <ServiceList data={freeOffers} />
            </Suspense>
          ) : (
            <center>
              <h3>{t("USR.PR.OTTAB.HTXT1")}</h3>
            </center>
          )}
        </div>
      </Container>
    </Suspense>
  );
}

export default CustomerSpecialPricing;
