import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Container,
  Form,
  Button,
  Modal,
  InputGroup,
  Image,
  // Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { FcAddImage, FcEditImage } from "react-icons/fc";
import { Divider } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";

const ProfileForm = () => {
  const { t } = useTranslation();
  const { onLogout } = UseAuth();
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setEmail] = useState("abc@xyz.com");
  const [userName, setUserName] = useState("userName");
  const [isImage, setIsImage] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [uploadedImage, setUploadedImage] = useState();
  const [open, setOpen] = React.useState(false);

  const [countryCode, setCountryCode] = useState("0");

  const uploadedImg = React.useRef(null);

  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loadApi, setLoadApi] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedUserName, setUpdatedUserName] = useState("");

  console.log("updatedUserName", updatedUserName);
  console.log("setUpdatedUserName", setUpdatedUserName);
  const phone_with_code = countryCode.concat(phoneNumber);

  useEffect(() => {
    try {
      const res = vasthuUrl
        .get(`getuserprofiledetail/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUserName(res.data.name);
          setphoneNumber(res.data.phoneNumber.slice(1));
          setEmail(res.data.email);
          setIsImage(res.data.imageUploaded);
          setImgUrl(res.data.profileUrl);
        });
    } catch (error) {
      console.log("ERROE" + error);
    }
  }, [show]);

  const alert = (msg) => {
    setMsg(msg);
    setShowMsg(true);
    setTimeout(() => {
      setShowMsg(false);
      setMsg("");
    }, 3000);
  };

  const handleImageUpload = async (e) => {
    // const reader = new FileReader();
    // const { current } = uploadedImg;
    // current.file = file;
    // reader.onload = e => {
    //   current.src = e.target.result;
    // };
    // reader.readAsDataURL(file);

    const res = await vasthuUrl.post(
      `image/uploadImage`,

      { file: uploadedImage, userId: userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          // contentType: "application/json",
        },
      }
    );
    console.log("response for Image Upload Api", res.data);
    setLoadApi(!loadApi);
    setShow(false);
    alert(res.data);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleUpdate = () => {
    try {
      const updatedPhoneNumberWithCode = countryCode.concat(phoneNumber);

      vasthuUrl
        .post(
          `vasthu/user/updateProfileByUser`,
          {
            uuid: userId,
            userName: userName,
            phone_no: updatedPhoneNumberWithCode, // Use the concatenated phone number with country code
            email: email,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          setEditMode(false);
          setOpen(true);
        });
    } catch (error) {
      alert("CHECK INTERNET CONNECTION");
    }
  };

  const handleEdit = () => {
    setEditMode(true);
    setUpdatedPhoneNumber(phoneNumber);
    setUpdatedEmail(email);
    setUpdatedUserName(userName);
  };

  console.log("setUserName", setUserName);
  console.log("updatedUserName", updatedUserName);

  const handleClosess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Container>
      {/* <Alert show={showMsg} className="mb-3" variant="info">
        {msg}
      </Alert> */}
      <Row
        className="mt-3"
        style={{ backgroundColor: "#eee", border: "1px solid #ffb33e" }}
      >
        <div className="col-lg-4 mt-3">
          <div className="card mb-4" style={{ border: "1px solid #ffb33e" }}>
            <div
              className="card-body text-center"
              style={{ backgroundColor: "beige" }}
            >
              <Row className="justify-content-center">
                {isImage ? (
                  <Image
                    fluid
                    thumbnail
                    src={imgUrl}
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: 100, height: 100, objectFit: "cover" }}
                    // style={{
                    //   width: "150px",
                    //   objectFit: "cover",
                    //   height: "108px",
                    // }}
                    // style={{ borderRadius: '50%', height: '50px', width: '50px', margin: '-9px', objectFit: 'cover' }}
                  />
                ) : (
                  // <h5>{t("CM.SU.IN1.NOIMG")}</h5>
                  <Image
                    fluid
                    thumbnail
                    roundedCircle
                    style={{ width: 100, height: 100, objectFit: "cover" }}
                    src={require("../images/noimage.jpg")}
                    // style={{ borderRadius: '50%', height: '50px', width: '50px', margin: '-9px', objectFit: 'cover' }}
                  />
                )}
                <div className="d-flex justify-content-center m-2">
                  <button
                    type="button"
                    className="btn all_btn ms-1"
                    style={{
                      borderRadius: 50,
                    }}
                    onClick={handleShow}
                  >
                    {isImage ? t("CM.SU.IN1.CHIMG") : t("CM.SU.IN1.ADIMG")}
                  </button>
                </div>
                {/* <Button className='m-3' onClick={handleShow} variant='outline-warning'   style={{
                    borderRadius: 50,
                    color: "black",
                    fontWeight: "bold",
                  }}>Change Profile</Button> */}
                {/* <FcAddImage
                  size={32}
                /> */}
              </Row>
              <h5 className="my-3">{userName}</h5>

              <div className="d-flex justify-content-center mb-2">
                <button
                  type="button"
                  className="btn all_btn ms-1"
                  style={{
                    borderRadius: 50,
                  }}
                  onClick={onLogout}
                >
                  {t("PR.AC.PFLTAB.BTN.SOUT")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-8 mt-3">
          <div className="card mb-4" style={{ border: "1px solid #ffb33e" }}>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">{t("CM.SU.IN1.NM")}</p>
                </div>
                <div className="col-sm-9">
                  <Form.Control
                    className="form_feild"
                    value={userName}
                    disabled={editMode ? false : true}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  {/* <p className="text-muted mb-0">{userName}</p> */}
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">{t("CM.SU.IN3.NM")}</p>
                </div>
                <div className="col-sm-9">
                  <Form.Control
                    className="form_feild"
                    value={email}
                    disabled={editMode ? false : true}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* <p className="text-muted mb-0">{email}</p> */}
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">{t("CM.SI.IN1.NM")}</p>
                </div>
                <div className="col-sm-9">
                  <div className="forget_form">
                    <InputGroup className="mb-3">
                      <Form.Select
                        aria-label="Country Code"
                        style={{ maxWidth: "10%" }}
                        value={countryCode}
                        disabled={editMode ? false : true}
                        className="form_feild  countrywidth"
                        onChange={(e) => {
                          setCountryCode(e.target.value);
                        }}
                      >
                        <option defaultChecked key={1} value="0">
                          IND
                        </option>
                        <option key={2} value="+65">
                          SGP
                        </option>
                        <option key={3} value="+60">
                          MYS
                        </option>
                        <option key={4} value="+1">
                          USA
                        </option>
                      </Form.Select>
                      <Form.Control
                        type="phone"
                        // style={{maxWidth:'60%'}}
                        value={phoneNumber}
                        className="form_feild select_feild"
                        placeholder={t("USR.HOM.HD.P")}
                        disabled={editMode ? false : true}
                        onChange={(e) => setphoneNumber(e.target.value)}
                        autoComplete="off"
                      />
                    </InputGroup>
                  </div>

                  {/* <Form.Control
                    className="form_feild"
                    value={phoneNumber}
                    disabled={editMode ? false : true}
                    onChange={(e) => setphoneNumber(e.target.value)}
                  /> */}
                  {/* <p className="text-muted mb-0">{phoneNumber}</p> */}
                </div>
              </div>
            </div>
          </div>
          {editMode ? (
            <div className="d-flex justify-content-end mt-2">
              <Button className="all_btn me-2" onClick={handleCancelEdit}>
                {t("TXT.BTN.CAN.LO")}
              </Button>
              <Button className="all_btn" onClick={handleUpdate}>
                {t("USR.HOM.BTN.SAV")}
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-end mt-2">
              <Button className="all_btn" onClick={handleEdit}>
                {t("USR.VSH.FLTAB.BTN.EDT")}
              </Button>
            </div>
          )}
        </div>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="all_background" closeButton>
          <Modal.Title style={{ marginLeft: "auto" }}>
            {t("USR.ACC.PRO.IMG.TL")}
          </Modal.Title>
          <Divider></Divider>
        </Modal.Header>
        <Modal.Body>
          {isImage ? (
            <Image fluid={true} rounded={true} ref={uploadedImg} />
          ) : null}

          <Form.Group controlId="formFile" className="mb-1">
            <Form.Label>{t("USR.ACC.PRO.IMG.UPLD")}</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setUploadedImage(e.target.files[0])}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-evenly">
          <Button className="all_btn" onClick={handleClose}>
            {t("TXT.CLO.UP")}
          </Button>
          <Button className="all_btn" onClick={handleImageUpload}>
            {t("USR.HOM.BTN.SAV")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          autoHideDuration={2000}
          onClose={handleClosess}
        >
          <Alert
            variant="filled"
            // onClose={handleClosess}
            severity="success"
            sx={{
              width: "100%",
            }}
          >
            Profile Updated Successfully
          </Alert>
        </Snackbar>
      </Stack>
    </Container>
  );
};

export default ProfileForm;
