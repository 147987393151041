import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomerOneTimePricing from "./CustomerOneTimeService";
import CustomerPeriodicPricing from "./CustomerPeriodicService";
import CustomerSpecialPricing from "./CustomerSpecialPricing";

const customerServiceTab = () => {
  const {t} = useTranslation();
  return (
    <Tabs
      defaultActiveKey="onetime"
      id="customerServiceTab"
      className="mb-3"
      justify
    >
      <Tab eventKey="onetime" title={t("USR.PR.OTTAB")}>
        <CustomerOneTimePricing />
      </Tab>
      {/* <Tab eventKey="periodic" title={t("USR.PR.PRTAB")}>
        <CustomerPeriodicPricing />
      </Tab> */}
      <Tab eventKey="special" title={t("USR.HOM.SPCL.OFF")}>
        <CustomerSpecialPricing />
      </Tab>
    </Tabs>
  );
};

export default customerServiceTab;
