import React, { useState, useEffect, UseAuth } from "react";
import { Navigate, NavLink, useNavigate, useLocation } from "react-router-dom";
import "../style.css";
// import { videoServerUrl } from "../../component/Api";
// import { vasthuUrl } from "../../component/Api";
import { Alert, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MdOutlineScreenShare, MdOutlineStopScreenShare } from "react-icons/md";
import { videoServerUrl } from "../../component/Api";
// import { useTranslation } from "react-i18next";

const ChatSender = () => {
  // const {t} = useTranslation();
  const [booked_slot_id, setBooked_slot_id] = useState("");
  const [meeting_code, setMeeting_code] = useState("66507");
  const token = window.sessionStorage.getItem("token");
  const navigate = useNavigate();
  // const webSocket = new WebSocket("ws://192.168.1.34:3001");
  const webSocket = new WebSocket(videoServerUrl);
  let peerConn;
  let dataChannel;
  let username;

  // useEffect(() => {
  //   try {
  //     setBooked_slot_id(state.i.slotId);
  //     console.log("Token" + token);
  //     vasthuUrl
  //       .get(`getmeetingcode/${state.i.slotId}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then((res) => {
  //         console.log(res.data);
  //         setMeeting_code(res.data);
  //       });
  //   } catch (e) {
  //     console.log("SERVER ERROR");
  //   }
  // }, []);

  if (window.sessionStorage) {
    console.log("session storage supported");
  } else {
    console.log("session storage not supported");
  }
  let displayMediaStream;

  webSocket.onmessage = (event) => {
    try {
      handleSignallingData(JSON.parse(event.data));
    } catch (error) {
      alert("Please ensure the remote peer connection");
    }
  };

  function handleSignallingData(data) {
    switch (data.type) {
      case "answer":
        try {
          peerConn.setRemoteDescription(data.answer);
          break;
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
      case "candidate":
        try {
          peerConn.addIceCandidate(data.candidate);
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
    }
  }

  function sendUsername() {
    username = meeting_code;
    console.log("usernameinput" + username);
    sendData({
      type: "store_user",
    });
  }

  function sendData(data) {
    data.username = username;
    if (webSocket.readyState === 1) {
      webSocket.send(JSON.stringify(data));
    } else {
      console.log("socket not ready");
    }
  }

  function startCall() {
    try {
      sendUsername();
    } catch (error) {
      console.log("not able to send username");
    }
    document.getElementById("chat-div").style.display = "inline";

    const configuration = {
      iceServers: [
        {
          urls: "stun:stun.aawsoft.com:3478",
        },
        {
          urls: "turn:turn.aawsoft.com:3478",
          username: "guest",
          credential: "somepassword",
        },

        {
          urls: "stun:stun.aawsoft.com:5349",
        },
        {
          urls: "turn:turn.aawsoft.com:5349",
          username: "guest",
          credential: "somepassword",
        },

        {
          urls: [
            "stun:stun.l.google.com:19302",
            "stun:stun1.l.google.com:19302",
            "stun:stun2.l.google.com:19302",
          ],
        },

        {
          urls: "stun:openrelay.metered.ca:80",
        },
        {
          urls: "turn:openrelay.metered.ca:80",
          username: "openrelayproject",
          credential: "openrelayproject",
        },
        {
          urls: "turn:openrelay.metered.ca:443",
          username: "openrelayproject",
          credential: "openrelayproject",
        },
        {
          urls: "turn:openrelay.metered.ca:443?transport=tcp",
          username: "openrelayproject",
          credential: "openrelayproject",
        },
      ],
      optional: [
        {
          RtpDataChannels: true,
        },
      ],
    };

    peerConn = new RTCPeerConnection(configuration);

    dataChannel = peerConn.createDataChannel("channel1", {
      reliable: true,
    });

    try {
      if (dataChannel.readyState !== "open") {
        alert("Wait for client");
      }
    } catch (error) {
      console.log("connection is open");
    }

    peerConn.addEventListener("datachannel", (event) => {
      dataChannel = event.channel;
      console.log("dataChannel", dataChannel);
    });

    dataChannel.onopen = function(success) {
      console.log("Both are connected", success);
      console.log(
        "target.readystate+channel",
        success.target.readyState,
        "+",
        success.target.label
      );
      if (
        success.target.readyState == "open" &&
        success.target.label == "channel1"
      ) {
        console.log("calling  api");
      }
    };

    dataChannel.onerror = function(error) {
      console.log("Ooops...error:", error);
    };

    dataChannel.onmessage = function(event) {
      var chatArea = document.querySelector("#chatarea");
      var messageDiv = document.createElement("div");
      messageDiv.classList.add("message", "remote-message"); // Add a class for styling
      messageDiv.innerText = "Remote: " + event.data;
      chatArea.appendChild(messageDiv);
      chatArea.innerHTML += "<br />";
      console.log("event.data", event.data);
    };

    dataChannel.onclose = function() {
      console.log("data channel is closed");
      alert("data call channel is closed - call hangup");
      window.location.reload();
    };

    peerConn.ontrack = (event) => {
      const video = document.getElementById("remote-chat");
      video.srcObject = event.streams[0];
    };

    peerConn.onicecandidate = (e) => {
      if (e.candidate == null) return;
      sendData({
        type: "store_candidate",
        candidate: e.candidate,
      });
    };

    createAndSendOffer();
  }

  function createAndSendOffer() {
    peerConn.createOffer(
      (offer) => {
        sendData({
          type: "store_offer",
          offer: offer,
        });

        peerConn.setLocalDescription(offer);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function hangUp(e) {
    // e.preventDefault();
    if (dataChannel) {
      dataChannel.close();
    }
    navigate("/");
  }

  //when user clicks the "send message" button
  const sendMsg = () => {
    var msgInput = document.getElementById("msgInput");
    var chatArea = document.querySelector("#chatarea");
    console.log("messageInput", msgInput);
    console.log("chatArea", chatArea);

    var val = msgInput.value;
    console.log("message" + val);
    try {
      dataChannel.send(val);
      var messageDiv = document.createElement("div");
      messageDiv.classList.add("message", "my-message"); // Add a class for styling
      messageDiv.innerText = val + " : Me ";
      chatArea.appendChild(messageDiv);
      chatArea.innerHTML += "<br />";
      msgInput.value = "";
    } catch (error) {
      alert("Please ensure the peer remote connection ");
      msgInput.value = "";
    }
  };

  return (
    <div>
      <div>
        <Button id="start_call" variant="warning" onClick={startCall}>
          Start Chat
        </Button>
      </div>
      <div id="chat-div" style={{ display: "none" }}>
        <video muted id="local-chat" autoPlay></video>
        <video id="remote-chat" autoPlay></video>
        <div className="chat-action-div">
          <div style={{ display: "flex", gap: "1rem" }}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Hang Up</Tooltip>}
            >
              <div>
                <Button variant="warning" onClick={() => hangUp()}>
                  HangUp
                </Button>
              </div>
            </OverlayTrigger>
          </div>

          <div className="chatBox">
            <div id="chatarea"></div>
            <div class="main__message_container">
              <input
                type="text"
                id="msgInput"
                placeholder="Type message here.."
              />
              <button class="sendMsgBtn" id="sendMsgBtn" onClick={sendMsg}>
                <i class="fa fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatSender;
