import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdLocalPhone, MdEmail, MdHome, MdChat } from "react-icons/md";
import { FaYoutube } from "react-icons/fa";
import { SiInstagram, SiFacebook, SiTwitter } from "react-icons/si";
import { useTranslation } from "react-i18next";

const HomeFooterInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="footerRow">
      <section
        className="d-flex justify-content-center justify-content-lg-between p-4"
        style={{ borderBottom: "1px solid #ffb33e" }}
      >
        <div className="me-5 d-none d-lg-block">
          <span>
            <h4>{t("CM.FT.HD.TL")}</h4>
          </span>
        </div>
        <div>
          <a
            href="https://www.facebook.com/vasthuparam"
            target="blank"
            className="me-4 text-reset"
          >
            <SiFacebook style={{ color: "	#1877F2" }} size={30} />
          </a>
          <a
            href="https://www.twitter.com/"
            target="blank"
            className="me-4 text-reset"
          >
            <SiTwitter style={{ color: "28b6f9" }} size={30} />
          </a>
          <a
            href="https://www.instagram.com/vasthuparam/?hl=en"
            target="blank"
            className="me-4 text-reset"
          >
            <SiInstagram style={{ color: " #fd1d1d " }} size={30} />
          </a>
          <a
            href="https://www.youtube.com/"
            target="blank"
            className="me-4 text-reset"
          >
            <FaYoutube style={{ color: "red" }} size={30} />
          </a>
        </div>
      </section>

      <section className="">
        <Container className="text-center text-md-start mt-5">
          <Row className="mt-3">
            <Col md="3" lg="4" xl="3" className="mx-auto mb-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d408.6143593535558!2d80.13772024040156!3d13.00910709118182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52605e1b76549f%3A0xccf08047beed529!2sManju%20Foundations%20-%20Royal%20Villa%20and%20Metro%2C%20D1%2C%20Gerugambakkam%2C%20Bharathi%20Nagar%2C%20Chennai%2C%20Tamil%20Nadu%20600128!5e0!3m2!1sen!2sin!4v1715946345011!5m2!1sen!2sin"
                className="map_location"
                loading="eager"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>

            <Col md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                {t("CM.FT.COL2.TL")}
              </h6>
              <p>
                <a href="/disclaimer" className="text-reset">
                  {t("CM.FT.COL2.L1")}
                </a>
              </p>
              <p>
                <a href="/privacypolicy" className="text-reset">
                  {t("CM.FT.COL2.L2")}
                </a>
              </p>
              <p>
                <a href="/termcondition" className="text-reset">
                  {t("CM.FT.COL2.L3")}
                </a>
              </p>
              <p>
                <a href="/copyrights" className="text-reset">
                  {t("CM.FT.COL2.L5")}
                </a>
              </p>
            </Col>

            {/* <Col md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">{t("CM.FT.COL3.TL")}3</h6>
              <p>
                <a href="#!" className="text-reset">
                {t("CM.FT.COL3.L1")}
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                {t("CM.FT.COL3.L2")}
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                {t("CM.FT.COL3.L3")}
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                {t("CM.FT.COL3.L4")}
                </a>
              </p>
            </Col> */}

            {/* <Col md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">{t("CM.FT.COL4.TL")}2</h6>
              <p>
                <a href="#!" className="text-reset">
                {t("CM.FT.COL4.L1")}
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                {t("CM.FT.COL4.L2")}
                </a>
              </p>
              <p>
                <a href="#!"  className="text-reset">
                {t("CM.FT.COL4.L3")}
                </a>
              </p>
              <p>
                <a href="#!"  className="text-reset">
                {t("CM.FT.COL4.L4")}
                </a>
              </p>
            </Col> */}

            <Col md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                {t("CM.FT.COL5.TL")}
              </h6>
              <p>
                <MdHome icon="home" className="me-2" />
                {t("CM.FT.COL5.CNT1")}
              </p>
              <p>
                <MdEmail icon="envelope" className="me-2" />
                {t("CM.FT.COL5.CNT2")}
              </p>
              <p>
                <MdLocalPhone icon="phone" className="me-2" />{" "}
                {t("CM.FT.COL5.CNT3")}
              </p>
              <p>
                <MdChat icon="print" className="me-2" /> {t("CM.FT.COL5.CNT4")}
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        {t("CM.FT.CPY_RH")}
      </div>
    </div>
  );
};

export default HomeFooterInfo;
