import React, { useEffect, useState } from "react";
import { Card, Container, Image, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TalkNow from "./TalkNow";
import OurCallServices from "./OurCallServices";
import { useNavigate } from "react-router-dom";

function OurServicesConsultant() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [serviceType, setServiceType] = useState("TalkNow");
  const [pendingAction, setPendingAction] = useState(null);
  const token = window.sessionStorage.getItem("token");

  const executePendingAction = () => {
    const pendingAction1 = window.sessionStorage.getItem("pendingAction1");
    const type = window.sessionStorage.getItem("details");
    console.log("pendingAction1", pendingAction1);
    console.log("pendingtype", type);
    setPendingAction(pendingAction1);
    if (type == "Wallet" || type == "Duration") {
      setServiceType("TalkNow");
    } else if (type == "Booking") {
      setServiceType("TalkNow");
    }
  };

  // Call executePendingAction after login
  useEffect(() => {
    executePendingAction();
  }, []);

  return (
    <Container className=" my-3">
      <center>
        <h1>{t("CM.SER.TL")}</h1>
      </center>
      <Card
        className="text-center all_border"
        style={{
          cursor: "pointer",
          background:
            "linear-gradient(0deg, #d3ab5c 0%, rgba(227,227,227,1) 100%)",
        }}
      >
        <Card.Header className="all_border all_background" as="h3">
          {t("USR.TYPE.OF.CONSULT")}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <h2 style={{ fontFamily: "serif" }}>{t("USR.CAL.BOOK.EXPT")}</h2>
              <Row>
                <Col
                  xs={6}
                  sm={6}
                  style={{
                    backgroundColor: serviceType === "TalkNow" ? "#ffb33c" : "",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="img_hov p-2"
                    onClick={() => setServiceType("TalkNow")}
                  >
                    <Image
                      src="call_icon.jpg"
                      alt=""
                      width="65px"
                      height="65px"
                      roundedCircle
                      className="image-3d mb-2"
                    />
                    {t("USR.TALK.NOW")}
                  </div>
                </Col>

                <Col
                  xs={6}
                  sm={6}
                  style={{
                    backgroundColor: serviceType === "BookNow" ? "#ffb33c" : "",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="img_hov p-2"
                    onClick={() => setServiceType("BookNow")}
                  >
                    <Image
                      src="book1_ic.png"
                      alt=""
                      width="65px"
                      height="65px"
                      roundedCircle
                      className="image-3d mb-2"
                    />
                    {t("USR.BO.NOW")}
                  </div>
                </Col>
              </Row>
            </Col>

            <div className="our_service_mob all_background">
              {serviceType == "TalkNow" ? (
                <TalkNow />
              ) : serviceType == "BookNow" ? (
                <OurCallServices type="Booking" action={pendingAction} />
              ) : null}
            </div>
            <Col sm={12} md={6} lg={4}>
              <h2 style={{ fontFamily: "serif" }}>{t("USR.VAS.SLF.CHEK")}</h2>
              <Row>
                <Col
                  xs={6}
                  sm={6}
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="img_hov p-2"
                    onClick={() => {
                      if (token !== null) {
                        navigate("plotvasthu");
                      } else {
                        window.sessionStorage.setItem("rerender", "plotvasthu");
                        navigate("/login");
                      }
                    }}
                  >
                    <Image
                      src="plot.png"
                      alt=""
                      width="65px"
                      height="65px"
                      roundedCircle
                      className="image-3d mb-2"
                    />
                    {t("CM.SER.VFP")}
                  </div>
                </Col>

                <Col
                  xs={6}
                  sm={6}
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="img_hov p-2"
                    onClick={() => {
                      if (token !== null) {
                        navigate("homevasthu");
                      } else {
                        window.sessionStorage.setItem("rerender", "homevasthu");
                        navigate("/login");
                      }
                    }}
                  >
                    <Image
                      src="home.png"
                      alt=""
                      width="65px"
                      height="65px"
                      roundedCircle
                      className="image-3d mb-2"
                    />
                    {t("CM.SER.VFH")}
                  </div>
                </Col>
              </Row>
            </Col>
            <div className="our_service_tab  all_background">
              {serviceType == "TalkNow" ? (
                <TalkNow />
              ) : serviceType == "BookNow" ? (
                <OurCallServices type="Booking" action={pendingAction} />
              ) : null}
            </div>
            <Col sm={12} md={6} lg={4}>
              <h2 style={{ fontFamily: "serif" }}>{t("USR.NAV.PRO")}</h2>
              <Row>
                <Col
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="img_hov p-2"
                    onClick={() => navigate("product")}
                  >
                    <Image
                      src="ree.jpg"
                      alt=""
                      width="65px"
                      height="65px"
                      roundedCircle
                      className="image-3d mb-2"
                    />
                    {t("USR.HOME.PRODUCT")}
                  </div>
                </Col>
              </Row>
            </Col>
            <div className="our_service_desk  all_background">
              {serviceType == "TalkNow" ? (
                <TalkNow />
              ) : serviceType == "BookNow" ? (
                <OurCallServices type="Booking" action={pendingAction} />
              ) : null}
            </div>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default OurServicesConsultant;
