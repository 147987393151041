import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  FormControl,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import validator from "validator";
import { vasthuUrl } from "../component/Api";
// import DatePicker from "react-datepicker";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";

const AdminProfDetails = () => {
  // const { token } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");
  const { t } = useTranslation();
  const [profData, setProfData] = useState([]);
  console.log("profData", profData);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profUuid, setProfUuid] = useState("");
  const [profName, setProfName] = useState("");
  const [profEmail, setProfEmail] = useState("");
  const [profPhone, setProfPhone] = useState("");
  const [rateMin, setRateMin] = useState("");
  const [maximunRate, setMaximunRate] = useState("");
  const [level, setLevel] = useState([]);
  const [consultantLevel, setConsultantLevel] = useState("");
  const [skillList, setSkillList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [monthOfExp, setMonthOfExp] = useState(0);
  const [skillMaster, setSkillMaster] = useState([]);
  const [langMaster, setLangMaster] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [status, setStatus] = useState("");
  console.log("status", status);
  const [Comment, setComment] = useState("");

  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState([]);
  console.log("detail", detail);
  const [showHistory, setShowHistory] = useState(false);
  const [rateHistory, setRateHistory] = useState();
  console.log("rateHistory", rateHistory);
  const uuid = window.sessionStorage.getItem("userId");

  const handleClose1 = () => {
    setShowHistory(false);
  };

  const datas = [
    {
      id: 1,
      startTime: "2024-09-11",
      duration: 1,
      endTime: "2024-09-11",
    },
    {
      id: 2,
      startTime: "2024-09-11",
      duration: 12,
      endTime: "2024-09-11",
    },
    {
      id: 3,
      startTime: "2024-09-11",
      duration: 15,
      endTime: "2024-09-11",
    },
    {
      id: 4,
      startTime: "2024-09-11",
      duration: 20,
      endTime: "2024-09-11",
    },
    {
      id: 5,
      startTime: "2024-09-11",
      duration: 22,
      endTime: "2024-09-11",
    },
  ];

  useEffect(() => {}, []);

  //date picker
  const today = new Date();
  const [comboList, setComboList] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;

  const [endDate, setEndDate] = useState(new Date());
  console.log("endDate", endDate);

  const [reason, setReason] = useState("");
  const [getstatus, setGetStatus] = useState([]);
  console.log("getstatus", getstatus);
  const [getreason, setGetReason] = useState([]);
  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/data/getallprofessional`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProfData(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }

    try {
      vasthuUrl
        .get(`/vasthu/openapi/getskills`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((skill) => {
          setSkillMaster(skill.data);
        });
    } catch (e) {
      console.log("server error", e);
      setSkillMaster([]);
    }

    try {
      vasthuUrl
        .get(`consultant/getCategoryLevel`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((level) => {
          setLevel(level.data);
        });
    } catch (e) {
      console.log("server error", e);
      setLevel([]);
    }
    try {
      vasthuUrl
        .get(`openapi/lang/allCode`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((lang) => {
          setLangMaster(lang.data);
        });
    } catch (e) {}

    try {
      vasthuUrl
        .get(`info/accmaster`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("setGetStatus", res.data);
          setGetStatus(res.data);
        });
    } catch (e) {
      alert("SERVER ERROR");
    }
  }, []);

  const getAndEditDetails = (uuid) => {
    vasthuUrl
      .get(`professionalBriefDetails/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("PROF Details", res.data);
        setProfName(res.data.userName);
        setProfEmail(res.data.email);
        setMonthOfExp(String(res.data.monthOfExp));
        setProfPhone(res.data.phone_no);
        setRateMin(res.data.ratePerMin);
        setMaximunRate(res.data.maxRate);
        setConsultantLevel(String(res.data.levelId));
        setSkillList(res.data.skillList);
        setLangList(res.data.langList);
        setProfUuid(res.data.uuid);
      });
    try {
      vasthuUrl
        .get(`/getPreviousRateHistory/${uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setRateHistory(res.data);
          console.log("setRateHistory", res.data);
        });
    } catch (e) {
      console.log("Error fetching rate history", e);
    }
    setShowModal(true);
  };

  const handleOpen = () => {
    setShowHistory(true);
  };

  const handleCloses = () => {
    setShowHistory(false);
  };

  const updateProfessionalDetail = async (event) => {
    event.preventDefault();
    if (
      !validator.isEmpty(profName) &&
      !validator.isEmpty(profPhone) &&
      !validator.isEmpty(consultantLevel) &&
      skillList.length > 0 &&
      langList.length > 0 &&
      !validator.isEmpty(monthOfExp)
    ) {
      if (
        validator.isMobilePhone(profPhone, "en-IN") &&
        validator.isEmail(profEmail)
      ) {
        const profSkill = skillList.map((i) => ({ aid: i.id }));
        const profLang = langList.map((i) => ({ aid: i.id }));
        const credentials = {
          uuid: profUuid,
          user: profName,
          phone: profPhone,
          email: profEmail,
          level: consultantLevel,
          exp: monthOfExp,
          profLang,
          profSkill,
        };
        console.log("professional submit", credentials);
        try {
          const res = await vasthuUrl.post(
            `updateProfessionalDetails/profile/${profUuid}`,
            {
              uuid: profUuid,
              userName: profName,
              phone_no: profPhone,
              email: profEmail,
              levelId: consultantLevel,
              monthOfExp: monthOfExp,
              language: profLang,
              skill: profSkill,
              ratePerMin: rateMin,
              maxRate: maximunRate,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          alert("Update Successfully!!!");
          setShowModal(false);
          setProfUuid("");
          setProfName("");
          setProfEmail("");
          setProfPhone("");
          setRateMin("");
          setMaximunRate("");
          setConsultantLevel("");
          setMonthOfExp("");
          setSkillList([]);
          setLangList([]);
        } catch (error) {
          alert("Failed to Update");
        }
      } else {
        alert("CREDENTIALS GIVEN IS INVALID");
      }
    } else {
      alert("CREDENTIALS IS EMPTY");
    }
  };

  //Adding for Prof profile status
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setDetail(id);
  };

  //deactivate key

  const updateDetail = async (e) => {
    try {
      const res = await vasthuUrl
        .post(
          `info/changeAccountStatus`,
          {
            uuid: detail.uuid,
            statusChangedDate: endDate,
            reason: Comment,
            proStatus: status,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          handleClose();
          alert("Successfully Consultant Account Status Updated!");
        });
    } catch (error) {
      alert("Failed to Update");
    }
  };

  const column = [
    { field: "name", headerName: "NAME", width: 200 },
    { field: "phoneNumber", headerName: "PHONE", width: 150 },
    { field: "email", headerName: "EMAIL", width: 200 },
    { field: "consultantLevel", headerName: "CATEGORY", width: 150 },
    {
      field: "accountstatus",
      headerName: "Professional Account Status",
      type: "actions",
      width: 150,
      renderCell: (i) => (
        <Button className="all_btn" onClick={() => handleShow(i.row)}>
          Deactivate
        </Button>
      ),
    },
    {
      field: "editprofdata",
      headerName: "Edit Professional Data",
      type: "action",
      width: 150,
      renderCell: (i) => (
        <Button
          className="all_btn"
          onClick={() => getAndEditDetails(i.row.uuid)}
        >
          Update
        </Button>
      ),
    },
  ];

  const originalDate = new Date();
  const formattedDate = format(originalDate, "MMM d, yyyy HH:mm");

  return (
    <React.Fragment>
      <center>
        <h4>
          <u>CONSULTANT DETAILS</u>
        </h4>
      </center>
      {message && (
        <Alert variant="info" className="text-center">
          {message}
        </Alert>
      )}
      {!loading ? (
        profData.length > 0 ? (
          <>
            {/* <Table
              striped
              bordered
              hover
              responsive
              className="deskTable text-center all_border"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>PHONE</th>
                  <th>EMAIL</th>
                  <th>CATEGORY</th>
                  <th>Professional Account Status</th>
                  <th>Edit Professional Data </th>
                </tr>
              </thead>
              <tbody>
                {profData.map((i, j) => (
                  <tr key={j}>
                    <td>{i.name}</td>
                    <td>{i.phoneNumber}</td>
                    <td>{i.email}</td>
                    <td>{i.consultantLevel}</td>
                    <td>
                      <button
                        type="button"
                        className="btn all_btn ms-1"
                        onClick={() => handleShow(i)}
                      >
                        Deactivate
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn all_btn ms-1"
                        onClick={() => getAndEditDetails(i.uuid)}
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table> */}
            <Grid container className="deskTable">
              <DataGrid
                className="all_border - text-center me-3 data_grid"
                rows={profData}
                columns={column}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                getRowId={(row) => row.uuid}
              />
            </Grid>
            {/* <MobileCard data={profData} /> */}
            {profData.map((i, j) => (
              <div className="justify-content-center mobileCard">
                <Card
                  className="mobileCard"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "#fff7c3",
                    border: "1px solid #ffb33e",
                  }}
                >
                  <Card.Body>
                    <Card.Title>Name: {i.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      Phone: {i.phoneNumber}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2 text-muted">
                      Email: {i.email}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2 text-muted">
                      Category: {i.consultantLevel}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2 text-muted">
                      Account Status:
                      <Button
                        size="sm"
                        type="button"
                        className="btn all_btn ms-1"
                        onClick={() => handleShow(i)}
                      >
                        Deactivate
                      </Button>
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2 text-muted">
                      Edit :
                      <Button
                        size="sm"
                        type="button"
                        className="btn all_btn ms-1"
                        onClick={() => getAndEditDetails(i.uuid)}
                      >
                        Update
                      </Button>
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </>
        ) : (
          <center>
            <h5>No Professional Found</h5>
          </center>
        )
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setIsDisable(true);
        }}
        size="lg"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter "
        centered
      >
        <Modal.Header className="all_background" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "auto" }}
          >
            Details of Consultant {profName}
          </Modal.Title>
        </Modal.Header>

        {loading ? (
          <center>
            <Spinner animation="border" variant="warning" />
          </center>
        ) : (
          <Modal.Body>
            {message && (
              <Alert variant="info" className="text-center">
                {message}
              </Alert>
            )}

            <Form>
              <Form.Group className="mb-3" controlId="adminAddprofName">
                <Form.Label>Professional Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Unique User Name"
                  value={profName}
                  disabled={isDisable}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setProfName(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="adminAddprofExp">
                <Form.Label for="positive-number">Month of Exp</Form.Label>
                <FormControl
                  placeholder="Total Experience in Month"
                  value={monthOfExp}
                  disabled={isDisable}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setMonthOfExp(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="formSkill">
                <Form.Label>Skill</Form.Label>
                <Multiselect
                  id={"profSkill"}
                  selectedValues={skillList}
                  displayValue="skillName"
                  disable={isDisable}
                  placeholder="Select Skill"
                  onRemove={(list, value) => {
                    setSkillList([...list]);
                  }}
                  onSearch={function noRefCheck() {}}
                  onSelect={(list, value) => {
                    console.log("multi select", [...list]);
                    setSkillList([...list]);
                  }}
                  options={skillMaster}
                  showCheckbox
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="formLang">
                <Form.Label>language Known</Form.Label>
                <Multiselect
                  id={"profLang"}
                  selectedValues={langList}
                  disable={isDisable}
                  displayValue="language"
                  placeholder="Select Known Language"
                  onRemove={(list, value) => {
                    setLangList([...list]);
                  }}
                  onSearch={function noRefCheck() {}}
                  onSelect={(list, value) => {
                    console.log("multi select", [...list]);
                    setLangList([...list]);
                  }}
                  options={langMaster}
                  showCheckbox
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="adminAddprofEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={profEmail}
                  disabled={isDisable}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setProfEmail(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="adminAddprofPhone">
                <Form.Label>Phone No*</Form.Label>
                <Form.Control
                  type="phone"
                  placeholder="Enter Mobile No"
                  value={profPhone}
                  disabled={isDisable}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setProfPhone(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-2" controlId="formPlotLagna">
                <Form.Label>Consultant Category</Form.Label>
                <Form.Select
                  aria-label="Level"
                  style={{ fontSize: "1rem" }}
                  value={consultantLevel}
                  disabled={isDisable}
                  onChange={(e) => {
                    setConsultantLevel(e.target.value);
                  }}
                >
                  <option value="">Consultant Category</option>
                  {level.map((i, j) => (
                    <option key={j} value={i.id}>
                      {i.level}
                      {`(${i.sharePercent}%)`}
                    </option>
                  ))}
                </Form.Select>
                <Form.Group className="mb-3" controlId="ratePerMin">
                  <Form.Label>Rate per min*</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter per min"
                    value={rateMin}
                    disabled={isDisable}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setRateMin(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="ratePerMin">
                  <Form.Label>Maximun Rate*</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter maximun rate"
                    value={maximunRate}
                    disabled={isDisable}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setMaximunRate(e.target.value);
                    }}
                  />
                </Form.Group>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <Button onClick={() => handleOpen()} className="all_btn">
                    History
                  </Button>
                </div>
              </Form.Group>
            </Form>
            <div className="d-flex all_border justify-content-around">
              <Button
                className="mt-2 all_btn"
                style={{ borderRadius: 50 }}
                onClick={() => {
                  setIsDisable(true);
                  setShowModal(false);
                }}
              >
                {t("TXT.CLO.LO")}
              </Button>
              {isDisable ? (
                <Button
                  className="all_btn"
                  style={{ borderRadius: 50 }}
                  onClick={() => setIsDisable(false)}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  className="mt-2 all_btn"
                  style={{ borderRadius: 50 }}
                  onClick={(event) => {
                    updateProfessionalDetail(event);
                    setIsDisable(false);
                  }}
                >
                  Update
                </Button>
              )}
            </div>
          </Modal.Body>
        )}
      </Modal>

      {/* History model */}
      <Modal show={showHistory} onHide={handleCloses}>
        <Modal.Header className="modal-header all_background" closeButton>
          <Modal.Title>Previous History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="p-5" striped>
            <thead>
              <tr>
                <th>Date</th>
                <th>Rate Per Minute</th>
                <th>Max Rate</th>
              </tr>
            </thead>
            <tbody>
              {rateHistory && rateHistory.length > 0 ? (
                rateHistory.map((item) => (
                  <tr key={item.id}>
                    <td>
                      {format(
                        new Date(item.updatedTime),
                        "d MMM yyyy, (HH:mm)"
                      )}
                    </td>
                    <td>{item.ratePerMin}</td>
                    <td>{item.maxRate}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">
                    <center> History Not Found</center>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {/* modal for prof status */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="all_background" closeButton>
          <Modal.Title>Changing Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p>User Name: {detail.name}</p>
            <Col>
              <Form.Label>Account status: </Form.Label>&nbsp;
              <Form.Select
                className=""
                style={{ width: "190px", display: "inline-block" }}
                defaultValue={detail.status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <option>Please Select</option>
                {getstatus.map((i) => (
                  <option>{i.status}</option>
                ))}
              </Form.Select>
            </Col>
            &nbsp;&nbsp;
            <Row>
              <Form.Group className="mb-3" controlId="validitydate">
                <Form.Label>Date :</Form.Label> &nbsp;
                {/* <DatePicker
                  selectsRange={true}
                  style={{ borderColor: "red" }}
                  required={true}
                  ariaRequired={true}
                  dateFormat={"dd/MM/yyyy"}
                  minDate={new Date()}
                  onChange={(dates) => {
                    const [start, end] = dates;
                    // x.offerStartDate = start;
                    // x.offerEndDate = end;
                    setDateRange(dates);
                    setComboList([...comboList]);
                    console.log(comboList);
                  }}
                  placeholderText="Select Date"
                  startDate={startDate}
                  endDate={endDate}
                  withPortal
                /> */}
                <DatePicker
                  value={endDate}
                  format={"dd-MM-yyyy"}
                  placeholder="Choose a date"
                  onChange={(e) => {
                    setEndDate(e);
                    console.log(endDate);
                  }}
                  minDate={today}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Label column="sm" lg={2}>
                {" "}
                Reason :{" "}
              </Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Reason..."
                  required
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="all_border d-flex justify-content-evenly">
          <Button className="all_btn" onClick={handleClose}>
            Close
          </Button>
          <Button
            className="all_btn"
            onClick={(e) => {
              updateDetail();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default AdminProfDetails;
