import React, { useState, useEffect, Suspense } from "react";
import { vasthuUrl } from "../component/Api";

import { Navigate, useNavigate, useLocation } from "react-router-dom";

import { Table, Card, Button } from "react-bootstrap";
import NewBootCard from "../component/NewBootCard";

import { UseAuth } from "../context/Auth";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

const AudioCallAdmin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [audiocallHistoryList, setAudiocallHistoryList] = useState([
    {
      callFrom: "",
      callTo: "",
      duration: "",
      offerId: "",
      offerName: "",
      status: "",
      proposedCallDuration: "",
    },
  ]);
  const token = window.sessionStorage.getItem("token");
  const location = useLocation();
  const [callStatus, setCallStatus] = useState("Audiocall List");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [activeStatusId, setActiveStatusId] = React.useState();
  const [changingStatus, setChangingStatus] = React.useState();
  const [changedStatus, setChangedStatus] = React.useState(); // only for hotreload while status changed

  useEffect(() => {
    console.log("screen: Audiocall.js");

    vasthuUrl
      .get(`vasthu/user/getAudioCallHistory`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((rs) => {
        console.log("achistory", rs.data);
        setAudiocallHistoryList(rs.data);
      })
      .catch((er) => console.log("call history err", er));
  }, [changedStatus]);

  function openModal(id) {
    setIsOpen(true);
    console.log("modalid", id);
    setActiveStatusId(id);
  }

  function afterOpenModal() {
    console.log("afterOpenModal");
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handlePsumbit1 = (event) => {
    event.preventDefault();
    closeModal();
    console.log("activeid_1" + activeStatusId + "of" + changingStatus);
    try {
      vasthuUrl
        .post(
          `vasthu/user/updateAudioCallStatus`,
          {
            activeStatusId,
            changingStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            alert("Successfully the Status changed to " + res.data.status);
            setChangedStatus(res.data.status);
          }
        });
    } catch (error) {
      alert("CHECK INTERNET CONNECTION");
    }
  };

  const handleStatus = (e) => {
    setChangingStatus(e.target.value);
    console.log("activeid" + activeStatusId + "of" + e.target.value);
  };

  return (
    // <div>
    // {/* <div>{callStatus}</div> */}
    // {/* <div>{audiocallHistoryList}</div> */}
    <React.Fragment>
      <div>
        <center>
          <h2>{t("USR.AUDCH.TEXT1")}</h2>
        </center>
      </div>
      <Table
        striped
        bordered
        hover
        responsive
        className="deskTable  all_border text-center"
      >
        <thead>
          <tr>
            <th>{"Client"}</th>
            <th>{"Consultant"}</th>
            <th>{"Duration"}</th>
            {/* <th>{"Date"}</th> */}
            <th>{"Proposed Duration"}</th>
            <th>{"offer"}</th>
            <th>{"Status"}</th>
            <th>{"Change Status"}</th>
          </tr>
        </thead>
        <tbody>
          {audiocallHistoryList.map((i, j) => (
            <tr key={j}>
              <td>{i.callTo}</td>
              <td>{i.callFrom}</td>
              <td>{i.duration}</td>
              {/* <td>15/06/2023</td> */}
              <td>{i.proposedCallDuration}</td>
              <td>{i.offerName}</td>
              <td>{i.status}</td>
              <td>
                <Button
                  className="mx-1 all_btn"
                  // onClick={() => handleView(i.offerDetails)}
                  onClick={() => openModal(i.id)}
                >
                  Change
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles}
        contentLabel="Change Status"
      >
        <h2 className="text-center all_background">Change Status</h2>
        <form onSubmit={handlePsumbit1}>
          <div
            className="text-center"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {/* <div>
                <label>Started</label>
                <input
                  type="radio"
                  name="status"
                  value="Started"
                  onChange={handleStatus}
                  style={{marginLeft:"revert"}}
                />
              </div>
              <div>
                <label>inprogress</label>
                <input
                  type="radio"
                  name="status"
                  value="inprogress"
                  onChange={handleStatus}
                  style={{marginLeft:"revert"}}
                />
              </div>
              <div>
                <label>onhold</label>
                <input
                  type="radio"
                  name="status"
                  value="onhold"
                  onChange={handleStatus}
                  style={{marginLeft:"revert"}}
                />
              </div>
              <div>
                <label>cancelled</label>
                <input
                  type="radio"
                  name="status"
                  value="cancelled"
                  onChange={handleStatus}
                  style={{marginLeft:"revert"}}
                />
              </div>
              <div>
                <label>completed</label>
                <input
                  type="radio"
                  name="status"
                  value="completed"
                  onChange={handleStatus}
                  style={{marginLeft:"revert"}}
                />
              </div> */}
            <select onChange={(e) => setChangingStatus(e.target.value)}>
              <option value="Started">Started</option>
              <option value="inprogress">inprogress</option>
              <option value="onhold">onhold</option>
              <option value="cancelled">cancelled</option>
              <option value="completed">completed</option>
            </select>

            <input
              className="all_btn"
              style={{ marginTop: "10px" }}
              type="submit"
            />
          </div>
          <center>
            <Button
              className="all_btn"
              size="sm"
              style={{ width: "100%", marginTop: "8px" }}
              onClick={closeModal}
            >
              {t("TXT.BTN.CAN.LO")}
            </Button>
          </center>
        </form>
      </Modal>

      <div className=" mobileCard justify-content-center mx-1">
        {audiocallHistoryList.map((i, j) => (
          <NewBootCard class="p-3 mb-2 bg-secondary text-white" key={j}>
            <h4 className="text-center">
              {"Client"}: {i.callTo}
            </h4>
            <hr></hr>
            <h6>
              {"Consultant"}: {i.callFrom}
            </h6>
            <h6>
              {"Duration"}: {i.duration}
            </h6>
            {/* <h6>
              {"Date"}: {i.date}
            </h6> */}
            <h6>
              {"Proposed Duration"}: {i.proposedCallDuration}
            </h6>
            <h6>
              {"Offer"}: {i.offerName}
            </h6>
            <h6>
              {"Status"}: {i.status}
            </h6>
            <Button
              className="mx-1 all_btn"
              // onClick={() => handleView(i.offerDetails)}
              onClick={() => openModal(i.id)}
            >
              Change
            </Button>
            {/* <select  onChange={e => setChangingStatus(e.target.value)}>
                <option value="Started">Started</option>
                <option value="inprogress">inprogress</option>
                <option value="onhold">onhold</option>
                <option value="cancelled">cancelled</option>
                <option value="completed">completed</option>  
              </select> */}
          </NewBootCard>
        ))}
      </div>
    </React.Fragment>
    // </div>
  );
};

export default AudioCallAdmin;
