import React, { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import ConsultantCallInfo from './ConsultantCallInfo';
import ConsultantPayments from './ConsultantPayments';
import crmServices from './crm/data/crmServices';

const ConsultantTabs = () => {
  const [key, setKey] = useState("callinfo");
  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="justify-content-center"
    >
      <Tab eventKey="callinfo" title="CALL INFO">
        <center>
          <h4 className="m-1">
            <u>Consultant Meeting Details</u>
          </h4> 
        </center>
        <ConsultantCallInfo />
      </Tab>
      <Tab eventKey="consultantearnings" title="CONSULTANT EARNINGS">
        <center>
          <h4 className="m-1">
            <u>Consultant Shares</u>
          </h4>
        </center>
        <ConsultantPayments />
      </Tab>
      {/* <Tab eventKey="crm" title="CRM">
        <center>
          <h4 className="m-1">
            <u>Crm Details</u>
          </h4>
        </center>
        {/* <ConsultantPayments /> */}
      <crmServices />
      {/* </Tab> */}
    </Tabs>
  )
}

export default ConsultantTabs