// import React from "react";
// // import { NavLink } from "react-router-dom";
// import { UseAuth } from "../context/Auth";
// import Header from "../component/Header";
// import Carousel from "../component/Carousel";

// const Home = () => {
//   const { token } = UseAuth();
//   console.log(token);
//   return (
//     <React.Fragment>
//       <Header title='VASTHU SASTRA' breakpionts='lg' navList={[{link:'login',pageName:'LOGIN'}, {link:'register', pageName:'REGISTER'}]} />
//       <Carousel />
//     </React.Fragment>
//   );
// };

// export default Home;

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
// import { NavLink } from "react-router-dom";
import { UseAuth } from "../context/Auth";
import UserHeader from "../pages/userHeader";
import Carousel from "../component/Carousel";
import HomeAccordian from "../component/HomeAccordian";
import HomeFooterInfo from "../component/HomeFooterInfo";
import HomeStats from "../component/homeStats";
import HomeConsultant from "../component/HomeConsultant";
import Slider from "../component/OwlCarousel";
import { useNavigate } from "react-router-dom";
import HomeTestimonials from "../component/HomeTestimonials";
import HomeServicePlans from "../component/HomeServicePlan";
import { FiPhoneCall } from "react-icons/fi";

import {
  Card,
  Navbar,
  Container,
  Offcanvas,
  Nav,
  NavItem,
  NavDropdown,
  Button,
  Image,
  Dropdown,
} from "react-bootstrap";
import HomeMarquee from "../component/HomeMarquee";
import HomeServices from "../component/HomeServices";
import HomePageOffers from "../component/HomePageOffers";
import HomeUserReview from "../component/HomeUserReview";
import CustomerOneTimePricing from "./CustomerOneTimeService";
import CustomerPeriodicPricing from "./CustomerPeriodicService";
import UserMain from "../pages/UserMain";

import { GiLotus } from "react-icons/gi";
import ScrollButton from "./ScrollButton";
import BasicSpeedDial from "./BasicSpeedDial";
import OurProducts from "./OurProducts";
import OurServicesConsultant from "./OurServicesConsultant";
const UserHome = () => {
  const { onLogout } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  const navigate = useNavigate();

  const [paytmurl, setPaytmurl] = useState("");

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  async function openModal() {
    await pay()
      .then(setIsOpen(true))
      .catch(console.log("some network issue"));
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  //   useEffect(() => {
  //     const queryString = window.location.search;
  // console.log(queryString);
  // const urlParams = new URLSearchParams(queryString);
  // const bank = urlParams.get('BANKNAME')
  // console.log(bank);

  //   })
  const pay = async () => {
    await fetch(`http://localhost:8080/vasthu/submitPaymentDetail`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        ORDER_ID: "or12897",
        TXN_AMOUNT: "100",
        CUST_ID: "cus45786",
      }),
    })
      .then(async (rawResponse) => {
        const content = await rawResponse;
        console.log(content);
        // console.log(content.url);
        setPaytmurl(content.url);
      })
      .catch((err) => console.log("fetch error -- " + err));
  };
  return (
    <React.Fragment>
      <div>
        {/* <HomeMarquee /> */}
        <Carousel />
        {/* <ScrollButton /> */}
        <HomeServices />
        <HomePageOffers />
        <OurServicesConsultant />
        {/* <HomeServicePlans /> */}
        {/* <CustomerOneTimePricing />
      <CustomerPeriodicPricing /> */}
        <OurProducts />
        <HomeConsultant />
        <HomeTestimonials />
        {/* <HomeUserReview /> */}
        <HomeFooterInfo />
      </div>
      <div
        style={{
          position: "fixed",
          top: "75%",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            // backgroundColor: 'aquamarine',
            float: "right",
            padding: "7px",
            borderRadius: "7px",
            marginRight: "5px",
          }}
        >
          {/* <FiPhoneCall />+919025028213 */}
          <BasicSpeedDial />
          <ScrollButton />
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserHome;
