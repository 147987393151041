// import React, { useState,useEffect,UseAuth  } from "react";
// import { Navigate, NavLink, useNavigate,useLocation} from "react-router-dom";
// import "../style.css";
// import { videoServerUrl } from "../../component/Api";
// import { vasthuUrl } from "../../component/Api";
// import { Button} from "react-bootstrap";

//   const VideoCallSender = () => {
//   const [booked_slot_id,setBooked_slot_id] = useState("");
//   const [meeting_code,setMeeting_code] = useState("");
//   const token = window.localStorage.getItem("token");
//   const {state} = useLocation();
//   console.log("booked_slot_id"+ String(state.i.slotId));
//     // setBooked_slot_id(location.state.id);

//   useEffect(() => {
//     try {
//       setBooked_slot_id(state.i.slotId);
//       console.log("Token"+token)
//       vasthuUrl
//         .get(`getmeetingcode/${state.i.slotId}`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         })
//         .then((res) => {
//           console.log(res.data);
//           setMeeting_code(res.data);

//         });
//     } catch (e) {
//       console.log("SERVER ERROR");
//     }
//   }, []);

//   const navigate = useNavigate();

//   // const webSocket = new WebSocket("ws://127.0.0.1:3001")
//   const webSocket = new WebSocket(videoServerUrl);
//   if (window.localStorage) {
//     console.log("local storage supported");
//   } else {
//     console.log("local storage not supported");
//   }
//   let displayMediaStream;

//   webSocket.onmessage = (event) => {
//     handleSignallingData(JSON.parse(event.data));
//   };

//   function handleSignallingData(data) {
//     switch (data.type) {
//       case "answer":
//         peerConn.setRemoteDescription(data.answer);
//         break;
//       case "candidate":
//         peerConn.addIceCandidate(data.candidate);
//     }
//   }

//   let username;
//   function sendUsername() {
//     // username = document.getElementById("username-input").value;
//     username = meeting_code;
//     console.log("usernameinput" + username);
//     sendData({
//       type: "store_user",
//     });
//   }

//   function sendData(data) {
//     data.username = username;
//     if (webSocket.readyState === 1) {
//       webSocket.send(JSON.stringify(data));
//     } else {
//       console.log("socket not ready");
//     }
//   }

//   let localStream;
//   let peerConn;
//   let senders = [];
//   // new chat ========================================================================
//   let dataChannel;
//   let connectedUser;

//   //=================================================================================

//   function startCall() {
//     try {
//       sendUsername();
//     } catch (error) {
//       console.log("not able to send username");

//     }
//     document.getElementById("video-call-div").style.display = "inline";

//       var getUserMedia =
//       navigator.getUserMedia ||
//       navigator.webkitGetUserMedia ||
//       navigator.mozGetUserMedia;
//     getUserMedia.call(
//       navigator,
//       {
//         video: {
//           frameRate: 24,
//           width: {
//             min: 480,
//             ideal: 720,
//             max: 1280,
//           },
//           aspectRatio: 1.33333
//           // facingMode:'user'

//         },
//         audio: true,

//       },
//       (stream) => {
//         let configuration = {
//           iceServers: [
//             {
//               urls: "stun:stun.aawsoft.com:3478",
//             },
//             {
//               urls: "turn:turn.aawsoft.com:3478",
//               username: "guest",
//               credential: "somepassword",
//             },

//             {
//               urls: "stun:stun.aawsoft.com:5349",
//             },
//             {
//               urls: "turn:turn.aawsoft.com:5349",
//               username: "guest",
//               credential: "somepassword",
//             },
//           ],
//           optional: [
//             {
//               RtpDataChannels: true,
//             },
//           ],
//         };

//         peerConn = new RTCPeerConnection(configuration);

//         // new chat ======================================================================

//         dataChannel = peerConn.createDataChannel("channel1", {
//           reliable: true,
//         });

//         peerConn.addEventListener("datachannel", (event) => {
//           dataChannel = event.channel;
//           // dataChannel.send("test")
//         });

//         dataChannel.onerror = function(error) {
//           console.log("Ooops...error:", error);
//         };

//         //when we receive a message from the other peer, display it on the screen
//         dataChannel.onmessage = function(event) {
//           // chatArea.innerHTML += connectedUser + ": " + event.data + "<br />";

//           var chatArea = document.querySelector("#chatarea");
//           // chatArea.innerHTML += "Remote: " + event.data + "<br />";
//           chatArea.innerText += "Remote: " + event.data;
//           chatArea.innerHTML += "<br />";

//         };

//         dataChannel.onclose = function() {
//           console.log("data channel is closed");
//           localStream.getTracks().forEach(function(track) {
//             track.stop();
//           });
//         };

//         // ================================================================================

//         //
//         localStream = stream;

//         localStream
//           .getTracks()
//           .forEach((track) =>
//             senders.push(peerConn.addTrack(track, localStream))
//           );

//         document.getElementById("local-video").srcObject = localStream;

//         peerConn.ontrack = (event) => {
//           const video = document.getElementById("remote-video");
//           video.srcObject = event.streams[0];
//         };

//         peerConn.onicecandidate = (e) => {
//           if (e.candidate == null) return;
//           sendData({
//             type: "store_candidate",
//             candidate: e.candidate,
//           });
//         };

//         createAndSendOffer();
//       },
//       (error) => {
//         console.log(error);
//       }
//     );
//   }

//   // new chat ======================================================================================
//   //alias for sending JSON encoded messages
//   function send(message) {
//     //attach the other peer username to our messages
//     if (connectedUser) {
//       message.name = connectedUser;
//     }

//     webSocket.send(JSON.stringify(message));
//   }

//   // ======================================================================================

//   function createAndSendOffer() {
//     peerConn.createOffer(
//       (offer) => {
//         sendData({
//           type: "store_offer",
//           offer: offer,
//         });

//         peerConn.setLocalDescription(offer);
//       },
//       (error) => {
//         console.log(error);
//       }
//     );
//   }

//   let isAudio = true;
//   function muteAudio() {
//     isAudio = !isAudio;
//     localStream.getAudioTracks()[0].enabled = isAudio;
//     var muteAudio = document.getElementById("mute-audio");
//     muteAudio.innerText === "Mute Audio"
//       ? (muteAudio.innerText = "Un Mute Audio")
//       : (muteAudio.innerText = "Mute Audio");
//   }

//   let isVideo = true;
//   function muteVideo() {
//     isVideo = !isVideo;
//     localStream.getVideoTracks()[0].enabled = isVideo;
//     var muteVideo = document.getElementById("mute-video");
//     muteVideo.innerText == "Mute Video"
//       ? (muteVideo.innerText = "Un Mute Video")
//       : (muteVideo.innerText = "Mute Video");
//   }

//   function hangUp(e) {
//     e.preventDefault();
//     peerConn.close();
//     localStream.getVideoTracks()[0].enabled = false;
//     localStream.getAudioTracks()[0].enabled = false;
//     localStream.getTracks().forEach(function(track) {
//       track.stop();
//     });
//     navigate("/professional");
//   }

//   // =======

//   // new chat =============================================================================
//   //when user clicks the "send message" button
//   const sendMsg = () => {
//     var msgInput = document.getElementById("msgInput");
//     var chatArea = document.querySelector("#chatarea");
//     console.log("messageInput" + msgInput);

//     var val = msgInput.value;
//     console.log("message" + val);

//     // chatArea.innerHTML += "Me: " + val + "<br />";
//     chatArea.innerText += "Me: " + val;
//     chatArea.innerHTML += "<br />";
//     dataChannel.send(val);
//     msgInput.value = "";
//   };

//   async function shareScreen() {
//     try {
//       if (!displayMediaStream) {
//         displayMediaStream = await navigator.mediaDevices.getDisplayMedia({video: true});
//       }
//       senders
//         .find((sender) => sender.track.kind === "video")
//         .replaceTrack(displayMediaStream.getTracks()[0]);

//       //show what you are showing in your "self-view" video.
//       document.getElementById("local-video").srcObject = displayMediaStream;

//       //hide the share button and display the "stop-sharing" one
//       document.getElementById("share-button").style.display = "none";
//       document.getElementById("stop-share-button").style.display = "inline";
//     } catch (e) {
//       console.log("Unable to acquire screen capture: " + e);
//     }
//   }

//   async function stopShareScreen() {
//     try {
//       senders
//         .find((sender) => sender.track.kind === "video")
//         .replaceTrack(
//           localStream.getTracks().find((track) => track.kind === "video")
//         );

//       document.getElementById("local-video").srcObject = localStream;
//       document.getElementById("share-button").style.display = "inline";
//       document.getElementById("stop-share-button").style.display = "none";
//     } catch (error) {
//       console.log("Unable to stop share screen: " + error);
//     }
//   }

//   return (
//     <div>
//       <div>
//         {/* <input
//           placeholder="Enter username..."
//           type="text"
//           id="username-input"
//         />
//         <br />
//         <button onClick={sendUsername}>Send</button> */}
//         <Button id= "start_call" variant="warning" onClick={startCall}>{t("TXT.STA.CALL.LO")}</Button>

//       </div>
//       <video muted id="local-video" autoPlay></video>
//         <video id="remote-video" autoPlay></video>
//         <div className="call-action-div">
//           <div>
//             <div id="video-call-div" style={{ display: "none" }}>
//         <Button id="mute-video" variant="warning" onClick={muteVideo}>
//               Mute Video
//             </Button>
//             <Button id="mute-audio" variant="warning" onClick={muteAudio}>
//               Mute Audio
//             </Button>
//             <Button id="share-button" variant="warning" onClick={shareScreen}>
//               Share Screen
//             </Button>
//             <Button id="stop-share-button" variant="warning" onClick={stopShareScreen}>
//               Stop Share
//             </Button>
//             {/* <NavLink to="/home"> */}
//             <Button variant="warning" onClick={hangUp}>HangUp</Button>
//             {/* </NavLink> */}
//           </div>
//           <div>
//             <div id="chatarea"></div>
//             <div>
//               <input id="msgInput" type="text" placeholder="message" />
//               <Button id="sendMsgBtn" variant="warning" onClick={sendMsg}>
//                 Send
//               </Button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoCallSender;

// import React, { useState, useEffect, UseAuth } from "react";
// import { Navigate, NavLink, useNavigate, useLocation } from "react-router-dom";
// import "../style.css";
// import { videoServerUrl } from "../../component/Api";
// import { vasthuUrl } from "../../component/Api";
// import { Alert, Button } from "react-bootstrap";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";
// import { MdOutlineScreenShare, MdOutlineStopScreenShare } from "react-icons/md";
// import { useTranslation } from "react-i18next";

// const VideoCallSender = () => {
//   const {t} = useTranslation();
//   const [booked_slot_id, setBooked_slot_id] = useState("");
//   const [meeting_code, setMeeting_code] = useState("");
//   const token = window.sessionStorage.getItem("token");
//   const { state } = useLocation();
//   console.log("booked_slot_id" + String(state.i.slotId));
//   // setBooked_slot_id(location.state.id);

//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   useEffect(() => {
//     try {
//       setBooked_slot_id(state.i.slotId);
//       console.log("Token" + token);
//       vasthuUrl
//         .get(`getmeetingcode/${state.i.slotId}`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         })
//         .then((res) => {
//           console.log(res.data);
//           setMeeting_code(res.data);
//         });
//     } catch (e) {
//       console.log("SERVER ERROR");
//     }
//   }, []);

//   const navigate = useNavigate();

//   // const webSocket = new WebSocket("ws://127.0.0.1:3001")
//   const webSocket = new WebSocket(videoServerUrl);
//   if (window.sessionStorage) {
//     console.log("session storage supported");
//   } else {
//     console.log("session storage not supported");
//   }
//   let displayMediaStream;

//   webSocket.onmessage = (event) => {
//     try {
//       handleSignallingData(JSON.parse(event.data));
//     } catch (error) {
//       alert("Please ensure the remote peer connection");
//     }
//     // handleSignallingData(JSON.parse(event.data));
//   };

//   function handleSignallingData(data) {
//     switch (data.type) {
//       case "answer":
//         try {
//           peerConn.setRemoteDescription(data.answer);
//           break;
//         } catch (error) {
//           console.log(
//             "setRemoteDescription failed because of not finding currect remote connection"
//           );
//         }
//       // peerConn.setRemoteDescription(data.answer);
//       // break;
//       case "candidate":
//         try {
//           peerConn.addIceCandidate(data.candidate);
//         } catch (error) {
//           console.log(
//             "setRemoteDescription failed because of not finding currect remote connection"
//           );
//         }

//       // peerConn.addIceCandidate(data.candidate);
//     }
//   }

//   let username;
//   function sendUsername() {
//     // username = document.getElementById("username-input").value;
//     username = meeting_code;
//     console.log("usernameinput" + username);
//     sendData({
//       type: "store_user",
//     });
//   }

//   function sendData(data) {
//     data.username = username;
//     if (webSocket.readyState === 1) {
//       webSocket.send(JSON.stringify(data));
//     } else {
//       console.log("socket not ready");
//     }
//   }

//   let localStream;
//   let peerConn;
//   let senders = [];
//   // new chat ========================================================================
//   let dataChannel;
//   let connectedUser;

//   //=================================================================================

//   function startCall() {
//     try {
//       sendUsername();
//     } catch (error) {
//       console.log("not able to send username");
//     }
//     document.getElementById("video-call-div").style.display = "inline";

//     var getUserMedia =
//       navigator.getUserMedia ||
//       navigator.webkitGetUserMedia ||
//       navigator.mozGetUserMedia;
//     getUserMedia.call(
//       navigator,
//       {
//         video: {
//           frameRate: 24,
//           width: {
//             min: 480,
//             ideal: 720,
//             max: 1280,
//           },
//           aspectRatio: 1.33333,
//           // facingMode:'user'
//         },
//         audio: true,
//       },
//       (stream) => {
//         let configuration = {
//           iceServers: [
//             {
//               urls: "stun:stun.aawsoft.com:3478",
//             },
//             {
//               urls: "turn:turn.aawsoft.com:3478",
//               username: "guest",
//               credential: "somepassword",
//             },

//             {
//               urls: "stun:stun.aawsoft.com:5349",
//             },
//             {
//               urls: "turn:turn.aawsoft.com:5349",
//               username: "guest",
//               credential: "somepassword",
//             },

//             {
//                   "urls": ["stun:stun.l.google.com:19302",
//                   "stun:stun1.l.google.com:19302",
//                   "stun:stun2.l.google.com:19302"]
//               },

//               {
//                   urls: "stun:openrelay.metered.ca:80",
//                 },
//                 {
//                   urls: "turn:openrelay.metered.ca:80",
//                   username: "openrelayproject",
//                   credential: "openrelayproject",
//                 },
//                 {
//                   urls: "turn:openrelay.metered.ca:443",
//                   username: "openrelayproject",
//                   credential: "openrelayproject",
//                 },
//                 {
//                   urls: "turn:openrelay.metered.ca:443?transport=tcp",
//                   username: "openrelayproject",
//                   credential: "openrelayproject",
//                 }
//           ],
//           optional: [
//             {
//               RtpDataChannels: true,
//             },
//           ],
//         };

//         peerConn = new RTCPeerConnection(configuration);

//         // new chat ======================================================================

//         dataChannel = peerConn.createDataChannel("channel1", {
//           reliable: true,
//         });

//         try {
//           if (dataChannel.readyState !== "open") {
//             alert("Wait for client");
//           }
//         } catch (error) {
//           console.log("connection is open");
//         }

//         peerConn.addEventListener("datachannel", (event) => {
//           dataChannel = event.channel;
//           // dataChannel.send("test")
//           console.log("dataChannel", dataChannel);
//           // if(dataChannel.readyState !== "open"){
//           //   alert("Wait for client");
//           // }
//         });

//         dataChannel.onopen = function(success) {
//           console.log("Both are connected", success);
//           console.log(
//             "target.readystate+channel",
//             success.target.readyState,
//             "+",
//             success.target.label
//           );
//           if (
//             success.target.readyState == "open" &&
//             success.target.label == "channel1"
//           ) {
//             console.log("calling  api");

//             //    vasthuUrl
//             //                         .get(
//             //                           // `/subscription/useVasthuOffer/${i.id}`,
//             //                           `/subscription/useVasthuOffer/${i.subscriptionId}`,
//             //                           {
//             //                             headers: {
//             //                               Authorization: `Bearer ${token}`,
//             //                             },
//             //                           }
//             //                         )
//             //                         .then((rs) =>
//             //                           console.log(
//             //                             "offer subscription redeem count used",
//             //                             rs.data.message
//             //                           )
//             //                         )
//             //                         .catch((er) =>
//             //                           console.log(
//             //                             "offer subscription redeem count not reduced",
//             //                             er
//             //                           )
//             //                         );
//           }
//         };

//         dataChannel.onerror = function(error) {
//           console.log("Ooops...error:", error);
//         };

//         //when we receive a message from the other peer, display it on the screen
//         dataChannel.onmessage = function(event) {
//           // chatArea.innerHTML += connectedUser + ": " + event.data + "<br />";

//           var chatArea = document.querySelector("#chatarea");
//           // chatArea.innerHTML += "Remote: " + event.data + "<br />";
//           chatArea.innerText += "Remote: " + event.data;
//           chatArea.innerHTML += "<br />";

//           var chatArea1 = document.querySelector("#chatarea1");
//           if(chatArea1){
//           chatArea1.innerText += "Remote: " + event.data;
//           chatArea1.innerHTML += "<br />";
//           }
//           console.log('event.data', event.data)
//         };

//         dataChannel.onclose = function() {
//           console.log("data channel is closed");
//           alert("data call channel is closed - call hangup");
//           localStream.getTracks().forEach(function(track) {
//             track.stop();
//           });
//           window.location.reload();
//         };

//         // ================================================================================

//         //
//         localStream = stream;

//         localStream
//           .getTracks()
//           .forEach((track) =>
//             senders.push(peerConn.addTrack(track, localStream))
//           );

//         document.getElementById("local-video").srcObject = localStream;

//         peerConn.ontrack = (event) => {
//           const video = document.getElementById("remote-video");
//           video.srcObject = event.streams[0];
//         };

//         peerConn.onicecandidate = (e) => {
//           if (e.candidate == null) return;
//           sendData({
//             type: "store_candidate",
//             candidate: e.candidate,
//           });
//         };

//         createAndSendOffer();
//       },
//       (error) => {
//         console.log(error);
//       }
//     );
//   }

//   // new chat ======================================================================================
//   //alias for sending JSON encoded messages
//   function send(message) {
//     //attach the other peer username to our messages
//     if (connectedUser) {
//       message.name = connectedUser;
//     }

//     webSocket.send(JSON.stringify(message));
//   }

//   // ======================================================================================

//   function createAndSendOffer() {
//     peerConn.createOffer(
//       (offer) => {
//         sendData({
//           type: "store_offer",
//           offer: offer,
//         });

//         peerConn.setLocalDescription(offer);
//       },
//       (error) => {
//         console.log(error);
//       }
//     );
//   }

//   // let isAudio = true;
//   // function muteAudio() {
//   //   isAudio = !isAudio;
//   //   localStream.getAudioTracks()[0].enabled = isAudio;
//   //   var muteAudio = document.getElementById("mute-audio");
//   //   muteAudio.innerText === "Mute Audio"
//   //     ? (muteAudio.innerText = "Un Mute Audio")
//   //     : (muteAudio.innerText = "Mute Audio");
//   // }
//   let isAudio = true;
//   function muteAudio() {
//     isAudio = !isAudio;
//     localStream.getAudioTracks()[0].enabled = isAudio;
//     var muteAudio = document.getElementById("mute-audio");
//     var audioIcon = document.getElementById("audio-icon");
//     if (isAudio) {
//       audioIcon.classList.remove("fa-microphone-slash");
//       audioIcon.classList.add("fa-microphone");
//     } else {
//       audioIcon.classList.remove("fa-microphone");
//       audioIcon.classList.add("fa-microphone-slash");
//     }
//   }

//   // let isVideo = true;
//   // function muteVideo() {
//   //   isVideo = !isVideo;
//   //   localStream.getVideoTracks()[0].enabled = isVideo;
//   //   var muteVideo = document.getElementById("mute-video");
//   //   muteVideo.innerText == "Mute Video"
//   //     ? (muteVideo.innerText = "Un Mute Video")
//   //     : (muteVideo.innerText = "Mute Video");
//   // }
//   let isVideo = true;
//   function muteVideo() {
//     isVideo = !isVideo;
//     localStream.getVideoTracks()[0].enabled = isVideo;
//     var muteVideo = document.getElementById("mute-video");
//     var videoIcon = document.getElementById("video-icon");
//     if (isVideo) {
//       videoIcon.classList.remove("fa-video-slash");
//       videoIcon.classList.add("fa-video");
//     } else {
//       videoIcon.classList.remove("fa-video");
//       videoIcon.classList.add("fa-video-slash");
//     }
//   }

//   function hangUp(e) {
//     e.preventDefault();
//     peerConn.close();
//     localStream.getVideoTracks()[0].enabled = false;
//     localStream.getAudioTracks()[0].enabled = false;
//     localStream.getTracks().forEach(function(track) {
//       track.stop();
//     });
//     // test - after completeted video call
//     console.log("Subscription id on hangup", state.i.subscriptionId);
//     vasthuUrl
//       .post(
//         `subscription/consulationCallStatus`,
//         {
//           offerId: state.i.subscriptionId,
//           callFinished: true,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//       .then((rs) =>
//         console.log("offer subscription -call hangup", rs.data.message)
//       )
//       .catch((er) =>
//         console.log("offer subscription  count not reduced - on hangup", er)
//       );

//     // test - after completeted video call - end
//     navigate("/");
//   }

//   // =======

//   // new chat =============================================================================
//   //when user clicks the "send message" button
//   const sendMsg = () => {
//     var msgInput = document.getElementById("msgInput");
//     // let inputValue = document.getElementsByClassName("inputField")[0].value;
//     var chatArea = document.querySelector("#chatarea");
//     console.log("messageInput", msgInput);
//     console.log("chatArea", chatArea);

//     var val = msgInput.value;
//     console.log("message" + val);
//     try {
//       // chatArea.innerText +="Me: " + val;
//       // chatArea.innerHTML += "<br />";
//       dataChannel.send(val);
//       chatArea.innerText += "Me: " + val;
//       chatArea.innerHTML += "<br />";
//       msgInput.value = "";
//     } catch (error) {
//       alert("Please ensure the peer remote connection ");
//       msgInput.value = "";
//     }
//     // // chatArea.innerHTML += "Me: " + val + "<br />";
//     // chatArea.innerText +="Me: " + val;
//     // chatArea.innerHTML += "<br />";
//     // dataChannel.send(val);
//     // msgInput.value = "";
//   };
//   const sendMsg1 = () => {
//     var msgInput1 = document.getElementById("msgInput1");
//     var chatArea1 = document.querySelector("#chatarea1");
//     console.log("messageInput1", msgInput1);
//     console.log("chatArea1", chatArea1);
//     console.log("dataChannel", dataChannel);

//     var val1 = msgInput1.value;
//     console.log("message1" + val1);
//     try {
//       console.log("dataChannel", dataChannel);
//       dataChannel.send(val1);
//       chatArea1.innerText += "Me: " + val1;
//       chatArea1.innerHTML += "<br />";
//       msgInput1.value = "";
//     } catch (error) {
//       console.log("dataChannel", dataChannel);
//       alert("Please ensure the peer remote connection ");
//       msgInput1.value = "";
//     }
//   };

//   async function shareScreen() {
//     try {
//       if (!displayMediaStream) {
//         displayMediaStream = await navigator.mediaDevices.getDisplayMedia({
//           video: true,
//         });
//       }
//       senders
//         .find((sender) => sender.track.kind === "video")
//         .replaceTrack(displayMediaStream.getTracks()[0]);

//       //show what you are showing in your "self-view" video.
//       document.getElementById("local-video").srcObject = displayMediaStream;

//       //hide the share button and display the "stop-sharing" one
//       document.getElementById("share-button").style.display = "none";
//       document.getElementById("stop-share-button").style.display = "inline";
//     } catch (e) {
//       console.log("Unable to acquire screen capture: " + e);
//     }
//   }

//   async function stopShareScreen() {
//     try {
//       senders
//         .find((sender) => sender.track.kind === "video")
//         .replaceTrack(
//           localStream.getTracks().find((track) => track.kind === "video")
//         );
//       displayMediaStream = undefined;

//       document.getElementById("local-video").srcObject = localStream;
//       document.getElementById("share-button").style.display = "inline";
//       document.getElementById("stop-share-button").style.display = "none";
//     } catch (error) {
//       console.log("Unable to stop share screen: " + error);
//     }
//   }

//   return (
//     <div>
//       <div>
//         {/* <input
//           placeholder="Enter username..."
//           type="text"
//           id="username-input"
//         />
//         <br />
//         <button onClick={sendUsername}>Send</button> */}
//         <Button id="start_call" variant="warning"  onClick={startCall}>
//           {/* {t("TXT.STA.CALL.LO")}
//            */}
//            Start Call
//         </Button>
//       </div>
//       <div id="video-call-div" style={{ display: "none" }}>
//         <video muted id="local-video" autoPlay></video>
//         <video id="remote-video" autoPlay></video>
//         <div className="call-action-div">
//           <div style={{ display: "flex", gap: "1rem" }}>
//             {/* <Button id="mute-video" variant="warning" onClick={muteVideo}>
//               Mute Video
//             </Button> */}
//             <Button id="mute-video" variant="warning" onClick={muteVideo}>
//               <i
//                 id="video-icon"
//                 class="fas fa-video"
//                 style={{ color: "#000000" }}
//               ></i>
//             </Button>
//             {/* <Button id="mute-audio" variant="warning" onClick={muteAudio}>
//               Mute Audio
//             </Button> */}
//             <Button id="mute-audio" variant="warning" onClick={muteAudio}>
//               <i
//                 id="audio-icon"
//                 class="fas fa-microphone"
//                 style={{ color: "#000000" }}
//               ></i>
//             </Button>
//             {/* <Button id="share-button" variant="warning" onClick={shareScreen}>
//               Share Screen
//             </Button> */}
//             <OverlayTrigger
//               placement="top"
//               overlay={<Tooltip id="tooltip">Share Screen</Tooltip>}
//             >
//               <Button id="share-button" variant="warning" onClick={shareScreen}>
//                 <MdOutlineScreenShare size={24} />
//               </Button>
//             </OverlayTrigger>

//             {/* <Button
//               id="stop-share-button"
//               variant="warning"
//               onClick={stopShareScreen}
//               style={{ display: "none" }}
//             >
//               Stop Share
//             </Button> */}
//             <OverlayTrigger
//               placement="top"
//               overlay={<Tooltip id="tooltip">Stop Share</Tooltip>}
//             >
//               <Button
//                 id="stop-share-button"
//                 variant="warning"
//                 onClick={stopShareScreen}
//                 style={{ display: "none" }}
//               >
//                 <MdOutlineStopScreenShare size={24} />
//               </Button>
//             </OverlayTrigger>

//             {/* <NavLink to="/home"> */}
//             {/* <Button variant="warning" onClick={hangUp}>
//               HangUp
//             </Button> */}
//             {/* </NavLink> */}
//             <OverlayTrigger
//               placement="top"
//               overlay={<Tooltip id="tooltip">Hang Up</Tooltip>}
//             >
//               <Button variant="warning" onClick={hangUp}>
//                 <i class="fas fa-phone-slash" style={{ color: "#000000" }}></i>
//               </Button>
//             </OverlayTrigger>

//             {/* <Button variant="warning" onClick={handleShow}>
//               <i class="fas fa-comment-dots" style={{ color: "#000000" }}></i>
//             </Button> */}
//           </div>
//           <div id="chatbox">
//             <div id="chatarea"></div>
//             <div>
//               <input id="msgInput" type="text" placeholder="message" />
//               <Button id="sendMsgBtn" variant="warning" onClick={sendMsg}>
//                 Send
//               </Button>
//             </div>
//           </div>

//           {/* <Offcanvas show={show} onHide={handleClose} placement="end">
//             <Offcanvas.Header closeButton>
//               <Offcanvas.Title>Chat</Offcanvas.Title>
//             </Offcanvas.Header>
//             <Offcanvas.Body>
//               <div>
//                 <div id="chatarea1" class="chatarea1"></div>
//                 <div>
//                   <input id="msgInput1" type="text" placeholder="message" />
//                   <Button id="sendMsgBtn1" variant="warning" onClick={sendMsg1}>
//                     Send
//                   </Button>
//                 </div>
//               </div>
//             </Offcanvas.Body>
//           </Offcanvas> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoCallSender;

//**  Updated By 08.10.2024  */
import React, { useState, useEffect, UseAuth } from "react";
import { Navigate, NavLink, useNavigate, useLocation } from "react-router-dom";
import "../style.css";
import { videoServerUrl } from "../../component/Api";
import { vasthuUrl } from "../../component/Api";
import { Alert, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MdOutlineScreenShare, MdOutlineStopScreenShare } from "react-icons/md";
import { useTranslation } from "react-i18next";

const VideoCallSender = () => {
  const { t } = useTranslation();
  const [booked_slot_id, setBooked_slot_id] = useState("");
  const [meeting_code, setMeeting_code] = useState("");
  const token = window.sessionStorage.getItem("token");
  const { state } = useLocation();
  console.log("booked_slot_id" + String(state.i.slotId));
  // setBooked_slot_id(location.state.id);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    // setShow(true)
    const chatContainer = document.querySelector(".main__right");
    chatContainer.classList.toggle("active");
  };

  useEffect(() => {
    try {
      setBooked_slot_id(state.i.slotId);
      console.log("Token" + token);
      vasthuUrl
        .get(`getmeetingcode/${state.i.slotId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setMeeting_code(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
    }
  }, []);

  const navigate = useNavigate();

  // const webSocket = new WebSocket("ws://127.0.0.1:3001")
  const webSocket = new WebSocket(videoServerUrl);
  if (window.sessionStorage) {
    console.log("session storage supported");
  } else {
    console.log("session storage not supported");
  }
  let displayMediaStream;

  webSocket.onmessage = (event) => {
    try {
      handleSignallingData(JSON.parse(event.data));
    } catch (error) {
      alert("Please ensure the remote peer connection");
    }
  };

  function handleSignallingData(data) {
    switch (data.type) {
      case "answer":
        try {
          peerConn.setRemoteDescription(data.answer);
          break;
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
      case "candidate":
        try {
          peerConn.addIceCandidate(data.candidate);
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
    }
  }

  let username;
  function sendUsername() {
    username = meeting_code;
    console.log("usernameinput" + username);
    sendData({
      type: "store_user",
    });
  }

  function sendData(data) {
    data.username = username;
    if (webSocket.readyState === 1) {
      webSocket.send(JSON.stringify(data));
    } else {
      console.log("socket not ready");
    }
  }

  let localStream;
  let peerConn;
  let senders = [];
  // new chat ========================================================================
  let dataChannel;
  let connectedUser;

  //=================================================================================

  function startCall() {
    try {
      sendUsername();
    } catch (error) {
      console.log("not able to send username");
    }
    document.getElementById("video-call-div").style.display = "inline";

    var getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia;
    getUserMedia.call(
      navigator,
      {
        video: {
          frameRate: 24,
          width: {
            min: 480,
            ideal: 720,
            max: 1280,
          },
          aspectRatio: 1.33333,
        },
        audio: true,
      },
      (stream) => {
        let configuration = {
          iceServers: [
            {
              urls: "stun:stun.aawsoft.com:3478",
            },
            {
              urls: "turn:turn.aawsoft.com:3478",
              username: "guest",
              credential: "somepassword",
            },

            {
              urls: "stun:stun.aawsoft.com:5349",
            },
            {
              urls: "turn:turn.aawsoft.com:5349",
              username: "guest",
              credential: "somepassword",
            },

            {
              urls: [
                "stun:stun.l.google.com:19302",
                "stun:stun1.l.google.com:19302",
                "stun:stun2.l.google.com:19302",
              ],
            },

            {
              urls: "stun:openrelay.metered.ca:80",
            },
            {
              urls: "turn:openrelay.metered.ca:80",
              username: "openrelayproject",
              credential: "openrelayproject",
            },
            {
              urls: "turn:openrelay.metered.ca:443",
              username: "openrelayproject",
              credential: "openrelayproject",
            },
            {
              urls: "turn:openrelay.metered.ca:443?transport=tcp",
              username: "openrelayproject",
              credential: "openrelayproject",
            },
          ],
          optional: [
            {
              RtpDataChannels: true,
            },
          ],
        };

        peerConn = new RTCPeerConnection(configuration);

        // new chat ======================================================================

        dataChannel = peerConn.createDataChannel("channel1", {
          reliable: true,
        });

        try {
          if (dataChannel.readyState !== "open") {
            alert("Wait for client");
          }
        } catch (error) {
          console.log("connection is open");
        }

        peerConn.addEventListener("datachannel", (event) => {
          dataChannel = event.channel;
          console.log("dataChannel", dataChannel);
        });

        dataChannel.onopen = function(success) {
          console.log("Both are connected", success);
          console.log(
            "target.readystate+channel",
            success.target.readyState,
            "+",
            success.target.label
          );
          if (
            success.target.readyState == "open" &&
            success.target.label == "channel1"
          ) {
            console.log("calling  api");
          }
        };

        dataChannel.onerror = function(error) {
          console.log("Ooops...error:", error);
        };

        //when we receive a message from the other peer, display it on the screen
        dataChannel.onmessage = function(event) {
          var chatArea = document.querySelector("#chatarea");
          var messageDiv = document.createElement("div");
          messageDiv.classList.add("message", "remote-message"); // Add a class for styling
          messageDiv.innerText = "Remote: " + event.data;
          chatArea.appendChild(messageDiv);
          chatArea.innerHTML += "<br />";
          console.log("event.data", event.data);
        };

        dataChannel.onclose = function() {
          console.log("data channel is closed");
          alert("data call channel is closed - call hangup");
          localStream.getTracks().forEach(function(track) {
            track.stop();
          });
          window.location.reload();
        };

        // ================================================================================

        //
        localStream = stream;

        localStream
          .getTracks()
          .forEach((track) =>
            senders.push(peerConn.addTrack(track, localStream))
          );

        document.getElementById("local-video").srcObject = localStream;

        peerConn.ontrack = (event) => {
          const video = document.getElementById("remote-video");
          video.srcObject = event.streams[0];
        };

        peerConn.onicecandidate = (e) => {
          if (e.candidate == null) return;
          sendData({
            type: "store_candidate",
            candidate: e.candidate,
          });
        };

        createAndSendOffer();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function createAndSendOffer() {
    peerConn.createOffer(
      (offer) => {
        sendData({
          type: "store_offer",
          offer: offer,
        });

        peerConn.setLocalDescription(offer);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // let isAudio = true;
  // function muteAudio() {
  //   isAudio = !isAudio;
  //   localStream.getAudioTracks()[0].enabled = isAudio;
  //   var muteAudio = document.getElementById("mute-audio");
  //   muteAudio.innerText === "Mute Audio"
  //     ? (muteAudio.innerText = "Un Mute Audio")
  //     : (muteAudio.innerText = "Mute Audio");
  // }
  let isAudio = true;
  function muteAudio() {
    isAudio = !isAudio;
    localStream.getAudioTracks()[0].enabled = isAudio;
    var muteAudio = document.getElementById("mute-audio");
    var audioIcon = document.getElementById("audio-icon");
    if (isAudio) {
      audioIcon.classList.remove("fa-microphone-slash");
      audioIcon.classList.add("fa-microphone");
    } else {
      audioIcon.classList.remove("fa-microphone");
      audioIcon.classList.add("fa-microphone-slash");
    }
  }

  // let isVideo = true;
  // function muteVideo() {
  //   isVideo = !isVideo;
  //   localStream.getVideoTracks()[0].enabled = isVideo;
  //   var muteVideo = document.getElementById("mute-video");
  //   muteVideo.innerText == "Mute Video"
  //     ? (muteVideo.innerText = "Un Mute Video")
  //     : (muteVideo.innerText = "Mute Video");
  // }
  let isVideo = true;
  function muteVideo() {
    isVideo = !isVideo;
    localStream.getVideoTracks()[0].enabled = isVideo;
    var muteVideo = document.getElementById("mute-video");
    var videoIcon = document.getElementById("video-icon");
    if (isVideo) {
      videoIcon.classList.remove("fa-video-slash");
      videoIcon.classList.add("fa-video");
    } else {
      videoIcon.classList.remove("fa-video");
      videoIcon.classList.add("fa-video-slash");
    }
  }

  function hangUp(e) {
    e.preventDefault();
    peerConn.close();
    localStream.getVideoTracks()[0].enabled = false;
    localStream.getAudioTracks()[0].enabled = false;
    localStream.getTracks().forEach(function(track) {
      track.stop();
    });
    // test - after completeted video call
    console.log("Subscription id on hangup", state.i.subscriptionId);
    vasthuUrl
      .post(
        `subscription/consulationCallStatus`,
        {
          offerId: state.i.subscriptionId,
          callFinished: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((rs) =>
        console.log("offer subscription -call hangup", rs.data.message)
      )
      .catch((er) =>
        console.log("offer subscription  count not reduced - on hangup", er)
      );

    // test - after completeted video call - end
    navigate("/");
  }

  // =======

  //when user clicks the "send message" button
  const sendMsg = () => {
    var msgInput = document.getElementById("msgInput");
    var chatArea = document.querySelector("#chatarea");
    console.log("messageInput", msgInput);
    console.log("chatArea", chatArea);

    var val = msgInput.value;
    console.log("message" + val);
    try {
      dataChannel.send(val);
      var messageDiv = document.createElement("div");
      messageDiv.classList.add("message", "my-message"); // Add a class for styling
      messageDiv.innerText = val + " : Me ";
      chatArea.appendChild(messageDiv);
      chatArea.innerHTML += "<br />";
      msgInput.value = "";
    } catch (error) {
      alert("Please ensure the peer remote connection ");
      msgInput.value = "";
    }
  };

  async function shareScreen() {
    try {
      if (!displayMediaStream) {
        displayMediaStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
      }
      senders
        .find((sender) => sender.track.kind === "video")
        .replaceTrack(displayMediaStream.getTracks()[0]);

      //show what you are showing in your "self-view" video.
      document.getElementById("local-video").srcObject = displayMediaStream;

      //hide the share button and display the "stop-sharing" one
      document.getElementById("share-button").style.display = "none";
      document.getElementById("stop-share-button").style.display = "inline";
    } catch (e) {
      console.log("Unable to acquire screen capture: " + e);
    }
  }

  async function stopShareScreen() {
    try {
      senders
        .find((sender) => sender.track.kind === "video")
        .replaceTrack(
          localStream.getTracks().find((track) => track.kind === "video")
        );
      displayMediaStream = undefined;

      document.getElementById("local-video").srcObject = localStream;
      document.getElementById("share-button").style.display = "inline";
      document.getElementById("stop-share-button").style.display = "none";
    } catch (error) {
      console.log("Unable to stop share screen: " + error);
    }
  }

  return (
    <div>
      <div>
        <Button id="start_call" variant="warning" onClick={startCall}>
          Start Video Call
        </Button>
      </div>

      <div id="video-call-div" style={{ display: "none" }}>
        <video muted id="local-video" autoPlay></video>
        <video id="remote-video" autoPlay></video>
        <div className="video-action-div">
          <div class="main">
            <div style={{ display: "flex", gap: "1rem" }}>
              <Button id="mute-video" variant="warning" onClick={muteVideo}>
                <i
                  id="video-icon"
                  class="fas fa-video"
                  style={{ color: "#000000" }}
                ></i>
              </Button>
              <Button id="mute-audio" variant="warning" onClick={muteAudio}>
                <i
                  id="audio-icon"
                  class="fas fa-microphone"
                  style={{ color: "#000000" }}
                ></i>
              </Button>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Share Screen</Tooltip>}
              >
                <Button
                  id="share-button"
                  variant="warning"
                  onClick={shareScreen}
                >
                  <MdOutlineScreenShare size={24} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Stop Share</Tooltip>}
              >
                <Button
                  id="stop-share-button"
                  variant="warning"
                  onClick={stopShareScreen}
                  style={{ display: "none" }}
                >
                  <MdOutlineStopScreenShare size={24} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Hang Up</Tooltip>}
              >
                <Button variant="warning" onClick={hangUp}>
                  <i
                    class="fas fa-phone-slash"
                    style={{ color: "#000000" }}
                  ></i>
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Messages</Tooltip>}
              >
                <Button variant="warning" id="chat_Btn" onClick={handleShow}>
                  <i class="fas fa-comment" style={{ color: "#000000" }}></i>
                </Button>
              </OverlayTrigger>
            </div>
            <div class="main__right">
              <div class="main__header">
                <h6>
                  <span class="live"></span>LIVE CHAT
                </h6>
              </div>
              <div className="videochatBox">
                <div id="chatarea"></div>
                <div class="main__message_container">
                  <input
                    type="text"
                    id="msgInput"
                    placeholder="Type message here.."
                  />
                  <button class="sendMsgBtn" id="sendMsgBtn" onClick={sendMsg}>
                    <i class="fa fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCallSender;
